<template>
    <div class="job-roles-update-modal">
        <ModalContainer :title="$t('job_roles.edit_job_role')" identifier="job-roles-update-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                <FormInputText v-model="$v.job_role.name.$model" identifier="name" :label="$t('job_roles.name')"
                               :disabled="is_saving || isLoading"
                               :has-error="$v.job_role.name.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.job_role.name.required">
                            {{ $t('validation.x_is_required', {x: $t('job_roles.name')}) }}
                        </p>
                    </template>
                </FormInputText>

                <FormGroupTwo>
                    <FormInputSelect v-model="$v.job_role.sites.$model" identifier="sites" :label="$t('job_roles.sites')" :options="site_options"
                                     :disabled="is_saving || isLoading" :has-error="$v.job_role.sites.$error" track-by="id"
                                     :multiple="true" display-label="name">
                        <template v-slot:errors>
                            <p v-if="!$v.job_role.sites.required">
                                {{$t('validation.x_is_required',{x: $t('job_roles.sites')})}}
                            </p>
                        </template>
                    </FormInputSelect>

                    <FormInputSelect v-model="$v.job_role.site_groups.$model" identifier="site_groups" :label="$t('job_roles.site_groups')" :options="site_group_options"
                                     :disabled="is_saving || isLoading" :has-error="$v.job_role.site_groups.$error" track-by="id"
                                     :multiple="true" display-label="name">
                        <template v-slot:errors>
                            <p v-if="!$v.job_role.site_groups.required">
                                {{$t('validation.x_is_required',{x: $t('job_roles.site_groups')})}}
                            </p>
                        </template>
                    </FormInputSelect>

                    <FormInputSelect v-model="$v.job_role.departments.$model" identifier="sites" :label="$t('job_roles.departments')" :options="department_options"
                                     :disabled="is_saving || isLoading" :has-error="$v.job_role.departments.$error" track-by="id"
                                     :multiple="true" display-label="name">
                        <template v-slot:errors>
                            <p v-if="!$v.job_role.departments.required">
                                {{$t('validation.x_is_required',{x: $t('job_roles.departments')})}}
                            </p>
                        </template>
                    </FormInputSelect>

                    <FormInputSelect v-model="$v.job_role.manager.$model" identifier="sites" :label="$t('job_roles.manager')" :options="job_role_options"
                                     :disabled="is_saving || isLoading" :has-error="$v.job_role.manager.$error" track-by="id" display-label="name">
                        <template v-slot:errors>
                            <p v-if="!$v.job_role.manager.required">
                                {{$t('validation.x_is_required',{x: $t('job_roles.manager')})}}
                            </p>
                        </template>
                    </FormInputSelect>
                </FormGroupTwo>

                <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                    {{ $t('save') }}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormInputSelect from "../form/FormInputSelect";
import FormGroupTwo from "../form/FormGroupTwo";
import {required} from 'vuelidate/lib/validators'
import Button from "../Button";
import _ from "lodash";

export default {
    name: "JobRolesUpdateModal",
    components: {Button, FormInputSelect, FormInputText, Form, FormGroupTwo, ModalContainer},
    props: {
        job_role_id: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            job_role: {
                name: null,
                sites: [],
                site_groups: [],
                departments: [],
                manager: null
            },
            site_options: [],
            site_group_options: [],
            department_options: [],
            job_role_options: [],
            is_loading_sites: false,
            is_loading_site_groups: false,
            is_loading_departments: false,
            is_loading_job_role: false,
            is_loading_managers: false,
            is_saving: false
        }
    },
    validations: {
        job_role: {
            name: {required},
            sites: {},
            site_groups: {},
            departments: {},
            manager: {},
        }
    },
    computed: {
        isLoading() {
            return this.is_loading_sites || this.is_loading_site_groups || this.is_loading_departments || this.is_loading_managers || this.is_loading_job_role;
        }
    },
    methods: {
        close(status) {
            this.$modal.hide('job-roles-update-modal', status);
        },
        save() {
            this.$v.job_role.$touch();
            if (this.$v.job_role.$anyError || this.is_saving)
                return;

            this.is_saving = true;

            this.$axios.patch(`jobRoles/${this.job_role_id}`, {
                name: this.job_role.name,
                sites: this.job_role.sites.length ? this.job_role.sites.map(s => s.id) : [],
                site_groups: this.job_role.site_groups.length ? this.job_role.site_groups.map(sg => sg.id) : [],
                departments: this.job_role.departments.length ? this.job_role.departments.map(d => d.id) : [],
                manager_job_role_id: this.job_role.manager ? this.job_role.manager.id : null
            }).then(({data}) => {
                this.is_saving = false;
                this.close(true);
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('job_roles.error_update')),
                    type: 'error',
                });
            });
        },
        async getSites() {
            this.is_loading_sites = true;
            this.is_loading_job_role = true;

            await this.$axios.get('sites/list')
                .then(async ({data}) => {
                    this.site_options = data.data.map(s => ({id: s.id, name: s.attributes.name}));
                    this.is_loading_sites = false;
                })
                .catch(e => {
                    this.is_loading_sites = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('sites.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        async getSiteGroups() {
            this.is_loading_site_groups = true;

            this.$axios.get('siteGroups/list')
                .then(({data}) => {
                    this.site_group_options = data.data.map(sg => ({id: sg.id, name: sg.attributes.name}));
                    this.is_loading_site_groups = false;
                })
                .catch(e => {
                    this.is_loading_site_groups = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('site_groups.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        async getDepartments() {
            this.is_loading_departments = true;

            await this.$axios.get('departments/list')
                .then(async ({data}) => {
                    this.department_options = data.data.map(d => ({id: d.id, name: d.attributes.name}));
                    this.is_loading_departments = false;
                })
                .catch(e => {
                    this.is_loading_departments = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('departments.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        async getJobRoles() {
            await this.$axios.get('jobRoles/list')
                .then(async ({data}) => {
                    this.job_role_options = data.data.map(job_role => ({id: job_role.id, name: job_role.attributes.name}));
                    this.is_loading_job_roles = false;
                })
                .catch(e => {
                    this.is_loading_job_roles = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('job_roles.error_retrieve')),
                        trainer: 'error',
                    });
                });
        },
        getJobRole() {
            this.$axios.get(`jobRoles/${this.job_role_id}`)
                .then(({data}) => {
                    this.job_role.name = data.data.attributes.name;

                    if (data.data.relationships.sites.data.length)
                        this.job_role.sites = this.site_options.filter(so => data.data.relationships.sites.data.find(s => s.id === so.id));

                    if (data.data.relationships.siteGroups.data.length)
                        this.job_role.site_groups = this.site_group_options.filter(sgo => data.data.relationships.siteGroups.data.find(sg => sg.id === sgo.id));

                    if (data.data.relationships.departments.data.length)
                        this.job_role.departments = this.department_options.filter(o => data.data.relationships.departments.data.find(d => d.id === o.id));

                    if (data.data.relationships.manager.data)
                        this.job_role.manager = _.find(this.job_role_options, {id: data.data.relationships.manager.data.id});

                    this.is_loading_job_role = false;
                })
                .catch(e => {
                    this.is_loading_job_role = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('job_roles.error_retrieve')),
                        type: 'error',
                    });
                });
        },
    },
    async mounted() {
        await Promise.all([this.getSites(), this.getSiteGroups(), this.getDepartments(), this.getJobRoles()])

        this.getJobRole();
    }
}
</script>
