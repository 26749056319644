<template>
    <div class="departments-update-modal">
        <ModalContainer :title="$t('departments.edit_department')" identifier="departments-update-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving || is_loading_department">
                <FormInputText v-model="$v.department.code.$model" identifier="code" :label="$t('departments.code')"
                               :disabled="is_saving || is_loading_department"
                               :has-error="$v.department.code.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.department.code.required">
                            {{$t('validation.x_is_required',{x: $t('departments.code')})}}
                        </p>
                    </template>
                </FormInputText>

                <FormInputText v-model="$v.department.name.$model" identifier="name" :label="$t('departments.name')"
                               :disabled="is_saving || is_loading_department"
                               :has-error="$v.department.name.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.department.name.required">
                            {{$t('validation.x_is_required',{x: $t('departments.name')})}}
                        </p>
                    </template>
                </FormInputText>

                <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                    {{$t('save')}}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
import _ from 'lodash';
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormGroupTwo from "../form/FormGroupTwo";
import FormInputSelect from "../form/FormInputSelect";
import {required, email} from 'vuelidate/lib/validators'
import Button from "../Button";
import FormInputPassword from "@/components/form/FormInputPassword";

export default {
    name: "DepartmentsUpdateModal",
    components: {FormInputPassword, Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
    props: {
        department_id: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            department: {
                code: null,
                name: null,
            },
            is_saving: false,
            is_loading_department: true,
        }
    },
    validations: {
        department: {
            code: {required},
            name: {required},
        }
    },
    methods: {
        close(status) {
            this.$modal.hide('departments-update-modal', status);
        },
        save() {
            this.$v.department.$touch();
            if (this.$v.department.$anyError || this.is_saving)
                return;

            this.is_saving = true;

            let payload = {
                code: this.$v.department.code.$model,
                name: this.$v.department.name.$model
            };

            this.$axios.patch(`departments/${this.department_id}`, payload).then(({data}) => {
                this.$notify({
                    text: this.$t('departments.success_updated'),
                    type: 'success',
                });

                this.is_saving = false;
                this.close(true);
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('departments.error_update')),
                    type: 'error',
                });
            });
        },
        async getDepartment() {
            this.is_loading_department = true;

            await this.$axios.get(`departments/${this.department_id}`)
                .then(({data}) => {
                    this.$v.department.code.$model = data.data.attributes.code;
                    this.$v.department.name.$model = data.data.attributes.name;

                    this.is_loading_department = false;
                })
                .catch(e => {
                    this.is_loading_department = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('departments.error_retrieve')),
                        type: 'error',
                    });
                });
        },
    },
    async mounted() {
        await this.getDepartment();
    }
}
</script>
