<template>
    <div class="page-container">
        <Headbar>
            <template v-slot:right>
                <Button className="--primary --small --wider"
                        :class="{spinner: is_saving}" :onclick="save">
                    {{ $t('save') }}
                </Button>
            </template>
        </Headbar>
        <main>
            <Form class="form" :disabled="!$store.getters.hasPermission('update users') || is_saving || is_loading_original">
                <SectionHeader :title="$t('profile.personal_information')"></SectionHeader>
                <div class="form-body">
                    <FormGroupTwo>
                        <FormInputText v-model="$v.user.name.$model" identifier="name" :label="$t('users.name')"
                                       :placeholder="$t('users.name')" :disabled="is_saving || is_loading_original"
                                       :has-error="$v.user.name.$error">
                            <template v-slot:errors>
                                <p v-if="!$v.user.name.required">
                                    {{ $t('validation.x_is_required', {x: $t('users.name')}) }}
                                </p>
                            </template>
                        </FormInputText>

                        <FormInputText v-model="$v.user.email.$model" identifier="email" :label="$t('users.email_address')"
                                       :placeholder="$t('users.email')" :disabled="is_saving || is_loading_original"
                                       :has-error="$v.user.email.$error" autocomplete="off" class="email-address">
                            <template v-slot:errors>
                                <p v-if="!$v.user.email.required">
                                    {{ $t('validation.x_is_required', {x: $t('users.email')}) }}
                                </p>
                                <p v-else-if="!$v.user.email.email">
                                    {{ $t('auth.enter_valid_email') }}
                                </p>
                            </template>
                        </FormInputText>
                    </FormGroupTwo>
                </div>
            </Form>

            <Form class="form" :disabled="!$store.getters.hasPermission('update users') || is_saving || is_loading_original">
                <SectionHeader :title="$t('profile.change_password')"></SectionHeader>
                <div class="form-body">
                    <FormGroupTwo>

                        <FormInputPassword v-model="$v.user.password.$model" identifier="password"
                                           :label="$t('users.password')"
                                           :placeholder="$t('users.password')" :disabled="is_saving || is_loading_original" type="password"
                                           :has-error="$v.user.password.$error" autocomplete="off">
                            <template v-slot:errors>
                                <p v-if="!$v.user.password.required">
                                    {{ $t('validation.x_is_required', {x: $t('users.password')}) }}
                                </p>
                            </template>
                        </FormInputPassword>
                    </FormGroupTwo>
                </div>
            </Form>
        </main>
    </div>
</template>

<script>
import _ from 'lodash';
import Headbar from "../components/headbar/Headbar";
import Form from "../components/form/Form";
import SectionHeader from "../components/SectionHeader";
import Button from "../components/Button";
import FormInputText from "../components/form/FormInputText";
import FormInputSelect from "../components/form/FormInputSelect";
import {required, email} from 'vuelidate/lib/validators'
import FormGroupTwo from "../components/form/FormGroupTwo";
import FormGroupThree from "../components/form/FormGroupThree";
import FormInputPassword from "../components/form/FormInputPassword";
import FormInputTextSelect from "../components/form/FormInputTextSelect";


export default {
    name: "users-update-page",
    components: {
        FormGroupTwo,
        FormGroupThree,
        FormInputText,
        FormInputPassword,
        FormInputSelect,
        Button,
        SectionHeader,
        Form,
        Headbar,
        FormInputTextSelect,
    },
    data() {
        return {
            original: null,
            user: {
                name: null,
                email: null,
                password: null,
            },
            is_saving: false,
            is_loading_original: true,
        }
    },
    validations: {
        user: {
            name: {required},
            email: {required, email},
            password: {},
        }
    },
    methods: {
        populate() {
            if (!this.original || !this.user)
                return;

            this.$v.user.name.$model = this.original.attributes.name;
            this.$v.user.email.$model = this.original.attributes.email;
        },
        async getOriginalUser() {
            this.is_loading_original = true;

            await this.$axios.get(`profile`)
                .then(({data}) => {
                    this.is_loading_original = false;
                    this.original = data.data;
                })
                .catch(e => {
                    this.is_loading_original = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('users.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        save() {
            this.$v.user.$touch();
            if (this.$v.user.$anyError || this.is_saving)
                return;

            this.is_saving = true;

            const payload = {};

            payload.name = this.$v.user.name.$model;

            if (this.original.attributes.email !== this.$v.user.email.$model)
                payload.email = this.$v.user.email.$model;

            if (this.$v.user.password.$model) {
                payload.password = this.$v.user.password.$model;
            }

            this.$axios.patch(`profile`, payload).then(({data}) => {
                this.$notify({
                    text: this.$t('profile.success_updated'),
                    type: 'success',
                });

                this.$store.dispatch('getUser');

                this.is_saving = false;
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('profile.error_update')),
                    type: 'error',
                });
            });
        },
    },
    async mounted() {
        await this.getOriginalUser();
        this.populate();
    },
    head() {
        return {
            title: {
                inner: this.$t('profile.user_account')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
.page-container {
    button {
        @apply my-0.5;
    }

    main {
        @apply flex flex-col;

        .form {
            @apply mb-8 w-full max-w-3xl mx-auto;

            .form-body {
                @apply px-8 pt-7 pb-2.5;
            }
        }
    }
}
</style>