<template>
    <div class="page-container">
        <sites-index v-if="$store.getters.hasPermission('read sites')"/>
        <site-groups-index v-if="$store.getters.hasPermission('read site groups')"/>
    </div>
</template>

<script>

import SitesIndex from "@/components/sites/Index"
import SiteGroupsIndex from "@/components/site_groups/Index";

export default {
    name: "sites-index-page",
    components: {SiteGroupsIndex, SitesIndex},
    head() {
        return {
            title: {
                inner: this.$t('nav.sites')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
.page-container {
    @apply flex flex-col;

    @screen lg {
        @apply flex-row;
    }

    .section {
        @apply w-full;

        @screen lg {
            @apply w-1/2;
        }

        main, .headbar-container {
            &:first-of-type {
                @screen lg {
                    @apply pr-4;
                }

                @screen xl {
                    @apply pr-6;
                }
            }

            &:last-of-type {
                @screen lg {
                    @apply pl-4;
                }

                @screen xl {
                    @apply pl-6;
                }
            }
        }
    }

    .headbar-expanded-container {
        @apply mt-4 flex flex-col gap-y-4 items-center;

        @screen md {
            @apply hidden;
        }
    }

    .select-dropdown {
        @apply min-w-40;
    }

    .btn-search-mobile, .btn-filter-mobile {
        @apply block text-primary w-6;

        @screen md {
            @apply hidden;
        }

        &:active, &:focus {
            @apply outline-none;
        }
    }

    .search-desktop, .filter-desktop {
        @apply hidden;

        @screen md {
            @apply block;
        }
    }

    .search-container {
        @apply mr-0;
    }

    .filter-mobile {
        @apply max-w-xs;
    }

    .td-after {
        @apply flex flex-row;

        & > * {
            @apply mr-3;

            &:last-child {
                @apply mr-0;
            }
        }
    }
}
</style>
