<template>
    <loading-screen v-if="is_loading_users"/>
    <div v-else class="page-container">
        <Headbar>
            <template v-slot:left>
                <h1 v-if="$route.params.role === 'trainer'">{{$t('users.users')}} ({{$t('users.viewing_as_trainer')}})</h1>
                <h1 v-else-if="$route.params.role === 'manager'">{{$t('users.users')}} ({{$t('users.viewing_as_manager')}})</h1>
                <h1 v-else-if="$route.params.role === 'administrator'">{{$t('users.users')}} ({{$t('users.viewing_as_administrator')}})</h1>
                <h1 v-else-if="$route.params.role === 'document_controller'">{{$t('users.users')}} ({{$t('users.viewing_as_document_controller')}})</h1>
                <h1 v-else>{{$t('users.users')}}</h1>
            </template>
            <template v-slot:right>
                <Button className="--primary --outline --match-select"
                        :onclick="generateDownload" :disabled="is_printing" :class="{spinner: is_printing}">
                    <font-awesome-icon :icon="['far', 'download']"/>
                </Button>
                <Search class="search-desktop" :placeholder="$t('search')" @search="search"/>
                <button class="btn-search-mobile btn-icon-only" @click="headbarExpanded = headbarExpanded === 'search' ? null : 'search'">
                    <font-awesome-icon v-if="headbarExpanded === 'search'" :icon="['far', 'times']"/>
                    <font-awesome-icon v-else :icon="['far', 'search']"/>
                </button>
            </template>
            <template v-slot:expanded>
                <div class="headbar-expanded-container">
                    <Search v-if="headbarExpanded === 'search'" :placeholder="$t('users.search_users')" @search="search"/>
                </div>
            </template>
        </Headbar>
        <main>
            <div class="users-container">
                <p class="no-users-text" v-if="!users.length">{{ $t('users.no_associated_users') }}</p>
                <div v-else v-for="user in users" class="user-wrapper" @click="visitUser(user)">
                    <div class="user">
                        <div class="user-info-container">
                            <user-icon-large iconType="far" iconName="user" backgroundColor="bg-primary"/>
                            <p class="user-name" v-if="user.attributes.archived_at == null">{{ user.attributes.name }}</p>
                            <p class="user-name" v-else-if="user.attributes.archived_at != null">User Archived</p>
                            <p class="user-role" v-if="user.attributes.archived_at == null">{{ user.relationships.job_roles.data.length ? user.relationships.job_roles.data.map(j => j.attributes.name).join(', ') : '' }}</p>
                            <div class="progress-bar-container" v-if="user.attributes.archived_at == null">
                                <div class="progress-bar">
                                    <div class="progress" :style="{width: `${$route.params.role === 'trainer' ? user.attributes.progress.trainer_progress : user.attributes.progress.administrator_progress}%`}"/>
                                </div>
                                <p class="progress-text">{{$route.params.role === 'trainer' ? user.attributes.progress.trainer_progress : user.attributes.progress.administrator_progress}}%</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pagination-wrapper" v-if="users && users.length">
                <button @click="onPageChange(1)" :class="{disabled: current_page === 1}">{{$t('pagination.first')}}</button>
                <button @click="onPageChange(serverParams.page - 1)" :class="{disabled: current_page <= 1}">{{$t('pagination.prev')}}</button>

                <button v-if="current_page > 1" :class="{active: serverParams.page === current_page-1}" @click="onPageChange(current_page-1)">{{current_page-1}}</button>

                <button :class="{active: serverParams.page === current_page}" @click="onPageChange(current_page)">{{current_page}}</button>

                <button v-if="current_page < last_page" :class="{active: serverParams.page === current_page+1}" :disabled="last_page < current_page+1" @click="onPageChange(current_page+1)">{{current_page+1}}</button>

                <button @click="onPageChange(serverParams.page + 1)" :disabled="current_page >= last_page" :class="{disabled: current_page >= last_page}">{{$t('pagination.next')}}</button>
                <button @click="onPageChange(last_page)" :disabled="current_page === last_page" :class="{disabled: current_page === last_page}">{{$t('pagination.last')}}</button>
            </div>
        </main>
        <download-csv v-if="csv_data.length" v-show="false" ref="csv-generator" :data="csv_data"></download-csv>
    </div>
</template>

<script>
import UserIconLarge from "../../components/UserIconLarge";
import ConfirmModal from "@/components/modal/ConfirmModal";
import Select from "@/components/form/Select";
import Search from "@/components/Search";
import Headbar from "@/components/headbar/Headbar";
import LoadingScreen from "@/components/LoadingScreen";
import Button from "../../components/Button.vue";

export default {
    name: "IndexProgress",
    components: {Button, LoadingScreen, Headbar, Search, UserIconLarge},
    data() {
        const printing_columns = [
            {
                label: this.$t('users.name'),
                field: 'attributes.name',
                sortable: false,
            },
            {
                label: this.$t('users.documents'),
                field: 'documents.relationships.documents.data.attributes.name',
                sortable: false,
            },
        ];
        return {
            users: [],
            headbarExpanded: false,
            current_page: 1,
            last_page: 999,
            serverParams: {
                per_page: 24,
                page: 1,
            },
            searchTerm: null,
            totalRecords: null,
            is_loading_users: false,
            is_printing: false,
            printing_columns: printing_columns,
            printing_pages: [],
            documents: [],
            csv_data: [],
        }
    },

    methods: {
        generateDownload() {
            this.generateCSVDownload();
        },
        async generateCSVDownload() {
            this.is_printing = true;
            await this.$refs['csv-generator'].generate();
            this.is_printing = false;
        },
        visitUser(user) {
            if (user.attributes.archived_at == null) {
                let encrypted_id = btoa(this.$CryptoJS.AES.encrypt(user.id.toString(), 'user').toString());
                this.$router.push({name: 'user-documents', params: {id: encrypted_id, role: this.$route.params.role}});
            }
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        removeParam(param) {
            this.$delete(this.serverParams, param);
        },
        onPageChange(params) {
            if(params === this.current_page) return;

            this.updateParams({page: params});
            this.getUsers();
        },
        search(searchTerm) {
            this.searchTerm = searchTerm;

            if (searchTerm && searchTerm.length)
                this.updateParams({term: searchTerm});
            else this.removeParam('term');

            this.getUsers();
        },
        getUsers() {
            this.is_loading_users = true;

            if(this.$route.params.role === 'trainer') {
                this.$axios.get(`users/${this.$store.getters.user.id}/trainer-users`, {params: {...this.serverParams}})
                    .then(({data}) => {
                        this.users = data.data;

                        this.totalRecords = data.meta.total;
                        this.last_page = data.meta.last_page;
                        this.current_page = data.meta.current_page;

                        this.is_loading_users = false;
                    })
                    .catch(e => {
                        this.is_loading_users = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('users.error_retrieve')),
                            type: 'error',
                        });
                    });
            } else if(this.$route.params.role === 'manager') {
                this.$axios.get(`users/${this.$store.getters.user.id}/manager-users`, {params: {...this.serverParams}})
                    .then(({data}) => {
                        this.users = data.data;

                        this.totalRecords = data.meta.total;
                        this.last_page = data.meta.last_page;
                        this.current_page = data.meta.current_page;

                        this.is_loading_users = false;
                    })
                    .catch(e => {
                        this.is_loading_users = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('users.error_retrieve')),
                            type: 'error',
                        });
                    });
            } else {
                this.$axios.get('users', {params: {...this.serverParams}})
                    .then(({data}) => {
                        this.users = data.data;
                        this.totalRecords = data.meta.total;
                        this.last_page = data.meta.last_page;
                        this.current_page = data.meta.current_page;

                        this.is_loading_users = false;
                    })
                    .catch(e => {
                        this.is_loading_users = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('users.error_retrieve')),
                            type: 'error',
                        });
                    });
            }
        },
        getDocuments() {
            this.getDocumentsCSVData();

            this.is_loading_documents = true;

            const encodedSorting = this.serverParams.sorting.map(f => btoa(JSON.stringify(f)));

            this.$axios.get('users', {params: {...this.serverParams, sorting: encodedSorting}})
                .then(({data}) => {
                    this.documents = data.data;

                    this.printing_pages = [];
                    let temp_documents_printing_array = _.clone(this.documents);

                    while (temp_documents_printing_array.length > 0)
                        this.printing_pages.push(temp_documents_printing_array.splice(0, 8));

                    this.$refs.table.changePage(data.meta.current_page);

                    history.pushState(
                        {},
                        null,
                        `${this.$route.path}?page=${data.meta.current_page}`
                    );

                    this.totalRecords = data.meta.total;
                    this.is_loading_documents = false;
                })
                .catch(e => {
                    this.is_loading_documents = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('documents.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        getDocumentsCSVData() {
            this.is_loading_documents_csv_data = true;

            this.$axios.get('users/list', {params: this.serverParams})
                .then(({data}) => {
                    this.csv_data = [];

                    data.data.forEach(document => {
                        this.csv_data.push({
                            'Trainee': users.attributes.name,
                            'Name': document.attributes.name,
                        });
                    });

                    this.is_loading_documents_csv_data = false;
                })
                .catch(e => {
                    this.is_loading_documents_csv_data = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('users.error_retrieve')),
                        type: 'error',
                    });
                });
        },
    },
    mounted() {
        if ((this.$route.params.role && this.$route.params.role === 'administrator') || (this.$route.params.role && this.$route.params.role === 'document_controller'))
            this.updateParams({ exclude_archived: 1 });
        this.getUsers();
        this.getDocuments();
    }
}
</script>

<style lang="scss" scoped>
.page-container {
    @apply h-full;

    .headbar-expanded-container {
        @apply mt-4 flex flex-col gap-y-4 items-center;

        @screen md {
            @apply hidden;
        }
    }

    .btn-search-mobile, .btn-filter-mobile {
        @apply block text-primary w-6;

        @screen md {
            @apply hidden;
        }

        &:active, &:focus {
            @apply outline-none;
        }
    }

    .search-desktop, .filter-desktop {
        @apply hidden;

        @screen md {
            @apply block;
        }
    }

    .search-container {
        @apply mr-0;
    }

    .filter-mobile {
        @apply max-w-xs;
    }

    main {
        @apply h-full flex flex-col;
    }

    .users-container {
        @apply flex flex-row flex-wrap -mx-4 mb-8;

        .no-users-text {
            @apply px-4 text-grey;
        }

        .user-wrapper {
            @apply w-1/2 px-4 py-4;

            @screen md {
                @apply w-1/4;
            }

            @screen xl {
                width: 16.66%;
            }

            .user {
                @apply bg-white rounded-3xl h-full cursor-pointer;

                &:hover {
                    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                }

                .user-info-container {
                    @apply flex flex-col gap-y-2 items-center py-8 px-4 h-full;

                    .user-name {
                        @apply font-bold text-grey text-center;
                    }

                    .user-role {
                        @apply text-grey text-center capitalize mb-4;
                    }

                    .progress-bar-container {
                        @apply w-3/4 h-2 mt-auto flex flex-row justify-evenly items-center;

                        .progress-bar {
                            @apply w-3/4 h-2 bg-primary bg-opacity-20 rounded-xl relative;

                            .progress {
                                @apply absolute h-2 bg-primary rounded-xl;
                            }
                        }

                        .progress-text {
                            @apply text-xs ml-2 mb-0.5;
                        }
                    }
                }
            }
        }
    }

    .pagination-wrapper {
        @apply flex flex-row mt-auto ml-auto;

        button {
            @apply font-medium mr-4 text-grey-dark text-lg;

            &:hover {
                @apply text-grey-dark cursor-pointer;
            }

            &.active {
                @apply text-primary;
            }

            &.disabled {
                @apply text-grey-light cursor-not-allowed;
            }
        }
    }
}
</style>