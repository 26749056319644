<template>
    <h1>{{$t('page_not_found')}}</h1>
</template>

<script>
    export default {
        name: "not-found-page",
        head() {
            return {
                title: {
                    inner: this.$t('page_not_found')
                },
            }
        }
    }
</script>

<style lang="scss" scoped></style>
