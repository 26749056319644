export default {
    nav: {
        home: 'Home',
        login: 'Login',
        reset_password: 'Reset Password',
        documents: 'Documents',
        departments: 'Departments',
        sites: 'Sites',
        countries: 'Countries',
        documents_categories_types: 'Documents Categories & Types',
        job_roles: 'Job Roles',
        modules: 'Modules',
        trainers: 'Trainers',
        roles_permissions: 'Roles & Permissions',
        permissions: 'Permissions',
        users: 'Users',
        profile: 'Profile',
        logout: 'Logout',
        signup: 'Sign Up',
    },
    profile: {
        profile: 'profile',
        user_account: 'User Account',
        personal_information: 'Personal Information',
        address_information: 'Address Information',
        change_password: 'Change Password',
        success_updated: 'Your account has been updated',
        error_update: 'Failed to update profile'
    },
    documents: {
        id: 'ID',
        documents: 'documents',
        all_documents: 'All Documents',
        upload: 'Upload',
        add_document: 'Add Document',
        edit_document: 'Edit Document',
        search_documents: 'Search documents',
        code: 'Code',
        name: 'Name',
        attachment_name: 'Attachment Name',
        path: 'Path',
        type: 'Type',
        category: 'Category',
        language: 'Language',
        module: 'Module',
        version: 'Version',
        trainers: 'Trainers',
        manager: 'Manager',
        review_num: 'Review No.',
        rev_num: 'Rev No.',
        next_review_date: 'Next Review Date',
        revision_details: 'Revision Details',
        rev_details: 'Rev Details',
        reference: 'Reference',
        description: 'Description',
        sites: 'Sites',
        site_groups: 'Site Groups',
        departmental_owner: 'Departmental Owner',
        dep_owner: 'Dep Owner',
        line_manager: 'Line Manager',
        activate_line_manager: 'Activate Line Manager as Trainer',
        export_options: 'Export Options',
        sign_document: 'Sign Document',
        tick_box_sign: 'Kindly tick the box to sign for document.',
        document_signed: 'The document has been signed',
        departments: 'Departments',
        department_groups: 'Department Groups',
        date_created: 'Date Created',
        review_no: 'Review No.',
        review_count: 'Review No.',
        next_review: 'Next Review',
        next_review_at: 'Next Review',
        date_uploaded: 'Date Uploaded',
        last_updated: 'Last Updated',
        last_updated_at: 'Last Updated',
        archived: 'Archived',
        unarchived: 'Unarchived',
        copy_link: 'Copy Link',
        link_copied: 'Link has been copied!',
        preview: 'Preview',
        file_upload: 'File Upload',
        type_id: 'Type ID',
        category_id: 'Category ID',
        module_id: 'Module ID',
        trainer_id: 'Trainer ID',
        department_id: 'Department ID',
        download: 'Download',
        file_type_preview_not_supported: 'File type is not supported for preview',
        old_values: 'Old Values',
        new_values: 'New Values',
        update: 'Update',
        edit: 'Edit',
        message: 'Message',
        country: 'Country',
        groups: 'Group/s',
        document: 'Document',
        training: 'Training',
        signature_status: 'Signature Status',
        induction_progress: 'Induction Progress',
        trainer_signed_at: 'Trainer signed at',
        employee_signed_at: 'Employee signed at',
        who_are_you_signing_for: 'Who are you signing for?',
        skip_training: 'Skip Training',
        mark_as_required: 'Mark As Required',
        not_required: 'Not Required',
        required: 'Required',
        are_you_sure_skip: 'Are you sure you want to mark this document as not required?',
        are_you_sure_required: 'Are you sure you want to mark this document as required?',
        employee: 'Employee',
        trainer: 'Trainer',
        minor: 'Minor',
        major: 'Major',
        send_email: 'Notify change via email',
        related_users: 'Related Users',
        completion: 'Completion',
        actions: 'Actions',
        prompt_delete: 'Are you sure you want to delete this document?',
        prompt_archive: 'Are you sure you want to archive this document?',
        prompt_unarchive: 'Are you sure you want to unarchive this document?',
        success_created: 'Document has been created',
        success_updated: 'Document has been updated',
        error_create: 'Failed to create document',
        error_update: 'Failed to update document',
        error_upload: 'Failed to upload document',
        error_delete: 'Failed to delete document',
        error_archive: 'Failed to archive document',
        error_unarchive: 'Failed to unarchive document',
        error_retrieve: 'Failed to retrieve documents',
        error_sign_document: 'Failed to sign document',
        error_retrieve_audits: 'Failed to retrieve audits',
        error_send_message: 'Failed to send message',
        error_skip: 'Failed to skip training for document',
        error_unskip: 'Failed to unskip training for document',
        document_details: 'Document Details',
        update_document: 'Update document',
        delete_document: 'Delete document',
        archive_document: 'Archive document',
        unarchive_document: 'Unarchive document',
        job_role_id: 'Job Role ID',
        is_line_manager: 'Is Line Manager',
        is_training_material: 'Document required for training',
        remove_employee_signatures: 'Remove Associated Employee Signatures',
        remove_trainer_signatures: 'Remove Associated Trainer Signatures',
    },
    sites: {
        id: 'ID',
        sites: 'sites',
        add_site: 'Add Site',
        edit_site: 'Edit Site',
        search_sites: 'Search sites',
        code: 'Code',
        name: 'Name',
        country: 'Country',
        groups: 'Group/s',
        site: 'Site',
        actions: 'Actions',
        prompt_delete: 'Are you sure you want to delete this site?',
        success_created: 'Site has been created',
        success_updated: 'Site has been updated',
        error_create: 'Failed to create site',
        error_update: 'Failed to update site',
        error_delete: 'Failed to delete site',
        error_retrieve: 'Failed to retrieve sites',
        site_details: 'Site Details',
        update_site: 'Update Site',
        delete_site: 'Delete Site',
    },
    site_groups: {
        sites: 'Sites',
        site_groups: 'site groups',
        add_group: 'Add Group',
        add_site_group: 'Add Site Group',
        edit_site_group: 'Edit Site Group',
        code: 'Code',
        name: 'Name',
        group: 'Group',
        site: 'Site',
        site_group: 'Site Group',
        actions: 'Actions',
        prompt_delete: 'Are you sure you want to delete this site group?',
        success_created: 'Site group has been created',
        success_updated: 'Site group has been updated',
        error_create: 'Failed to create site group',
        error_update: 'Failed to update site group',
        error_delete: 'Failed to delete site group',
        error_retrieve: 'Failed to retrieve site groups',
        site_group_details: 'Site Group Details',
        update_site_group: 'Update Site Group',
        delete_site_group: 'Delete Site Group',
    },
    countries: {
        countries: 'countries',
        add_country: 'Add Country',
        edit_country: 'Edit Country',
        code: 'Code',
        name: 'Name',
        group: 'Group',
        groups: 'Groups',
        country: 'Country',
        actions: 'Actions',
        prompt_delete: 'Are you sure you want to delete this country?',
        success_created: 'Country has been created',
        success_updated: 'Country has been updated',
        error_create: 'Failed to create country',
        error_update: 'Failed to update country',
        error_delete: 'Failed to delete country',
        error_retrieve: 'Failed to retrieve countries',
        country_details: 'Country Details',
        update_country: 'Update Country',
        delete_country: 'Delete Country',
    },
    country_groups: {
        countries: 'Countries',
        country_groups: 'country groups',
        add_group: 'Add Group',
        add_country_group: 'Add Country Group',
        edit_country_group: 'Edit Country Group',
        code: 'Code',
        name: 'Name',
        group: 'Group',
        country: 'Country',
        country_group: 'Country Group',
        actions: 'Actions',
        prompt_delete: 'Are you sure you want to delete this country group?',
        success_created: 'Country group has been created',
        success_updated: 'Country group has been updated',
        error_create: 'Failed to create country group',
        error_update: 'Failed to update country group',
        error_delete: 'Failed to delete country group',
        error_retrieve: 'Failed to retrieve country groups',
        country_group_details: 'Country Group Details',
        update_country_group: 'Update Country Group',
        delete_country_group: 'Delete Country Group',
    },
    departments: {
        department: 'Department',
        departments: 'Departments',
        search_departments: 'Search departments',
        add_department: 'Add Department',
        edit_department: 'Edit Department',
        code: 'Code',
        name: 'Name',
        group: 'Group',
        groups: 'Groups',
        actions: 'Actions',
        prompt_delete: 'Are you sure you want to delete this department?',
        success_created: 'Department has been created',
        success_updated: 'Department has been updated',
        error_create: 'Failed to create department',
        error_update: 'Failed to update department',
        error_delete: 'Failed to delete department',
        error_retrieve: 'Failed to retrieve departments',
        country_details: 'Department Details',
        update_department: 'Update Department',
        delete_department: 'Delete Department',
    },
    department_groups: {
        departments: 'Departments',
        department_groups: 'Department Groups',
        add_group: 'Add Group',
        add_department_group: 'Add Department Group',
        edit_department_group: 'Edit Department Group',
        code: 'Code',
        name: 'Name',
        group: 'Group',
        department: 'Department',
        department_group: 'Department Group',
        actions: 'Actions',
        prompt_delete: 'Are you sure you want to delete this department group?',
        success_created: 'Department group has been created',
        success_updated: 'Department group has been updated',
        error_create: 'Failed to create department group',
        error_update: 'Failed to update department group',
        error_delete: 'Failed to delete department group',
        error_retrieve: 'Failed to retrieve department groups',
        department_group_details: 'Department Group Details',
        update_department_group: 'Update Department Group',
        delete_department_group: 'Delete Department Group',
    },
    categories: {
        categories: 'categories',
        add_category: 'Add Category',
        edit_category: 'Edit Category',
        search_categories: 'Search categories',
        code: 'Code',
        name: 'Name',
        category: 'Category',
        actions: 'Actions',
        prompt_delete: 'Are you sure you want to delete this category?',
        success_created: 'Category has been created',
        success_updated: 'Category has been updated',
        error_create: 'Failed to create category',
        error_update: 'Failed to update category',
        error_delete: 'Failed to delete category',
        error_retrieve: 'Failed to retrieve categories',
        category_details: 'Category Details',
        update_category: 'Update Category',
        delete_category: 'Delete Category',
    },
    types: {
        types: 'types',
        add_type: 'Add Type',
        edit_type: 'Edit Type',
        search_types: 'Search types',
        name: 'Name',
        category: 'Category',
        type: 'Type',
        actions: 'Actions',
        prompt_delete: 'Are you sure you want to delete this type?',
        success_created: 'Type has been created',
        success_updated: 'Type has been updated',
        error_create: 'Failed to create type',
        error_update: 'Failed to update type',
        error_delete: 'Failed to delete type',
        error_retrieve: 'Failed to retrieve types',
        type_details: 'Type Details',
        update_type: 'Update Type',
        delete_type: 'Delete Type',
    },
    job_roles: {
        job_role: 'Job Role',
        job_roles: 'Job Roles',
        add_job_role: 'Add Job Role',
        edit_job_role: 'Edit Job Role',
        search_job_roles: 'Search job roles',
        id: 'ID',
        name: 'Name',
        user: 'User',
        users: 'Users',
        user_or_users: 'User/s',
        sites: 'Sites',
        site_groups: 'Site Groups',
        departments: 'Departments',
        manager: 'Manager',
        actions: 'Actions',
        prompt_delete: 'Are you sure you want to delete this job role?',
        success_created: 'Job role has been created',
        success_updated: 'Job role has been updated',
        error_create: 'Failed to create job role',
        error_update: 'Failed to update job role',
        error_delete: 'Failed to delete job role',
        error_retrieve: 'Failed to retrieve job roles',
        error_retrieve_managers: 'Failed to retrieve managers',
        job_role_details: 'Job Role Details',
        update_job_role: 'Update Job Role',
        delete_job_role: 'Delete Job Role',
    },
    modules: {
        module: 'Module',
        modules: 'Modules',
        add_module: 'Add Module',
        edit_module: 'Edit Module',
        search_modules: 'Search modules',
        name: 'Name',
        actions: 'Actions',
        prompt_delete: 'Are you sure you want to delete this module?',
        success_created: 'Module has been created',
        success_updated: 'Module has been updated',
        error_create: 'Failed to create module',
        error_update: 'Failed to update module',
        error_delete: 'Failed to delete module',
        error_retrieve: 'Failed to retrieve modules',
        module_details: 'Module Details',
        update_module: 'Update Module',
        delete_module: 'Delete Module',
    },
    trainers: {
        trainer: 'Trainer',
        trainers: 'Trainers',
        add_trainer: 'Add Trainer',
        edit_trainer: 'Edit Trainer',
        search_trainers: 'Search trainers',
        id: 'ID',
        name: 'Name',
        job_roles: 'Job Roles',
        actions: 'Actions',
        prompt_delete: 'Are you sure you want to delete this trainer?',
        success_created: 'Trainer has been created',
        success_updated: 'Trainer has been updated',
        error_create: 'Failed to create trainer',
        error_update: 'Failed to update trainer',
        error_delete: 'Failed to delete trainer',
        error_retrieve: 'Failed to retrieve trainers',
        trainer_details: 'Trainer Details',
        update_trainer: 'Update Trainer',
        delete_trainer: 'Delete Trainer',
    },
    users: {
        user: 'User',
        users: 'users',
        add_user: 'Add User',
        edit_user: 'Edit User',
        search_users: 'Search users',
        no_associated_users: 'No associated users',
        viewing_as_trainer: 'Viewing as trainer',
        viewing_as_manager: 'Viewing as manager',
        viewing_as_administrator: 'Viewing as administrator',
        viewing_as_document_controller: 'Viewing as document controller',
        name: 'Name',
        department: 'Department',
        email: 'Email',
        email_address: 'Email Address',
        password: 'Password',
        confirm_password: 'Confirm password',
        role: 'Role',
        roles: 'Roles',
        permission: 'Permission',
        permissions: 'Permissions',
        job_role: 'Job Role',
        job_roles: 'Job Roles',
        actions: 'Actions',
        view_users_progress: 'View Users Progress',
        view_users_progress_as_administrator: 'View Users Progress (Administrator)',
        view_users_progress_as_document_controller: 'View Users Progress (Document Controller)',
        view_users_progress_as_trainer: 'View Users Progress (Trainer)',
        view_users_progress_as_manager: 'View Users Progress (Manager)',
        view_own_documents: 'View Own Documents',
        archive_user: 'Archive User',
        unarchive_user: 'Unarchive User',
        prompt_delete: 'Are you sure you want to delete this user?',
        prompt_archive: 'Are you sure you want to archive this user?',
        prompt_unarchive: 'Are you sure you want to unarchive this user?',
        success_created: 'User has been created',
        success_updated: 'User has been updated',
        error_create: 'Failed to create user',
        error_update: 'Failed to update user',
        error_delete: 'Failed to delete user',
        error_retrieve: 'Failed to retrieve users',
        error_retrieve_documents: 'Failed to retrieve user\'s documents',
        error_download_document: 'Failed to download document',
        user_details: 'User Details',
        update_user: 'Update User',
        delete_user: 'Delete User',
        error_archive: 'Failed to archive user',
        error_unarchive: 'Failed to unarchive user',
    },
    roles: {
        roles: 'roles',
        permissions: 'Permissions',
        permission: 'Permission',
        actions: 'Actions',
        name: 'Name',
        add_role: 'Add Role',
        edit_role: 'Edit Role',
        search_roles: 'Search roles',
        prompt_delete: 'Are you sure you want to delete this role?',
        prompt_delete_subtext: '',
        error_retrieve: 'Failed to retrieve roles',
        error_create: 'Failed to create role',
        error_update: 'Failed to update role',
        error_delete: 'Failed to delete role',
        success_created: 'Role has been created',
        success_updated: 'Role has been updated',
        error_retrieve_fields: 'Failed to retrieve fields',
        role_details: 'Role Details',
        delete_role: 'Delete Role',
    },
    reports: {
        generate_report: 'Generate Report',
        report_type: 'Report Type',
        room_name: 'Room Name',
        organisation_name: 'Organisation Name',
        from_date: 'From Date',
        to_date: 'To Date',
        choose_a_room: 'Choose a room',
        choose_an_organisation: 'Choose an organisation',
        choose_a_from_date: 'Choose a from date',
        choose_a_to_date: 'Choose a to date',
        report_options: 'Report Options',
        preview: 'Preview',
        organisations_report: 'Organisations Report',
        rooms_report: 'Rooms Report',
        total_number_of_bookings: 'Total number of bookings',
        total_number_of_hours_booked: 'Total number of hours booked',
        time_frame: 'Time Frame',
        to_date_must_be_after_from :'The to date must be after the from date',
    },
    audits: {
        audits: 'Audits',
        audit: 'Audit',
        user: 'User',
        event: 'Event',
        detail: 'Detail',
        date_time: 'Date & Time',
    },
    permissions: {
        permission: 'permission',
        permissions: 'permissions',
        error_retrieve: 'Failed to retrieve permissions',
        error_save: 'Failed to save permission',
        details_saved: 'permission details have been saved',
        saved: 'permission has been saved',
    },
    prompts: {
        are_you_sure: 'are you sure?',
        do_you_want_to_delete_this_x: 'do you want to delete this {x}?',
    },
    validation: {
        x_is_required: '{x} is required',
        x_are_required: '{x} are required',
        please_enter_x: 'please enter {x}',
        please_enter_longer_x: 'please enter a longer {x}',
        please_enter_shorter_x: 'please enter a shorter {x}',
        please_enter_valid_x: 'please enter a valid {x}',
        please_choose_x: 'please choose {x}',
        please_enter_number: 'please enter a number',
        please_enter_number_between_x: 'please enter a number between {x} and {y}',
        please_enter_url: 'please enter a URL',
        passwords_must_match: 'Passwords must match',
        password_must_be_atleast_x_characters: 'Password must be at least {x} characters',
        password_confirmation_must_match_new_password: 'Password confirmation must match new password'
    },
    auth: {
        unauthorized: 'Unauthorized',
        unauthenticated: 'Unauthenticated',
        forgot_password_q: 'Forgot password?',
        forgot_password: 'Forgot password',
        forgot_password_text: 'Enter your email and we\'ll send you a link to reset your password.',
        password_has_been_updated: 'Your password has been updated!',
        setup_your_account: 'Setup your account.',
        account_created: 'Account successfully created',
        email: 'Email',
        password: 'Password',
        password_is_required: 'Password is required',
        email_is_required: 'Email is required',
        reset_password: 'Reset Password',
        enter_valid_email: 'Enter a valid email',
        email_sent: 'Email successfully sent',
        return_to_login: 'Return to Login',
        enter_your_new_password: 'Enter your new password',
        retype_password: 'Re-type password',
        failed_login: 'Login Failed',
        failed_reset: 'Reset Failed',
        identity_verified: 'Your identity has been verified!',
        set_new_password: 'Set your new password',
        failed_signup: 'Signup Failed',
        sign_in_with_microsoft: 'Sign in with Microsoft',
        logged_in: 'Logged In',
        you_have_logged_in: 'You have logged in!'
    },
    pagination: {
        next: 'Next',
        prev: 'Prev',
        last: 'Last',
        first: 'First',
    },
    cancel: 'Cancel',
    delete: 'Delete',
    remove: 'Remove',
    start: 'Start',
    finish: 'Finish',
    add: 'Add',
    update: 'Update',
    save: 'Save',
    print: 'Print',
    send: 'Send',
    search: 'Search',
    select: 'Select',
    page_not_found: 'Page not found',
    x_per_page: '{x} per page',
    error: 'Error',
    yes: 'Yes',
    no: 'No',
    accepted: 'Accepted',
    not_accepted: 'Not Accepted',
    warning: 'Warning',
    actions: 'Actions',
    back: 'Back',
    submit: 'Submit',
    assign: 'Assign',
    okay: 'Okay',
    signup: 'Sign Up',
    filter_by: 'Filter by',
    export_as : 'Export as',
    sign: 'Sign',
    download: 'Download',
    edit: 'Edit',
    clear_filters: 'Clear Filters',
    skip: 'Skip',
    archive: 'Archive',
    unarchive: 'Unarchive',
    confirm: 'Confirm'
}
