<template>
    <footer class="footer-container">
        <p>Version 1.0.0</p>
        <p>Copyright © {{year}} Medilink International ® All Rights Reserved.</p>
    </footer>
</template>

<script>
    export default {
        name: "Footer",
        computed: {
            year() {
                let date = new Date().getFullYear();
                return date;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .footer-container {
        @apply flex flex-col items-center justify-between bg-primary h-auto w-full py-4;
        grid-area: footer;

        @screen md {
            @apply flex-row-reverse py-2;
        }

        p {
            @apply py-1 px-6 text-xs text-white;
        }
    }
</style>