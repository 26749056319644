var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section"},[_c('Headbar',{scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('h1',[_vm._v(_vm._s(_vm.$t('countries.countries')))])]},proxy:true},{key:"right",fn:function(){return [(_vm.$store.getters.hasAnyPermission(['store countries']))?_c('Button',{attrs:{"className":"--primary --small","onclick":_vm.toggleCreate}},[_vm._v(_vm._s(_vm.$t('countries.add_country')))]):_vm._e()]},proxy:true}])}),_c('main',[_c('vue-good-table',{attrs:{"mode":"remote","styleClass":"vgt-table vgt-custom","columns":_vm.columns,"rows":_vm.countries,"isLoading":_vm.is_loading_countries,"search-options":{
                enabled: false,
            },"pagination-options":{
                enabled: true,
                mode: 'records',
                dropdownAllowAll: false,
                perPage: 10,
                perPageDropdownEnabled: true,
                rowsPerPageLabel: _vm.$t('x_per_page', {x: _vm.$t('countries.countries')}),
            },"sort-options":{
              enabled: true,
              multipleColumns: true,
            },"totalRows":_vm.totalRecords},on:{"update:isLoading":function($event){_vm.is_loading_countries=$event},"update:is-loading":function($event){_vm.is_loading_countries=$event},"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPerPageChange,"on-sort-change":_vm.onSortChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'after')?_c('div',{staticClass:"td-after"},[(_vm.$store.getters.hasPermission('update countries'))?_c('Button',{attrs:{"className":"--secondary --outline --mini --big-text","onclick":()=>_vm.toggleUpdate(props.row)}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'pencil']}})],1):_vm._e(),(_vm.$store.getters.hasPermission('destroy countries'))?_c('Button',{attrs:{"className":"--secondary --outline --mini --big-text","onclick":()=>_vm.toggleDelete(props.row)}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'trash']}})],1):_vm._e()],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }