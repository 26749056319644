<template>
    <div class="input-group" :class="{error: hasError}">
        <div class="upload" v-if="!attachment.name">
            <input type="file" name="file" :id="'file-input'" @change="handleFileChange" :disabled="disabled"/>
            <label :for="'file-input'" class="button --primary --outline" :class="{'--negative': hasError}"><font-awesome-icon :icon="['far', 'upload']"/>{{label}}</label>
        </div>

        <div v-else class="uploaded-file">
            <input disabled v-model="attachment.name" :size="attachment.name.length">
            <font-awesome-icon :icon="['far', 'times']" @click="removeFile"/>
        </div>

        <FormErrors v-if="hasError">
            <slot name="errors"></slot>
        </FormErrors>
    </div>
</template>

<script>
    import FormErrors from "./FormErrors";

    export default {
        name: "FormInputText",
        components: {FormErrors},
        props: {
            value: {
                type: [String, Number],
            },
            identifier: {
                type: String,
                default: null,
            },
            label: {
                type: String,
                default: null,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            hasError: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                attachment: {
                    name: null
                },
            }
        },
        methods: {
            handleFileChange(e) {
                this.attachment = e.target.files[0];
                this.$emit('input', e.target.files[0]);

                if(this.attachment.size > 15728640) {
                    this.attachment = {
                        name: null
                    };

                    this.$notify({
                        title: this.$t('error'),
                        text: 'File size is too big',
                        type: 'error',
                    });
                }
            },
            removeFile() {
                this.attachment = {
                    name: null
                };
            },
        }
    }
</script>

<style lang="scss" scoped>
    .input-group {
        @apply w-full flex flex-col items-start mb-5;

        .upload {
            @apply flex flex-row flex-wrap w-full text-left;
            width: unset;

            input {
                width: 0.1px;
                height: 0.1px;
                opacity: 0;
                overflow: hidden;
                position: absolute;
                z-index: -1;
            }

            label {
                @apply cursor-pointer text-xs text-grey font-bold border-2 border-primary py-3 px-4 rounded;

                & > svg {
                    @apply text-base mr-2;
                }

                &:hover {
                    @apply border-primary-over;
                }
            }
        }

        .uploaded-file {
            @apply flex flex-row w-auto text-xs text-grey font-bold border-2 border-primary py-3 px-4 rounded;

            & > svg {
                @apply text-base cursor-pointer;
            }

            input {
                @apply h-full bg-white font-bold;
            }
        }

        &.error {
            .input {
                @apply border-negative;
            }
        }
    }
</style>