<template>
    <div class="sites-create-modal">
        <ModalContainer :title="$t('site_groups.add_site_group')" identifier="site-groups-create-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                <FormInputText v-model="$v.site_group.name.$model" ref="name" identifier="name" :label="$t('site_groups.name')"
                               :disabled="is_saving" :has-error="$v.site_group.name.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.site_group.name.required">
                            {{$t('validation.x_is_required',{x: $t('site_groups.name')})}}
                        </p>
                    </template>
                </FormInputText>

                <FormInputSelect v-model="$v.site_group.sites.$model" identifier="sites"
                                 :label="$t('site_groups.sites')" :options="site_options"
                                 :disabled="is_saving || is_loading_sites"
                                 :has-error="$v.site_group.sites.$error" track-by="id"
                                 display-label="name" :multiple="true" class="select">
                    <template v-slot:errors>
                        <p v-if="!$v.site_group.sites.required">
                            {{ $t('validation.x_are_required', {x: $t('site_groups.sites')}) }}
                        </p>
                    </template>
                </FormInputSelect>

                <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                    {{$t('save')}}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormGroupTwo from "../form/FormGroupTwo";
import FormInputSelect from "../form/FormInputSelect";
import {required, email} from 'vuelidate/lib/validators'
import Button from "../Button";
import FormInputPassword from "@/components/form/FormInputPassword";

export default {
    name: "SitesCreateModal",
    components: {FormInputPassword, Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
    data() {
        return {
            site_group: {
                name: null,
                sites: [],
            },
            site_options: [],
            is_saving: false,
            is_loading_sites: false,
        }
    },
    validations: {
        site_group: {
            name: {required},
            sites: {required},
        }
    },
    methods: {
        close(status) {
            this.$modal.hide('site-groups-create-modal', status);
        },
        save() {
            this.$v.site_group.$touch();
            if (this.$v.site_group.$anyError || this.is_saving)
                return;

            this.is_saving = true;

            let payload = Object.assign({}, this.$v.site_group.$model);
            delete payload.sites;

            this.$axios.post(`siteGroups`, payload).then(async ({data}) => {
                await this.syncSites(data.data.id);

                this.$notify({
                    text: this.$t('site_groups.success_created'),
                    type: 'success',
                });

                this.is_saving = false;
                this.close(true);
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('site_groups.error_create')),
                    type: 'error',
                });
            });
        },
        async syncSites(site_group_id) {
            await this.$axios.post(`siteGroups/${site_group_id}/sites/sync`, {site_ids: this.site_group.sites.map(c => c.id)});
        },
        getSites() {
            this.is_loading_sites = true;

            this.$axios.get('sites/list')
                .then(({data}) => {
                    this.site_options = data.data.map(p => ({id: p.id, name: p.attributes.name}));
                    this.is_loading_sites = false;
                })
                .catch(e => {
                    this.is_loading_sites = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('sites.error_retrieve')),
                        type: 'error',
                    });
                });
        }
    },
    mounted() {
        this.getSites();

        this.$refs.name.$el.children[1].focus();
    }
}
</script>
