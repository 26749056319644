<template>
    <aside class="sidebar-container">
        <router-link :to="{name: 'documents-index'}" class="nav-item" v-if="$store.getters.hasAnyPermission(['read documents'])">
            <div class="icon-container">
                <font-awesome-icon :icon="['far', 'file-alt']"/>
            </div>
            <p>{{$t('nav.documents')}}</p>
        </router-link>

        <router-link :to="{name: 'sites-index'}" class="nav-item" v-if="$store.getters.hasAnyPermission(['read sites', 'read site groups'])">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'building']"/>
            </div>
            <p>{{$t('nav.sites')}}</p>
        </router-link>

        <router-link :to="{name: 'departments-index'}" class="nav-item" v-if="$store.getters.hasAnyPermission(['read departments'])">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'users']"/>
            </div>
            <p>{{$t('nav.departments')}}</p>
        </router-link>

        <router-link :to="{name: 'countries-index'}" class="nav-item" v-if="$store.getters.hasAnyPermission(['read countries', 'read country groups'])">
            <div class="icon-container">
                <font-awesome-icon :icon="['fas', 'globe-europe']"/>
            </div>
            <p>{{$t('nav.countries')}}</p>
        </router-link>

        <router-link :to="{name: 'categories-index'}" class="nav-item" v-if="$store.getters.hasAnyPermission(['read categories', 'read types'])">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'icons']"/>
            </div>
            <p>{{$t('nav.documents_categories_types')}}</p>
        </router-link>

        <router-link :to="{name: 'job-roles-index'}" class="nav-item" v-if="$store.getters.hasAnyPermission(['read job roles'])">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'briefcase']"/>
            </div>
            <p>{{$t('nav.job_roles')}}</p>
        </router-link>

        <router-link :to="{name: 'modules-index'}" class="nav-item" v-if="$store.getters.hasAnyPermission(['read modules'])">
            <div class="icon-container">
                <font-awesome-icon :icon="['far', 'file-certificate']"/>
            </div>
            <p>{{$t('nav.modules')}}</p>
        </router-link>

        <router-link :to="{name: 'roles-index'}" class="nav-item" v-if="$store.getters.hasPermission('read permissions')">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'key']"/>
            </div>
            <p>{{$t('nav.permissions')}}</p>
        </router-link>

        <router-link :to="{name: 'users-index'}" class="nav-item" v-if="$store.getters.hasPermission('read users')">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'user-friends']"/>
            </div>
            <p>{{$t('nav.users')}}</p>
        </router-link>
    </aside>
</template>

<script>
    export default {
        name: "Sidebar"
    }
</script>

<style lang="scss" scoped>
    .sidebar-container {
        @apply h-full flex flex-col bg-white hidden py-4 justify-start;
        box-shadow: 5px 0px 10px #0000001A;
        width: 140px;
        grid-area: sidebar;

        @screen md {
            @apply flex;
        }

        .nav-item {
            @apply flex flex-col items-center px-1 mb-4;

            @screen 3xl {
                @apply mb-6;
            }

            .icon-container {
                @apply p-2 rounded-lg border-2 border-grey-light flex flex-col items-center justify-center;
                height: 40px;
                width: 40px;

                svg {
                    @apply text-primary w-auto;
                    height: 18px;
                }
            }

            p {
                @apply text-xs font-bold mt-2 text-center text-grey;

                @screen 3xl {
                    @apply text-sm;
                }
            }

            &:hover, &:focus {
                .icon-container {
                    @apply border-primary;
                }
            }

            &.router-link-active {
                .icon-container {
                    @apply bg-primary border-primary;

                    svg {
                        @apply text-white;
                    }
                }
            }
        }
    }
</style>