<template>
    <div class="login-page-container darken">
        <div class="content">
            <img src="../assets/medilink-logo.svg" class="logo " alt="logo"/>

            <Form v-if="screen === 'login'" @submit="login" :disabled="is_processing" class="form-container login-form">
                <FormInputText v-model="$v.credentials.email.$model" autocomplete="on" :placeholder="$t('users.email')"
                               :disabled="is_processing" :has-error="$v.credentials.email.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.credentials.email.required">
                            {{ $t('auth.email_is_required') }}
                        </p>
                        <p v-else-if="!$v.credentials.email.email">
                            {{ $t('auth.enter_valid_email') }}
                        </p>
                    </template>
                </FormInputText>

                <FormInputPassword v-model="$v.credentials.password.$model" identifier="password"
                                   :placeholder="$t('users.password')" :disabled="is_processing" type="password"
                                   :has-error="$v.credentials.password.$error" autocomplete="on">
                    <template v-slot:errors>
                        <p v-if="!$v.credentials.password.required">
                            {{$t('validation.x_is_required',{x: $t('users.password')})}}
                        </p>
                        <p v-else-if="!$v.credentials.password.minLength">
                            {{ $t('validation.password_must_be_atleast_x_characters',{x: 8}) }}
                        </p>
                    </template>
                </FormInputPassword>

                <div class="submit-row">
                    <Button :disabled="is_processing" className="--primary" :class="{'spinner-black': is_processing}" type="submit">
                        <span>{{ $t('nav.login') }}</span>
                    </Button>

                    <a @click="screen = 'forgot'" class="forgot">
                        {{ $t('auth.forgot_password_q') }}
                    </a>
                </div>

                <div class="divider-row">
                    <div class="divider"/>
                    <p class="text">Or</p>
                    <div class="divider"/>
                </div>

                <Button className="--secondary --outline" class="microsoft-login" :onclick="microsoftLogin">
                    <img src="../assets/microsoft-logo.svg">
                    <p>{{$t('auth.sign_in_with_microsoft')}}</p>
                </Button>
            </Form>
            <div v-else-if="screen === 'forgot'" class="forgot-wrapper">
                <button class="back" @click="screen = 'login'">
                    <font-awesome-icon :icon="['fal','long-arrow-left']"/>
                </button>
                <Form @submit="forgot" :disabled="is_processing" class="form-container forgot-form">

                    <p>{{ $t('auth.forgot_password_text') }}</p>

                    <FormInputText v-model="$v.credentials.email.$model" autocomplete="off"
                                   :has-error="$v.credentials.email.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.credentials.email.required">
                                {{ $t('auth.email_is_required') }}
                            </p>
                            <p v-else-if="!$v.credentials.email.email">
                                {{ $t('auth.enter_valid_email') }}
                            </p>
                        </template>
                    </FormInputText>

                    <div class="submit-row">
                        <Button :disabled="is_processing" className="--primary mt-4"
                                :class="{'spinner-black': is_processing}" type="submit">
                            {{ $t('auth.reset_password') }}
                        </Button>
                    </div>
                </Form>
            </div>
            <Form v-else-if="screen === 'reset'" class="reset-form">
                <p>{{ $t('auth.email_sent') }}</p>

                <font-awesome-icon :icon="['fal','check-circle']"/>
                <div class="submit-row">
                    <Button className="--primary" :onclick="() => this.screen = 'login'">
                        {{ $t('nav.login') }}
                    </Button>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
import {required, email} from 'vuelidate/lib/validators'
import Button from "../components/Button";
import Form from "../components/form/Form";
import FormInputText from "@/components/form/FormInputText";
import FormInputPassword from "@/components/form/FormInputPassword";

export default {
    name: "login-page",
    components: {FormInputText, FormInputPassword, Form, Button},
    data() {
        return {
            credentials: {
                email: '',
                password: '',
            },
            show_password: false,
            is_processing: false,
            screen: 'login',
        }
    },
    validations: {
        credentials: {
            email: {
                required,
                email,
            },
            password: {
                required,
            }
        }
    },
    methods: {
        login() {
            this.$v.credentials.$touch();
            if (this.$v.credentials.$anyError || this.is_processing)
                return;

            this.is_processing = true;
            this.$axios.post('auth/login', {
                email: this.$v.credentials.email.$model,
                password: this.$v.credentials.password.$model,
            }).then(async ({data}) => {
                this.$store.commit("SET_TOKEN", data.data.token);
                await this.$store.dispatch('getUser');

                this.is_processing = false;

                this.pushRoute();
            }).catch(e => {
                this.is_processing = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('auth.failed_login')),
                    type: 'error',
                });
            });
        },
        pushEmployee() {
            let encrypted_id = btoa(this.$CryptoJS.AES.encrypt(this.$store.getters.user.id.toString(), 'user').toString());
            this.$router.push({name: 'user-documents', params: {id: encrypted_id, role: 'employee'}});
        },
        pushRoute() {
            if (this.$store.getters.hasRole('administrator') && this.$store.getters.userRoles().length === 1)
                this.$router.push({name: 'user-documents', params: {role: 'administrator'}});
            else if (this.$store.getters.hasRole('document_controller') && this.$store.getters.userRoles().length === 1)
                this.$router.push({name: 'user-documents', params: {role: 'document_controller'}});
            else if (this.$store.getters.hasRole('trainer') && this.$store.getters.userRoles().length === 1)
                this.$router.push({name: 'user-documents', params: {role: 'trainer'}});
            else if (this.$store.getters.hasRole('manager') && this.$store.getters.userRoles().length === 1)
                this.$router.push({name: 'user-documents', params: {role: 'manager'}});
            else if (this.$store.getters.hasRole('employee') && this.$store.getters.userRoles().length === 1)
                this.pushEmployee();
            else this.$router.push('/');
        },
        microsoftLogin() {
            if (!this.$store.getters.isLoggedIn && !this.$msal.isAuthenticated()) {
                this.$msal.signIn();
            }
        },
        forgot() {
            this.$v.credentials.email.$touch();
            if (this.$v.credentials.email.$anyError || this.is_processing)
                return;

            this.is_processing = true;
            this.$axios.post('auth/password/reset/request', {
                email: this.$v.credentials.email.$model,
            }).then(async ({data}) => {
                this.screen = 'reset';

                this.is_processing = false;
            }).catch(e => {
                this.is_processing = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('auth.failed_reset')),
                    type: 'error',
                });
            });
        }
    },
    head() {
        return {
            title: {
                inner: this.$t('nav.login')
            },
        }
    },
    async mounted() {
        let user = this.$msal.data.user;
        let token = localStorage.getItem(`msal.${this.$b2c_client_id}.idtoken`);

        if (this.$msal.isAuthenticated() && !this.$store.getters.isLoggedIn) {
            this.$axios.post('auth/handleOauthResponse', {
                email: user.userName,
                account_id: user.accountIdentifier,
                token: token
            })
                .then(async ({data}) => {
                    this.$store.commit("SET_TOKEN", data.data.token);
                    await this.$store.dispatch('getUser');

                    this.pushRoute();

                    this.$notify({
                        title: this.$t('auth.logged_in'),
                        text: this.$t('auth.you_have_logged_in'),
                        type: 'success',
                    });
                }).catch((e) => {
                    if(token){
                        this.$msal.signOut();
                    }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.login-page-container {
    @apply bg-cover bg-center bg-no-repeat bg-primary min-h-screen w-full flex flex-col justify-center items-center;

    //&.darken {
    //    position: relative;
    //
    //    &:after {
    //        content: '';
    //        position: absolute;
    //        top: 0;
    //        bottom: 0;
    //        left: 0;
    //        right: 0;
    //        display: block;
    //        background-color: rgba(0, 0, 0, 0.38);
    //    }
    //}

    .content {
        @apply p-10 rounded-lg flex flex-col max-w-full bg-white;
        width: 90vw;
        //background-color: rgba(0, 0, 0, 0.65);
        z-index: 1;

        @screen sm {
            width: 478px;
        }

        .logo {
            @apply w-auto mb-12 object-contain h-14;
        }

        .form-container {
            @apply bg-white;

            .submit-row {
                @apply flex flex-row justify-between items-center;
                margin-top: 0rem !important;

                a.forgot {
                    @apply text-grey text-sm no-underline cursor-pointer;
                }
            }

            .divider-row {
                @apply w-full flex flex-row my-8;

                & > .divider {
                    @apply border-b-2 border-grey-light mt-3 h-1/2 w-1/2;

                }

                & > p {
                    @apply mx-4 my-auto text-grey;
                }
            }

            .microsoft-login {
                @apply flex flex-row py-2 justify-center;

                & > img {
                    @apply h-6 my-auto mr-2;
                }

                & > p {
                    @apply text-grey my-auto;
                }
            }
        }

        .login-form {
            button[type="submit"] {
                @apply self-start;
            }

            .submit-row {
                @apply flex flex-row flex-wrap mt-3;

                button[type=button] {
                    @apply ml-2;
                }
            }
        }

        .forgot-wrapper {
            button.back {
                @apply absolute top-0 left-0;

                svg {
                    @apply text-white text-3xl mt-11 ml-10;
                }

                &:active, &:focus {
                    @apply outline-none;
                }
            }

            .forgot-form {
                @apply flex flex-col items-center;

                & > p {
                    @apply text-grey font-normal leading-6 text-base text-center -mt-3 mb-8;
                    max-width: 16rem;
                }
            }
        }

        .reset-form {
            @apply flex flex-col items-center mb-4;

            & > p {
                @apply text-grey font-normal text-base text-center max-w-92 mt-3;
            }

            svg {
                @apply text-grey mt-8 mb-10;
                font-size: 5.65rem;
            }

            .submit-row {
                @apply flex flex-row justify-between items-center w-full;

                button {
                    @apply mx-auto cursor-pointer;
                }
            }
        }
    }

}
</style>
