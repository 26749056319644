<template>
    <div class="sites-create-modal">
        <ModalContainer :title="$t('site_groups.add_site_group')" identifier="site-groups-update-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving || is_loading_site_group">
                <FormInputText v-model="$v.site_group.name.$model" identifier="name" :label="$t('site_groups.name')"
                               :disabled="is_saving || is_loading_site_group"
                               :has-error="$v.site_group.name.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.site_group.name.required">
                            {{$t('validation.x_is_required',{x: $t('site_groups.name')})}}
                        </p>
                    </template>
                </FormInputText>

                <FormInputSelect v-model="$v.site_group.sites.$model" identifier="sites"
                                 :label="$t('site_groups.sites')" :options="site_options"
                                 :disabled="is_saving || is_loading_site_group || is_loading_sites"
                                 :has-error="$v.site_group.sites.$error" track-by="id"
                                 display-label="name" :multiple="true" class="select">
                    <template v-slot:errors>
                        <p v-if="!$v.site_group.sites.required">
                            {{ $t('validation.x_are_required', {x: $t('site_groups.sites')}) }}
                        </p>
                    </template>
                </FormInputSelect>

                <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                    {{$t('save')}}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormGroupTwo from "../form/FormGroupTwo";
import FormInputSelect from "../form/FormInputSelect";
import {required} from 'vuelidate/lib/validators'
import Button from "../Button";
import FormInputPassword from "@/components/form/FormInputPassword";

export default {
    name: "SitesCreateModal",
    components: {FormInputPassword, Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
    props: {
        site_group_id: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            site_group: {
                name: null,
                sites: [],
            },
            site_options: [],
            is_saving: false,
            is_loading_sites: true,
            is_loading_site_group: true,
        }
    },
    validations: {
        site_group: {
            name: {required},
            sites: {required},
        }
    },
    methods: {
        close(status) {
            this.$modal.hide('site-groups-update-modal', status);
        },
        save() {
            this.$v.site_group.$touch();
            if (this.$v.site_group.$anyError || this.is_saving)
                return;

            this.is_saving = true;

            let payload = {
                code: this.site_group.code,
                name: this.site_group.name,
            };

            this.$axios.patch(`siteGroups/${this.site_group_id}`, payload).then(async ({data}) => {
                await this.syncSites(data.data.id);

                this.$notify({
                    text: this.$t('site_groups.success_updated'),
                    type: 'success',
                });

                this.is_saving = false;

                this.close(true);
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('site_groups.error_update')),
                    type: 'error',
                });
            });
        },
        async syncSites(site_group_id) {
            await this.$axios.post(`siteGroups/${this.site_group_id}/sites/sync`, {site_ids: this.site_group.sites.map(c => c.id)});
        },
        async getSites() {
            this.is_loading_sites = true;

            await this.$axios.get('sites/list')
                .then(async ({data}) => {
                    this.site_options = data.data.map(c => ({id: c.id, name: c.attributes.name}));

                    this.is_loading_sites = false;
                })
                .catch(e => {
                    this.is_loading_sites = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('sites.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        async getSiteGroup() {
            this.is_loading_site_group = true;

            await this.$axios.get(`siteGroups/${this.site_group_id}`)
                .then(({data}) => {
                    this.$v.site_group.name.$model = data.data.attributes.name;
                    this.$v.site_group.sites.$model = this.site_options.filter(site => data.data.relationships.sites.data.find(c => c.id === site.id));

                    this.is_loading_site_group = false;
                })
                .catch(e => {
                    this.is_loading_site_group = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('categories.error_retrieve')),
                        type: 'error',
                    });
                });
        },
    },
    async mounted() {
        await this.getSites();
        this.getSiteGroup();
    }
}
</script>
