<template>
    <div class="document-controller-sign-document-modal">
        <ModalContainer :title="$t('documents.sign_document')" identifier="document-controller-sign-document-modal" :closable="true">
            <Form class="form" @submit="sign" :disabled="(!sign_employee && !sign_trainer) || is_saving">

                <p class="text">{{$t('documents.who_are_you_signing_for')}}</p>

                <div class="checkboxes-wrapper">
                    <div class="checkbox-container">
                        <input type="checkbox" id="employee-check" v-model="sign_employee" :disabled="employee_signed_at"/>
                        <label for="employee-check">{{$t('documents.employee')}}</label>
                    </div>

                    <div class="checkbox-container">
                        <input type="checkbox" id="trainer-check" v-model="sign_trainer" :disabled="trainer_signed_at"/>
                        <label for="trainer-check">{{$t('documents.trainer')}}</label>
                    </div>
                </div>

                <p class="subtext" v-if="trainer_signed_at">{{$t('documents.trainer_signed_at')}} {{$moment(trainer_signed_at).format('DD/MM/YYYY HH:mm')}}</p>
                <p class="subtext" v-if="employee_signed_at">{{$t('documents.employee_signed_at')}} {{$moment(employee_signed_at).format('DD/MM/YYYY HH:mm')}}</p>

                <Button type="submit" className="--primary --small" :disabled="!sign_employee && !sign_trainer" :class="{spinner: is_saving}">
                    {{$t('sign')}}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputCheck from "../form/FormInputCheck";
import {required, requiredIf} from 'vuelidate/lib/validators'
import Button from "../Button";

export default {
    name: "DocumentControllerSignDocumentModal",
    components: {Button, Form, FormInputCheck, ModalContainer},
    props: {
        document_id: {
            type: Number,
            required: true
        },
        role: {
            type: String,
            required: true
        },
        trainer_signed_at: {
            type: String,
            required: false
        },
        employee_signed_at: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            sign_employee: false,
            sign_trainer: false,
            is_saving: false,
        }
    },
    methods: {
        close(status) {
            this.$modal.hide('document-controller-sign-document-modal', status);
        },
        async signEmployee() {
            let payload = {
                role: this.role,
                user_id: this.$route.params.id,
                sign_for: 'employee'
            };

            await this.$axios.post(`documents/${this.document_id}/sign`, payload)
                .then(({data}) => {
                    this.is_saving = false;

                    this.$notify({
                        title: this.$t('success'),
                        text: this.$t('documents.document_signed'),
                        type: 'success',
                    });
                })
                .catch(e => {
                    this.is_saving = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('documents.error_sign_document')),
                        type: 'error',
                    });
                });
        },
        async signTrainer() {
            let payload = {
                role: 'document_controller',
                user_id: this.$route.params.id,
                sign_for: 'trainer'
            };

            await this.$axios.post(`documents/${this.document_id}/sign`, payload)
                .then(({data}) => {
                    this.is_saving = false;

                    this.$notify({
                        title: this.$t('success'),
                        text: this.$t('documents.document_signed'),
                        type: 'success',
                    });
                })
                .catch(e => {
                    this.is_saving = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('documents.error_sign_document')),
                        type: 'error',
                    });
                });
        },
        async sign() {
            if(!this.sign_employee && !this.sign_trainer) return;

            this.is_saving = true;

            let functions = [];

            if(this.sign_employee) functions.push(this.signEmployee());
            if(this.sign_trainer) functions.push(this.signTrainer());

            await Promise.all(functions);

            this.is_saving = false;
            this.close(true);
        },
    },
}
</script>

<style lang="scss" scoped>
.document-controller-sign-document-modal{
    .text {
        @apply font-bold mx-auto text-center text-grey mt-6;
    }

    .subtext {
        @apply italic mx-auto text-center text-sm text-grey;
    }

    .checkboxes-wrapper {
        @apply flex flex-col mt-8 mb-12 mx-auto;

        @screen sm {
            @apply flex-row;
        }

        .checkbox-container {
            @apply flex flex-row items-center mr-auto;

            &:first-of-type {
                @apply mb-4;

                @screen sm {
                    @apply mb-0;
                }
            }

            @screen sm {
                @apply mx-4;
            }

            & > input {
                @apply mr-4 my-auto w-6 h-6 rounded-lg;
                accent-color: theme('colors.primary');
            }

            & > label {
                @apply text-grey font-bold my-auto;
            }
        }
    }
}
</style>