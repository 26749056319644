<template>
    <div class="section">
        <Headbar>
            <template v-slot:left>
                <h1>{{ $t('country_groups.country_groups') }}</h1>
            </template>
            <template v-slot:right>
                <Button className="--primary --small" :onclick="toggleCreate" v-if="$store.getters.hasAnyPermission(['store country groups'])">{{$t('country_groups.add_group')}}</Button>
            </template>
        </Headbar>

        <main>
            <vue-good-table
                mode="remote"
                styleClass="vgt-table vgt-custom"
                :columns="columns"
                :rows="country_groups"
                :isLoading.sync="is_loading_country_groups"
                :search-options="{
                    enabled: false,
                }"
                :pagination-options="{
                    enabled: true,
                    mode: 'records',
                    dropdownAllowAll: false,
                    perPage: 10,
                    perPageDropdownEnabled: true,
                    rowsPerPageLabel: $t('x_per_page', {x: $t('country_groups.country_groups')}),
                }"
                :sort-options="{
                  enabled: true,
                  multipleColumns: true,
                }"
                :totalRows="totalRecords"
                @on-page-change="onPageChange"
                @on-per-page-change="onPerPageChange"
                @on-sort-change="onSortChange">
                <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field === 'after'" class="td-after">
                        <Button className="--secondary --outline --mini --big-text" v-if="$store.getters.hasPermission('update country groups')"
                                :onclick="()=>toggleUpdate(props.row)">
                            <font-awesome-icon :icon="['fal', 'pencil']"/>
                        </Button>
                        <Button className="--secondary --outline --mini --big-text" v-if="$store.getters.hasPermission('destroy country groups')"
                                :onclick="()=>toggleDelete(props.row)">
                            <font-awesome-icon :icon="['fal', 'trash']"/>
                        </Button>
                    </div>
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
            </vue-good-table>
        </main>
    </div>
</template>

<script>
import Headbar from "../../components/headbar/Headbar";
import Search from "../../components/Search";
import Button from "../../components/Button";
import Select from "../../components/form/Select";
import ConfirmModal from "../../components/modal/ConfirmModal";
import CreateModal from "./CountryGroupsCreateModal";
import UpdateModal from "./CountryGroupsUpdateModal";
import {EventBus} from "../../EventBus";

export default {
    name: "country-groups-index",
    components: {ConfirmModal, Select, Button, Search, Headbar},
    data: function () {
        const columns = [
            {
                label: this.$t('country_groups.code'),
                field: 'attributes.code',
                sortable: false,
            },
            {
                label: this.$t('country_groups.name'),
                field: 'attributes.name',
                sortable: false,
            },
            {
                label: this.$t('country_groups.countries'),
                field: row => row.relationships.countries.data ? row.relationships.countries.data.map(c => c.attributes.name).join(', ') : '',
                sortable: false,
            },
        ];

        if (this.$store.getters.hasAnyPermission(['update country groups', 'destroy country groups']))
            columns.push({
                label: this.$t('countries.actions'),
                field: 'after',
                tdClass: 'td-after',
                sortable: false
            });

        return {
            columns: columns,
            country_groups: [],
            is_loading_country_groups: false,
            totalRecords: null,
            serverParams: {
                sorting: [],
                per_page: 10
            },
            searchTerm: null,
            is_deleting: false,
            headbarExpanded: false
        }
    },
    methods: {
        toggleCreate() {
            this.$modal.show(
                CreateModal, {},
                {
                    name: 'country-groups-create-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    width: 450,
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true) {
                            EventBus.$emit('get-countries');
                            this.getCountryGroups();
                        }
                    }
                }
            );
        },
        toggleUpdate(country_group) {
            this.$modal.show(
                UpdateModal,
                {
                    country_group_id: country_group.id,
                },
                {
                    name: 'country-groups-update-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    width: 450,
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true) {
                            EventBus.$emit('get-countries');
                            this.getCountryGroups();
                        }
                    }
                }
            );
        },
        toggleDelete(country_group) {
            this.$modal.show(
                ConfirmModal, {
                    title: this.$t('country_groups.delete_country_group'),
                    message: this.$t('country_groups.prompt_delete'),
                    confirmText: this.$t('delete'),
                    cancelText: this.$t('cancel'),
                    confirmClass: '--primary',
                    cancelClass: '--secondary --outline'
                },
                {
                    name: 'confirm-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                },
                {
                    'before-close': (e) => {
                        if (e.params === true) {
                            this.is_deleting = true;
                            this.$axios.delete(`countryGroups/${country_group.id}`)
                                .then(({data}) => {
                                    this.is_deleting = false;
                                    this.getCountryGroups();
                                })
                                .catch(e => {
                                    this.is_deleting = false;

                                    this.$notify({
                                        title: this.$t('error'),
                                        text: this.$larerror(e.response.data, this.$t('countries.error_delete')),
                                        type: 'error',
                                    });
                                });
                        }
                    }
                }
            );
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.getCountryGroups();
        },
        onPerPageChange(params) {
            this.updateParams({per_page: params.currentPerPage});
            this.getCountryGroups();
        },
        onSortChange(params) {
            const sorts = [];

            if (!params || !params.length) {
                this.updateParams({sorting: []});
                return this.getCountryGroups();
            }

            params.forEach(p => {
                if (!p.type || p.type === 'none')
                    return;

                let sort_by = null;
                let sort_order = p.type;
                if (p.field === 'attributes.name')
                    sort_by = 'name';
                else if (p.field === 'attributes.email')
                    sort_by = 'email';
                else if (p.field === 'role')
                    sort_by = 'role';
                else
                    sort_by = p.field.split('.')[1];

                sorts.push({sort_order, sort_by})
            });

            this.updateParams({sorting: sorts});
            this.getCountryGroups();
        },
        removeParam(param) {
            this.$delete(this.serverParams, param);
        },
        search(searchTerm) {
            this.searchTerm = searchTerm;

            if (searchTerm && searchTerm.length)
                this.updateParams({term: searchTerm});
            else this.removeParam('term');

            this.getCountryGroups();
        },
        getCountryGroups() {
            this.is_loading_country_groups = true;

            const encodedSorting = this.serverParams.sorting.map(f => btoa(JSON.stringify(f)));

            this.$axios.get('countryGroups', {params: {...this.serverParams, sorting: encodedSorting}})
                .then(({data}) => {
                    this.country_groups = data.data;
                    this.totalRecords = data.meta.total;
                    this.is_loading_country_groups = false;
                })
                .catch(e => {
                    this.is_loading_country_groups = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('countries.error_retrieve')),
                        type: 'error',
                    });
                });
        }
    },
    mounted() {
        this.getCountryGroups();
    }
}
</script>

<style lang="scss" scoped>
.page-container {
    @apply flex flex-col;

    @screen lg {
        @apply flex-row;
    }

    main, .headbar-container {
        &:first-of-type {
            @screen lg {
                @apply pr-4;
            }

            @screen xl {
                @apply pr-6;
            }
        }

        &:last-of-type {
            @screen lg {
                @apply pl-4;
            }

            @screen xl {
                @apply pl-6;
            }
        }
    }

    .headbar-expanded-container {
        @apply mt-4 flex flex-col gap-y-4 items-center;

        @screen md {
            @apply hidden;
        }
    }

    .select-dropdown {
        @apply min-w-40;
    }

    .btn-search-mobile, .btn-filter-mobile {
        @apply block text-primary w-6;

        @screen md {
            @apply hidden;
        }

        &:active, &:focus {
            @apply outline-none;
        }
    }

    .search-desktop, .filter-desktop {
        @apply hidden;

        @screen md {
            @apply block;
        }
    }

    .search-container {
        @apply mr-0;
    }

    .filter-mobile {
        @apply max-w-xs;
    }

    .td-after {
        @apply flex flex-row;

        & > * {
            @apply mr-3;

            &:last-child {
                @apply mr-0;
            }
        }
    }
}
</style>
