<template>
    <div class="job-roles-create-modal">
        <ModalContainer :title="$t('job_roles.add_job_role')" identifier="job-roles-create-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                <FormInputText v-model="$v.job_role.name.$model" ref="name" identifier="name" :label="$t('job_roles.name')"
                               :disabled="is_saving || is_loading" :has-error="$v.job_role.name.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.job_role.name.required">
                            {{ $t('validation.x_is_required', {x: $t('job_roles.name')}) }}
                        </p>
                    </template>
                </FormInputText>

                <FormGroupTwo>
                    <FormInputSelect v-model="$v.job_role.sites.$model" identifier="sites" :label="$t('job_roles.sites')" :options="site_options"
                                     :disabled="is_saving || is_loading" :has-error="$v.job_role.sites.$error" track-by="id"
                                     :multiple="true" display-label="name">
                        <template v-slot:errors>
                            <p v-if="!$v.job_role.sites.required">
                                {{$t('validation.x_is_required',{x: $t('job_roles.sites')})}}
                            </p>
                        </template>
                    </FormInputSelect>

                    <FormInputSelect v-model="$v.job_role.site_groups.$model" identifier="site_groups" :label="$t('job_roles.site_groups')" :options="site_group_options"
                                     :disabled="is_saving || is_loading" :has-error="$v.job_role.site_groups.$error" track-by="id"
                                     :multiple="true" display-label="name">
                        <template v-slot:errors>
                            <p v-if="!$v.job_role.site_groups.required">
                                {{$t('validation.x_is_required',{x: $t('job_roles.site_groups')})}}
                            </p>
                        </template>
                    </FormInputSelect>

                    <FormInputSelect v-model="$v.job_role.departments.$model" identifier="sites" :label="$t('job_roles.departments')" :options="department_options"
                                     :disabled="is_saving || is_loading" :has-error="$v.job_role.departments.$error" track-by="id"
                                     :multiple="true" display-label="name">
                        <template v-slot:errors>
                            <p v-if="!$v.job_role.departments.required">
                                {{$t('validation.x_is_required',{x: $t('job_roles.departments')})}}
                            </p>
                        </template>
                    </FormInputSelect>

                    <FormInputSelect v-model="$v.job_role.manager.$model" identifier="sites" :label="$t('job_roles.manager')" :options="job_role_options"
                                     :disabled="is_saving || is_loading" :has-error="$v.job_role.manager.$error" track-by="id" display-label="name">
                        <template v-slot:errors>
                            <p v-if="!$v.job_role.manager.required">
                                {{$t('validation.x_is_required',{x: $t('job_roles.manager')})}}
                            </p>
                        </template>
                    </FormInputSelect>
                </FormGroupTwo>

                <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                    {{ $t('save') }}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormInputSelect from "../form/FormInputSelect";
import FormGroupTwo from "../form/FormGroupTwo";
import {required} from 'vuelidate/lib/validators'
import Button from "../Button";

export default {
    name: "JobRolesCreateModal",
    components: {Button, FormInputSelect, FormInputText, Form, FormGroupTwo, ModalContainer},
    data() {
        return {
            job_role: {
                name: null,
                sites: [],
                site_groups: [],
                departments: [],
                manager: null,
            },
            site_options: [],
            site_group_options: [],
            department_options: [],
            job_role_options: [],
            is_loading_sites: false,
            is_loading_site_groups: false,
            is_loading_departments: false,
            is_loading_job_roles: false,
            is_saving: false,
        }
    },
    validations: {
        job_role: {
            name: {required},
            sites: {},
            site_groups: {},
            departments: {},
            manager: {},
        }
    },
    computed: {
        is_loading() {
            return this.is_loading_sites || this.is_loading_site_groups || this.is_loading_departments || this.is_loading_job_roles;
        }
    },
    methods: {
        close(status) {
            this.$modal.hide('job-roles-create-modal', status);
        },
        save() {
            this.$v.job_role.$touch();
            if (this.$v.job_role.$anyError || this.is_saving)
                return;

            this.is_saving = true;

            let payload = {
                name: this.job_role.name
            };

            if(this.job_role.sites.length) payload.sites = this.job_role.sites.map(s => s.id);
            if(this.job_role.site_groups.length) payload.site_groups = this.job_role.site_groups.map(sg => sg.id);
            if(this.job_role.departments.length) payload.departments = this.job_role.departments.map(d => d.id);
            if(this.job_role.manager) payload.manager_job_role_id = this.job_role.manager.id;

            this.$axios.post(`jobRoles`, payload).then(({data}) => {
                this.is_saving = false;
                this.close(true);
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('job_roles.error_create')),
                    type: 'error',
                });
            });
        },
        getSites() {
            this.is_loading_sites = true;

            this.$axios.get('sites/list')
                .then(({data}) => {
                    this.site_options = data.data.map(s => ({id: s.id, name: s.attributes.name}));
                    this.is_loading_sites = false;
                })
                .catch(e => {
                    this.is_loading_sites = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('sites.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        getSiteGroups() {
            this.is_loading_site_groups = true;

            this.$axios.get('siteGroups/list')
                .then(({data}) => {
                    this.site_group_options = data.data.map(sg => ({id: sg.id, name: sg.attributes.name}));
                    this.is_loading_site_groups = false;
                })
                .catch(e => {
                    this.is_loading_site_groups = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('site_groups.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        getDepartments() {
            this.is_loading_departments = true;

            this.$axios.get('departments/list')
                .then(({data}) => {
                    this.department_options = data.data.map(d => ({id: d.id, name: d.attributes.name}));
                    this.is_loading_departments = false;
                })
                .catch(e => {
                    this.is_loading_departments = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('departments.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        getJobRoles() {
            this.is_loading_job_roles = true;

            this.$axios.get('jobRoles/list')
                .then(({data}) => {
                    this.job_role_options = data.data.map(job_role => ({id: job_role.id, name: job_role.attributes.name}));
                    this.is_loading_job_roles = false;
                })
                .catch(e => {
                    this.is_loading_job_roles = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('job_roles.error_retrieve')),
                        trainer: 'error',
                    });
                });
        },
    },
    mounted() {
        this.getSites();
        this.getSiteGroups();
        this.getDepartments();
        this.getJobRoles();

        this.$refs.name.$el.children[1].focus();
    }
}
</script>


<style lang="scss" scoped>
.select {
    @apply mb-5;
}
</style>