<template>
    <div class="documents-update-modal">
        <ModalContainer :title="$t('documents.update_document')" identifier="documents-update-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving || is_loading_document">
                <FormGroupTwo>
                    <FormInputText v-model="$v.document.name.$model" identifier="name" :label="$t('documents.name')"
                                   :disabled="is_saving || is_loading_document"
                                   :has-error="$v.document.name.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.document.name.required">
                                {{ $t('validation.x_is_required', {x: $t('documents.name')}) }}
                            </p>
                        </template>
                    </FormInputText>

                    <FormInputText v-model="$v.document.version.$model" identifier="version" :label="$t('documents.version')"
                                   :disabled="is_saving || is_loading_document"
                                   :has-error="$v.document.version.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.document.version.required">
                                {{ $t('validation.x_is_required', {x: $t('documents.version')}) }}
                            </p>
                        </template>
                    </FormInputText>
                </FormGroupTwo>

                <div class="checkboxes-wrapper">
                    <div class="checkbox-container">
                        <input type="checkbox" id="employee-check" v-model="remove_employee_signatures"/>
                        <label for="employee-check">{{$t('documents.remove_employee_signatures')}}</label>
                    </div>

                    <div class="checkbox-container">
                        <input type="checkbox" id="trainer-check" v-model="remove_trainer_signatures"/>
                        <label for="trainer-check">{{$t('documents.remove_trainer_signatures')}}</label>
                    </div>
                </div>

                <div class="checkbox-container send-email-checkbox" >
                    <input type="checkbox" id="email-check" v-model="send_email"/>
                    <label for="email-check">{{$t('documents.send_email')}}</label>
                </div>

                <FormInputText v-if="minor_update && send_email" v-model="$v.document.message.$model" identifier="message"
                               :label="$t('documents.message')" :disabled="is_saving || is_loading_document"
                               :has-error="$v.document.message.$error" useTextarea largeTextarea>
                    <template v-slot:errors>
                        <p v-if="!$v.document.message.required">
                            {{ $t('validation.x_is_required', {x: $t('documents.message')}) }}
                        </p>
                    </template>
                </FormInputText>

                <FormFileUpload v-model="$v.document.file_upload.$model" :label="$t('documents.upload')" :has-error="$v.document.file_upload.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.document.file_upload.required">
                            {{$t('validation.x_is_required',{x: $t('documents.file_upload')})}}
                        </p>
                    </template>
                </FormFileUpload>

                <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                    {{ $t('update') }}
                </Button>
            </Form>
        </ModalContainer>

        <vue-html2pdf
            :enable-download="true"
            :filename="`${this.document.name} Related Users`"
            :pdf-quality="2"
            :manual-pagination="true"
            pdf-format="a4"
            pdf-orientation="portrait"
            @beforeDownload="beforePDFDownload($event)"
            @hasDownloaded="hasPDFDownloaded($event)"
            ref="html2Pdf"
        >
            <section slot="pdf-content" class="pdf-content">
                <h1>{{document.name}} {{$t('documents.related_users')}}</h1>

                <div class="users-page" v-for="(page, index) in printing_pages" :key="index">
                    <p v-for="user in page">{{user.id}} - {{user.attributes.name}} - {{user.attributes.email}}</p>
                    <div v-if="index < printing_pages.length" class="html2pdf__page-break"/>
                </div>
            </section>
        </vue-html2pdf>
    </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormInputSelect from "../form/FormInputSelect";
import FormFileUpload from "../form/FormFileUpload";
import {required, requiredIf} from 'vuelidate/lib/validators'
import Button from "../Button";
import FormGroupTwo from "../form/FormGroupTwo";

export default {
    name: "DocumentsUpdateModal",
    components: {Button, FormInputSelect, FormInputText, Form, ModalContainer, FormGroupTwo, FormFileUpload},
    props: {
        document_id: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            document: {
                name: null,
                version: null,
                message: null,
                file_upload: null,
                
            },
            send_email: false,
            minor_update: true,
            major_update: false,
            remove_trainer_signatures: false,
            remove_employee_signatures: false,
            printing_pages: [],
            is_saving: false,
            is_printing: false,
            is_loading_document: true,
           
        }
    },
    validations: {
        document: {
            name: {required},
            version: {required},
            message: {
                required: requiredIf(function () {
                    return this.send_email;
                }),
            },
            file_upload: {required},
        }
    },
    methods: {
        close(status) {
            this.$modal.hide('documents-update-modal', status);
        },
        async generatePDFDownload() {
            this.is_printing = true;
            await this.$refs.html2Pdf.generatePdf()
        },
        async beforePDFDownload() {
            this.is_printing = true;
        },
        async hasPDFDownloaded(){
            this.is_printing = false;
        },
        toggleUpdateType() {
            this.minor_update = !this.minor_update;
            this.major_update = !this.major_update;

            if(this.major_update)
                this.send_email = false;
                this.remove_trainer_signatures = false;
                this.remove_employee_signatures = false;
        },
        async sendMessage() {
            let payload = {
                type: this.minor_update ? 'yes' : 'no',
            };

            if(this.minor_update) {
                payload.send_email_notification = this.send_email ? true : false;
                payload.remove_trainer_signatures = this.remove_trainer_signatures ? true : false;
                payload.remove_employee_signatures = this.remove_employee_signatures ? true : false;
                if(this.document.message) payload.message = this.document.message;
            }

            await this.$axios.post(`documents/${this.document_id}/revision-update`, payload)
                .then(async ({data}) => {
                    if(this.major_update) {
                        this.printing_pages = [];
                        let temp_users_printing_array = _.clone(data.data);

                        while (temp_users_printing_array.length > 0)
                            this.printing_pages.push(temp_users_printing_array.splice(0, !this.printing_pages.length ? 42 : 44));

                        await this.generatePDFDownload();
                    }
                }).catch(e => {
                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data.errors, this.$t('documents.error_send_message')),
                        type: 'error',
                    });
            });
        },
        save() {
            this.$v.document.$touch();
            if (this.$v.document.$anyError || this.is_saving)
                return;

            this.is_saving = true;

            let formData = new FormData();
            formData.append('name', this.document.name);
            formData.append('version', this.document.version);
            formData.append('document', this.document.file_upload);
            formData.append('attachment_name', this.document.file_upload.name);

            this.$axios.post(`documents/${this.document_id}/revise`, formData)
                .then(async ({data}) => {
                    await this.sendMessage();

                    this.is_saving = false;
                    this.close(true);
                }).catch(e => {
                    this.is_saving = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data.errors, this.$t('documents.error_update')),
                        type: 'error',
                    });
            });
        },
        getDocument() {
            this.is_loading_document = true;

            this.$axios.get(`documents/${this.document_id}`)
                .then(({data}) => {
                    this.document.name = data.data.attributes.name;
                    this.document.version = data.data.attributes.version;

                    this.is_loading_document = false;
                })
                .catch(e => {
                    this.is_loading_document = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('documents.error_retrieve')),
                        type: 'error',
                    });
                });
        },
    },
    async mounted() {
        this.getDocument();
    }
}
</script>

<style lang="scss">
.documents-update-modal {
    .send-email-checkbox {
        @apply mb-6;
    }

    .checkbox-container {
        @apply flex flex-row items-center mr-4;

        & > input {
            @apply mr-2 my-auto w-6 h-6 rounded-lg;
            accent-color: theme('colors.primary');
        }

        & > label {
            @apply text-grey font-bold my-auto;
        }
    }

    .checkboxes-wrapper {
        @apply flex flex-row mt-2 mb-6;
    }
}

.pdf-content {
    @apply px-8;

    h1 {
        @apply mt-4 mb-8 text-3xl;
    }

}
</style>
