<template>
    <loading-screen v-if="is_loading"></loading-screen>
    <div class="page-container" v-else-if="!is_loading">
        <Headbar>
            <template v-slot:left>
                <h1 v-if="original">{{ original.attributes.name }}</h1>
            </template>
            <template v-slot:right>
                <Button className="--primary --small --wider"
                        :class="{spinner: is_saving}" :onclick="save"
                        v-if="$store.getters.hasAnyPermission(['update documents'])">
                    {{ $t('save') }}
                </Button>
            </template>
        </Headbar>
        <main>
            <div class="document-container">
                <div class="document-details-container">
                    <SectionHeader :title="$t('documents.document_details')"></SectionHeader>
                    <Form class="form" @submit="save"
                          :disabled="!$store.getters.hasPermission('update documents') || is_saving">
                        <FormGroupThree>
                            <FormInputText v-model="$v.document.name.$model" identifier="name"
                                           :label="$t('documents.name')"
                                           :disabled="is_saving" :has-error="$v.document.name.$error">
                                <template v-slot:errors>
                                    <p v-if="!$v.document.name.required">
                                        {{ $t('validation.x_is_required', {x: $t('documents.name')}) }}
                                    </p>
                                </template>
                            </FormInputText>

                            <FormInputSelect v-model="$v.document.type.$model" identifier="type"
                                             :label="$t('documents.type')" :options="type_options"
                                             :disabled="is_saving || is_loading_types"
                                             :has-error="$v.document.type.$error" track-by="id"
                                             :display-custom-label="(row) => `${row.attributes.name}`">
                                <template v-slot:errors>
                                    <p v-if="!$v.document.type.required">
                                        {{ $t('validation.x_is_required', {x: $t('documents.type')}) }}
                                    </p>
                                </template>
                            </FormInputSelect>

                            <FormInputSelect v-model="$v.document.category.$model" identifier="category"
                                             :label="$t('documents.category')" :options="category_options"
                                             :disabled="is_saving || is_loading_categories"
                                             :has-error="$v.document.category.$error" track-by="id"
                                             :display-custom-label="(row) => `${row.attributes.name}`">
                                <template v-slot:errors>
                                    <p v-if="!$v.document.category.required">
                                        {{ $t('validation.x_is_required', {x: $t('documents.category')}) }}
                                    </p>
                                </template>
                            </FormInputSelect>

                            <FormInputText v-model="$v.document.language.$model" identifier="language"
                                           :label="$t('documents.language')"
                                           :disabled="is_saving" :has-error="$v.document.language.$error">
                                <template v-slot:errors>
                                    <p v-if="!$v.document.language.required">
                                        {{ $t('validation.x_is_required', {x: $t('documents.language')}) }}
                                    </p>
                                </template>
                            </FormInputText>

                            <FormInputSelect v-model="$v.document.module.$model" identifier="module"
                                             :label="$t('documents.module')" :options="module_options"
                                             :disabled="is_saving || is_loading_modules"
                                             :has-error="$v.document.module.$error" track-by="id"
                                             :display-custom-label="(row) => `${row.attributes.name}`">
                                <template v-slot:errors>
                                    <p v-if="!$v.document.module.required">
                                        {{ $t('validation.x_is_required', {x: $t('documents.module')}) }}
                                    </p>
                                </template>
                            </FormInputSelect>

                            <FormInputText v-model="$v.document.version.$model" identifier="version"
                                           :label="$t('documents.version')"
                                           :disabled="is_saving" :has-error="$v.document.version.$error">
                                <template v-slot:errors>
                                    <p v-if="!$v.document.version.required">
                                        {{ $t('validation.x_is_required', {x: $t('documents.version')}) }}
                                    </p>
                                </template>
                            </FormInputText>

                            <FormInputSelect v-model="$v.document.trainers.$model" identifier="trainer"
                                             :label="$t('documents.trainers')" :options="job_role_options"
                                             :disabled="is_saving || is_loading_job_roles" :multiple="true"
                                             :has-error="$v.document.trainers.$error" track-by="id"
                                             :display-custom-label="(row) => `${row.attributes.name}`">
                                <template v-slot:errors>
                                    <p v-if="!$v.document.trainer.required">
                                        {{ $t('validation.x_is_required', {x: $t('documents.trainers')}) }}
                                    </p>
                                </template>
                            </FormInputSelect>


                            <FormInputText v-model="$v.document.review_num.$model" identifier="review_num"
                                           :label="$t('documents.review_num')"
                                           :disabled="is_saving" :has-error="$v.document.review_num.$error">
                                <template v-slot:errors>
                                    <p v-if="!$v.document.review_num.required">
                                        {{ $t('validation.x_is_required', {x: $t('documents.review_num')}) }}
                                    </p>
                                </template>
                            </FormInputText>

                            <FormInputDateTime v-model="$v.document.next_review_date.$model"
                                               identifier="next_review_date"
                                               :label="$t('documents.next_review_date')" :disabled="is_saving"
                                               :only-date="true"
                                               :hasError="$v.document.next_review_date.$error" :min-date="todayDate"
                                               formatted="DD/MM/YYYY">
                                <template v-slot:errors>
                                    <p v-if="!$v.document.next_review_date.required">
                                        {{ $t('validation.x_is_required', {x: $t('documents.next_review_date')}) }}
                                    </p>
                                </template>
                            </FormInputDateTime>

                            <FormInputText v-model="$v.document.revision_details.$model" identifier="revision_details"
                                           :label="$t('documents.revision_details')"
                                           :disabled="is_saving" :has-error="$v.document.revision_details.$error">
                                <template v-slot:errors>
                                    <p v-if="!$v.document.revision_details.required">
                                        {{ $t('validation.x_is_required', {x: $t('documents.revision_details')}) }}
                                    </p>
                                </template>
                            </FormInputText>

                            <FormInputText v-model="$v.document.reference.$model" identifier="reference"
                                           :label="$t('documents.reference')"
                                           :disabled="is_saving" :has-error="$v.document.reference.$error">
                                <template v-slot:errors>
                                    <p v-if="!$v.document.reference.required">
                                        {{ $t('validation.x_is_required', {x: $t('documents.reference')}) }}
                                    </p>
                                </template>
                            </FormInputText>

                            <FormInputText v-model="$v.document.description.$model" identifier="description"
                                           :label="$t('documents.description')"
                                           :disabled="is_saving" :has-error="$v.document.description.$error">
                                <template v-slot:errors>
                                    <p v-if="!$v.document.description.required">
                                        {{ $t('validation.x_is_required', {x: $t('documents.description')}) }}
                                    </p>
                                </template>
                            </FormInputText>

                            <FormInputSelect v-model="$v.document.sites.$model" identifier="sites"
                                             :label="$t('documents.sites')" :options="site_options"
                                             :disabled="is_saving || is_loading_sites"
                                             :has-error="$v.document.sites.$error" track-by="id"
                                             :display-custom-label="(row) => `${row.attributes.name}`" :multiple="true">
                                <template v-slot:errors>
                                    <p v-if="!$v.document.sites.required">
                                        {{ $t('validation.x_is_required', {x: $t('documents.sites')}) }}
                                    </p>
                                </template>
                            </FormInputSelect>

                            <FormInputSelect v-model="$v.document.site_groups.$model" identifier="sites"
                                             :label="$t('documents.site_groups')" :options="site_group_options"
                                             :disabled="is_saving || is_loading_site_groups"
                                             :has-error="$v.document.sites.$error" track-by="id"
                                             :display-custom-label="(row) => `${row.attributes.name}`" :multiple="true">
                                <template v-slot:errors>
                                    <p v-if="!$v.document.site_groups.required">
                                        {{ $t('validation.x_is_required', {x: $t('documents.site_groups')}) }}
                                    </p>
                                </template>
                            </FormInputSelect>

                            <FormInputSelect v-model="$v.document.departmental_owner.$model"
                                             identifier="departmental_owner" :label="$t('documents.departmental_owner')"
                                             :options="department_options"
                                             :disabled="is_saving || is_loading_departmental_owners"
                                             :has-error="$v.document.departmental_owner.$error" track-by="id"
                                             :display-custom-label="(row) => `${row.attributes.name}`">
                                <template v-slot:errors>
                                    <p v-if="!$v.document.departmental_owner.required">
                                        {{ $t('validation.x_is_required', {x: $t('documents.departmental_owner')}) }}
                                    </p>
                                </template>
                            </FormInputSelect>

                            <FormInputSelect v-model="$v.document.departments.$model" identifier="departments"
                                             :label="$t('documents.departments')" :options="department_options"
                                             :disabled="is_saving || is_loading_departments"
                                             :has-error="$v.document.departments.$error" track-by="id"
                                             :display-custom-label="(row) => `${row.attributes.name}`" :multiple="true">
                                <template v-slot:errors>
                                    <p v-if="!$v.document.departments.required">
                                        {{ $t('validation.x_is_required', {x: $t('documents.departments')}) }}
                                    </p>
                                </template>
                            </FormInputSelect>

                            <FormInputSelect v-model="$v.document.manager.$model" identifier="manager"
                                             :label="$t('documents.manager')" :options="job_role_options"
                                             :disabled="is_saving || is_loading_job_roles"
                                             :has-error="$v.document.manager.$error" track-by="id"
                                             :display-custom-label="(row) => `${row.attributes.name}`">
                                <template v-slot:errors>
                                    <p v-if="!$v.document.manager.required">
                                        {{ $t('validation.x_is_required', {x: $t('documents.manager')}) }}
                                    </p>
                                </template>
                            </FormInputSelect>

                            <div class="checkbox-container input-group">
                                <input type="checkbox" id="line-manager-check" v-model="$v.document.is_line_manager.$model">
                                <label for="line-manager-check">{{$t('documents.activate_line_manager')}}</label>
                            </div>
                        </FormGroupThree>
                        <FormGroupThree>
                            <div class="checkbox-container input-group remove-margin-left">
                                <input type="checkbox" id="is_training_material" v-model="$v.document.is_training_material.$model">
                                <label for="is_training_material">{{$t('documents.is_training_material')}}</label>
                            </div>

                        </FormGroupThree>
                    </Form>
                </div>
                <div class="document-info-container">
                    <SectionHeader :title="$t('documents.document')"></SectionHeader>
                    <div class="document-info">
                        <div class="info-text-container">
                            <div class="info-text">
                                <p class="info-title">{{ $t('documents.date_uploaded') }}</p>
                                <p>{{ $moment(original.attributes.created_at).format('DD/MM/YYYY HH:mm') }}</p>
                            </div>
                            <div class="info-text">
                                <p class="info-title">{{ $t('documents.last_updated') }}</p>
                                <p>{{ $moment(original.attributes.last_updated_at).format('DD/MM/YYYY - HH:mm') }}</p>
                            </div>
                        </div>
                        <div class="options-container">
                            <div class="filename-container">
                                <p>{{ original.attributes.attachment_name }}</p>
                            </div>
                            <div class="buttons-container">
                                <Button className="--secondary --outline --small --big-text"
                                        :onclick="() => downloadDocument(original.id, original.attributes.attachment_name)">
                                    <span class="icon-button"><font-awesome-icon :icon="['far', 'download']"/>{{$t('documents.download')}}</span>
                                </Button>
                                <Button className="--secondary --outline --small --big-text" :onclick="toggleUpdate">
                                    <span class="icon-button"><font-awesome-icon :icon="['far', 'retweet']"/>{{$t('documents.update')}}</span>
                                </Button>
                                <Button className="--secondary --outline --small --big-text" :onclick="copyLink">
                                    <span class="icon-button"><font-awesome-icon :icon="['far', 'link']"/>{{ $t('documents.copy_link') }}</span>
                                </Button>

                                <div v-if="!documentPreviewSupported" :content="$t('documents.file_type_preview_not_supported')" v-tippy="{ placement : 'top',  arrow: true }">
                                    <Button className="--secondary --outline --small --big-text" disabled>
                                        <span class="icon-button"><font-awesome-icon :icon="['far', 'external-link']"/>{{ $t('documents.preview') }}</span>
                                    </Button>
                                </div>

                                <Button v-else className="--secondary --outline --small --big-text" :onclick="previewDocument">
                                    <span class="icon-button"><font-awesome-icon :icon="['far', 'external-link']"/>{{ $t('documents.preview') }}</span>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="audits-container">
                <div class="title-container">
                    <h1>{{ $t('audits.audits') }}</h1>
                </div>
                <vue-good-table
                    mode="remote"
                    styleClass="vgt-table vgt-custom"
                    :columns="auditsColumns"
                    :rows="audits"
                    :isLoading.sync="is_loading_audits"
                    :search-options="{enabled: false}"
                    :pagination-options="{
                    enabled: true,
                    mode: 'records',
                    dropdownAllowAll: false,
                    perPage: 15,
                    perPageDropdownEnabled: false,
                    rowsPerPageLabel: $t('x_per_page', {x: $t('audits.audits')}),
                }"
                    :sort-options="{
                  enabled: true,
                  multipleColumns: true,
                }"
                    :totalRows="totalRecordsAudits"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange">
                    <template slot="table-row" slot-scope="props">
                        <div v-if="props.column.field === 'status'">
                            <div class="not-available-container">
                                <div class="not-available-circle"></div>
                                <p>{{ $t('profile.not_available') }}</p>
                            </div>
                        </div>
                        <div v-else-if="props.column.field === 'attributes.event'">
                            <p style="text-transform: capitalize;">{{ props.row.attributes.event }}</p>
                        </div>
                        <div v-else-if="props.column.field === 'values'" class="td-values">
                            <div v-if="Object.keys(props.row.attributes.old_values).length" class="row">
                                <p class="name" style="font-weight: bold">{{ $t('documents.old_values') }}</p>
                                <div class="values">
                                    <p style="text-transform: capitalize"
                                       v-for="key in Object.keys(props.row.attributes.old_values)"
                                       v-if="props.row.attributes.old_values[key]">{{ $t(`documents.${key}`) }}:
                                        {{ props.row.attributes.old_values[key] }}</p>
                                </div>
                            </div>
                            <div v-if="Object.keys(props.row.attributes.new_values).length" class="row">
                                <p class="name" style="font-weight: bold">{{ $t('documents.new_values') }}</p>
                                <div class="values">
                                    <p style="text-transform: capitalize"
                                       v-for="key in Object.keys(props.row.attributes.new_values)"
                                       v-if="props.row.attributes.new_values[key]">{{ $t(`documents.${key}`) }}:
                                        {{ props.row.attributes.new_values[key] }}</p>
                                </div>
                            </div>
                        </div>
                        <div v-else-if="props.column.field === 'attributes.created_at'">
                            <p style="text-transform: capitalize;">
                                {{ $moment(props.row.attributes.to).format('DD/MM/YYYY - HH:mm') }}</p>
                        </div>
                        <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                    </template>
                </vue-good-table>
            </div>
        </main>
    </div>
</template>

<script>
import _ from 'lodash';
import Headbar from "../../components/headbar/Headbar";
import Form from "../../components/form/Form";
import SectionHeader from "../../components/SectionHeader";
import Button from "../../components/Button";
import FormInputText from "../../components/form/FormInputText";
import FormInputSelect from "../../components/form/FormInputSelect";
import FormInputDateTime from "../../components/form/FormInputDateTime";
import FormGroupThree from "../../components/form/FormGroupThree";
import FormFileUpload from "../../components/form/FormFileUpload";
import {required, requiredIf} from 'vuelidate/lib/validators'
import FormGroupTwo from "../../components/form/FormGroupTwo";
import DocumentsUpdateModal from "../../components/documents/DocumentsUpdateModal";
import LoadingScreen from "../../components/LoadingScreen";

export default {
    name: "documents-update-page",
    components: {
        FormGroupTwo,
        FormInputText,
        FormInputSelect,
        FormInputDateTime,
        Button,
        SectionHeader,
        Form,
        Headbar,
        FormGroupThree,
        FormFileUpload,
        LoadingScreen
    },
    data: function () {
        return {
            original: null,
            document: {
                name: null,
                type: null,
                category: null,
                language: null,
                module: null,
                version: null,
                trainers: [],
                manager: null,
                review_num: null,
                next_review_date: null,
                revision_details: null,
                reference: null,
                description: null,
                sites: [],
                site_groups: [],
                departmental_owner: null,
                departments: [],
                is_line_manager: false,
                is_training_material:false,
            },
            type_options: [],
            category_options: [],
            module_options: [],
            job_role_options: [],
            site_options: [],
            site_group_options: [],
            department_options: [],
            is_loading: true,
            is_loading_types: false,
            is_loading_categories: false,
            is_loading_modules: false,
            is_loading_job_roles: false,
            is_loading_sites: false,
            is_loading_site_groups: false,
            is_loading_departmental_owners: false,
            is_loading_departments: false,
            is_saving: false,
            audits: [],
            auditsColumns: [
                {
                    label: this.$t('audits.user'),
                    field: 'relationships.user.data.attributes.name',
                    sortable: false,
                },
                {
                    label: this.$t('audits.event'),
                    field: 'attributes.event',
                    sortable: false,
                },
                {
                    label: this.$t('audits.detail'),
                    field: 'values',
                    sortable: false,
                },
                {
                    label: this.$t('audits.date_time'),
                    field: this.createdFn,
                    sortable: false,
                },
            ],
            totalRecordsAudits: 0,
            is_loading_audits: false,
            base_url: process.env.VUE_APP_API_URL.slice(0, -7),
        }
    },
    validations: {
        document: {
            name: {required},
            type: {required},
            category: {required},
            language: {required},
            module: {required},
            version: {required},
            trainers: {},
            manager: {},
            review_num: {},
            next_review_date: {},
            revision_details: {},
            reference: {},
            description: {},
            sites: {
                required: requiredIf(function () {
                    return !this.document.sites.length && !this.document.site_groups.length;
                }),
            },
            site_groups: {
                required: requiredIf(function () {
                    return this.$v.document.sites.length;
                }),
            },
            departmental_owner: {required},
            departments: {required},
            is_line_manager: {},
            is_not_required: {},
            is_training_material:{},
        }
    },
    methods: {
        createdFn(row) {
            return this.$moment(row.attributes.created_at).format('DD/MM/YYYY - HH:mm');
        },
        downloadDocument(document_id, attachment_name) {
            this.$axios.get(`/documents/${document_id}/download`, {
                responseType: 'arraybuffer',
                headers: {
                    'Accept': 'application/pdf',
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                },
                params: {
                    t: new Date().getTime()
                },
            }).then(({data}) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', attachment_name);
                document.body.appendChild(link);
                link.click();
            });
        },
        save() {
            this.$v.document.$touch();
            if (this.$v.document.$anyError || this.is_saving)
                return;

            this.is_saving = true;

            let payload = {
                name: this.document.name,
                type_id: this.document.type.id,
                category_id: this.document.category.id,
                language: this.document.language,
                module_id: this.document.module.id,
                version: this.document.version,
                job_roles: this.document.trainers.map(trainer => trainer.id),
                manager_job_role_id: this.document.manager ? this.document.manager.id : null,
                review_count: this.document.review_num,
                next_review_at: this.document.next_review_date ? this.$moment(this.document.next_review_date).format('YYYY-MM-DD') : null,
                revision_details: this.document.revision_details,
                reference: this.document.reference,
                description: this.document.description,
                sites: this.document.sites.map(site => site.id),
                site_groups: this.document.site_groups.map(site_group => site_group.id),
                department_id: this.document.departmental_owner.id,
                departments: this.document.departments.map(department => department.id),
                is_line_manager: this.document.is_line_manager,
                is_not_required: this.document.is_not_required,
                is_training_material:this.document.is_training_material,
            }

            if (this.document.next_review_date === this.original.attributes.next_review_at)
                delete payload.next_review_at;

            // if(this.document.review_num)
            //     payload.review_count = this.document.review_num;
            //
            // if(this.document.revision_details)
            //     payload.revision_details = this.document.revision_details;
            //
            // if(this.document.reference)
            //     payload.reference = this.document.reference;
            //
            // if(this.document.description)
            //     payload.description = this.document.description;

            this.$axios.patch(`documents/${this.$route.params.id}`, payload)
                .then(async ({}) => {
                    this.is_saving = false

                    if (this.document.name !== this.original.attributes.name)
                        this.original.attributes.name = this.document.name;

                    this.getAudits();

                    this.$notify({
                        text: this.$t('documents.success_updated'),
                        type: 'success',
                    });
                }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('documents.error_update')),
                    type: 'error',
                });
            });
        },
        toggleUpdate() {
            this.$modal.show(
                DocumentsUpdateModal,
                {
                    document_id: Number(this.$route.params.id),
                },
                {
                    name: 'documents-update-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true)
                            this.getOriginalDocument();
                    }
                }
            );
        },
        async copyLink() {
            let encrypted_id = btoa(this.$CryptoJS.AES.encrypt(this.$route.params.id.toString(), 'download').toString());

            let url = `${location.origin}/documents/${encrypted_id}/download`;

            await navigator.clipboard.writeText(url);

            this.$notify({
                title: this.$t('success'),
                text: this.$t('documents.link_copied'),
                type: 'success',
            });
        },
        async previewDocument() {
            let file_type = this.original.attributes.attachment_name.split('.').pop();

            let encrypted_id = btoa(this.$CryptoJS.AES.encrypt(this.$route.params.id.toString(), 'preview').toString());

            let url = `${location.origin}/documents/${encrypted_id}/preview`;

            window.open(url, '_blank');
        },
        async getOriginalDocument() {
            this.is_loading = true;

            await this.$axios.get(`documents/${this.$route.params.id}`)
                .then(({data}) => {
                    this.original = data.data;

                    this.document.name = this.original.attributes.name;
                    this.document.type = _.find(this.type_options, {id: this.original.relationships.type.data.id});
                    this.document.category = _.find(this.category_options, {id: this.original.relationships.category.data.id});
                    this.document.language = this.original.attributes.language;
                    this.document.module = _.find(this.module_options, {id: this.original.relationships.module.data.id});
                    this.document.version = this.original.attributes.version;
                    this.document.trainers = this.job_role_options.filter(trainer => data.data.relationships.job_roles.data.find(job_role => job_role.id === trainer.id));
                    this.document.manager = _.find(this.job_role_options, {id: this.original.relationships.managerJobRole.data.id});
                    this.document.review_num = this.original.attributes.review_count;
                    this.document.next_review_date = this.original.attributes.next_review_at;
                    this.document.revision_details = this.original.attributes.revision_details;
                    this.document.reference = this.original.attributes.reference;
                    this.document.description = this.original.attributes.description;
                    this.document.sites = this.site_options.filter(so => this.original.relationships.sites.data.find(s => s.id === so.id));
                    this.document.site_groups = this.site_group_options.filter(sgo => this.original.relationships.site_groups.data.find(sg => sg.id === sgo.id));
                    this.document.departmental_owner = _.find(this.department_options, {id: this.original.relationships.department.data.id});
                    this.document.departments = this.department_options.filter(dop => this.original.relationships.departments.data.find(d => d.id === dop.id));
                    this.document.is_line_manager = !!this.original.attributes.is_line_manager;
                    this.document.is_not_required = !!this.original.attributes.is_not_required;
                    this.document.is_training_material = !!this.original.attributes.is_training_material;

                    this.is_loading = false;
                })
                .catch(e => {
                    this.is_loading = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('documents.error_retrieve_document')),
                        type: 'error',
                    });
                });
        },
        async getAudits() {
            this.is_loading_audits = true;

            await this.$axios.get(`documents/${this.$route.params.id}/audits`, {
                params: {
                    ...this.serverParams,
                }
            })
                .then(({data}) => {
                    this.audits = data.data
                    this.totalRecordsAudits = data.meta.total;
                    this.is_loading_audits = false
                })
                .catch(e => {
                    this.is_loading_audits = false
                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('documents.error_retrieve_audits')),
                        type: 'error',
                    });
                });
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.getAudits();
        },
        onSortChange(params) {
            const sorts = [];

            if (!params || !params.length) {
                this.updateParams({sorting: []});
                return this.getAudits();
            }

            params.forEach(p => {
                if (!p.type || p.type === 'none')
                    return;

                let sort_by = null;
                let sort_order = p.type;
                if (p.field === 'attributes.name')
                    sort_by = 'name';
                else if (p.field === 'attributes.email')
                    sort_by = 'email';
                else if (p.field === 'role')
                    sort_by = 'role';
                else
                    sort_by = p.field.split('.')[1];

                sorts.push({sort_order, sort_by})
            });

            this.updateParams({sorting: sorts});
            this.getAudits();
        },
        removeParam(param) {
            this.$delete(this.serverParams, param);
        },
        async getTypes() {
            this.is_loading_types = true;

            await this.$axios.get('types/list')
                .then(({data}) => {
                    this.type_options = data.data;
                    this.is_loading_types = false;
                })
                .catch(e => {
                    this.is_loading_types = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('types.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        async getCategories() {
            this.is_loading_categories = true;

            await this.$axios.get('categories/list')
                .then(({data}) => {
                    this.category_options = data.data;
                    this.is_loading_categories = false;
                })
                .catch(e => {
                    this.is_loading_categories = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('categories.error_retrieve')),
                        category: 'error',
                    });
                });
        },
        async getModules() {
            this.is_loading_modules = true;

            await this.$axios.get('modules/list')
                .then(({data}) => {
                    this.module_options = data.data;
                    this.is_loading_modules = false;
                })
                .catch(e => {
                    this.is_loading_modules = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('modules.error_retrieve')),
                        module: 'error',
                    });
                });
        },
        async getJobRoles() {
            this.is_loading_job_roles = true;

            await this.$axios.get('jobRoles/list')
                .then(({data}) => {
                    this.job_role_options = data.data;
                    this.is_loading_job_roles = false;
                })
                .catch(e => {
                    this.is_loading_job_roles = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('trainers.error_retrieve')),
                        trainer: 'error',
                    });
                });
        },
        async getSites() {
            this.is_loading_sites = true;

            await this.$axios.get('sites/list')
                .then(({data}) => {
                    this.site_options = data.data;
                    this.is_loading_sites = false;
                })
                .catch(e => {
                    this.is_loading_sites = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('sites.error_retrieve')),
                        site: 'error',
                    });
                });
        },
        async getSiteGroups() {
            this.is_loading_site_groups = true;

            await this.$axios.get('siteGroups/list')
                .then(({data}) => {
                    this.site_group_options = data.data;
                    this.is_loading_site_groups = false;
                })
                .catch(e => {
                    this.is_loading_site_groups = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('site_groups.error_retrieve')),
                        site: 'error',
                    });
                });
        },
        async getDepartments() {
            this.is_loading_departments = true;

            await this.$axios.get('departments/list')
                .then(({data}) => {
                    this.department_options = data.data;
                    this.is_loading_departments = false;
                })
                .catch(e => {
                    this.is_loading_departments = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('departments.error_retrieve')),
                        type: 'error',
                    });
                });
        },
    },
    computed: {
        todayDate() {
            let date = new Date();
            return this.$moment(date).format('YYYY-MM-DD HH:mm');
        },
        documentPreviewSupported() {
            let file_type = this.original.attributes.attachment_name.split('.').pop();

            return file_type === 'jpg' || file_type === 'jpeg' || file_type === 'png' || file_type === 'doc' || file_type === 'docx'
                || file_type === 'ppt' || file_type === 'pptx'|| file_type === 'xlsx' || file_type === 'pdf';
        }
    },
    async mounted() {
        this.$route.params.id = this.$CryptoJS.AES.decrypt(atob(this.$route.params.id.toString()), 'document').toString(this.$CryptoJS.enc.Utf8);

        if(!this.$route.params.id) {
            this.$router.push('/');
            return;
        }

        await Promise.all([this.getTypes(), this.getCategories(), this.getModules(), this.getJobRoles(), this.getSites(), this.getSiteGroups(), this.getDepartments(), this.getAudits()]);

        await this.getOriginalDocument();
    },
    head() {
        return {
            title: {
                inner: this.$t('documents.document')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
main {
    padding: 0 !important;
}

.document-container {
    @apply flex flex-col border-b-2 border-primary;

    @screen md {
        @apply flex-row;
    }

    .document-details-container {
        @apply py-7 px-4 w-full;

        @screen md {
            @apply pl-8 pr-4 w-7/12;
        }

        form {
            @apply p-8;

            .checkbox-container {
                @apply flex flex-row items-center mt-6 mx-auto;

                &.remove-margin-left {
                    @apply ml-0;
                }

                & > input {
                    @apply mr-2 my-auto w-6 h-6 rounded-lg;
                    accent-color: theme('colors.primary');
                }

                & > label {
                    @apply text-grey font-bold my-auto;
                }
            }
        }
    }

    .document-info-container {
        @apply py-7 px-4 w-full;

        @screen md {
            @apply pr-8 pl-4 w-5/12;
        }

        .document-info {
            @apply flex flex-col gap-y-8 px-8 py-6 bg-white text-grey;

            .info-text-container {
                @apply flex flex-col justify-between gap-y-2;

                @screen 2xl {
                    @apply flex-row gap-y-0;
                }

                .info-text {
                    @apply flex flex-col;

                    @screen 2xl {
                        @apply flex-row gap-x-2;
                    }

                    .info-title {
                        @apply font-bold;
                    }
                }
            }

            .options-container {
                @apply flex flex-col gap-y-4 gap-x-4 w-full;

                .filename-container {
                    @apply w-full flex flex-row font-bold rounded border-2 border-primary text-left;

                    @screen 2xl {
                        @apply w-full;
                    }

                    p {
                        @apply text-base font-normal mx-4 my-2 overflow-hidden;
                        text-overflow: ellipsis;
                    }
                }

                .buttons-container {
                    @apply grid grid-cols-1 gap-y-4;

                    @screen 2xl {
                        @apply grid-cols-2 grid-rows-2 gap-x-4;
                    }

                    & > * {
                        @apply w-full;

                        button {
                            @apply w-full;
                        }
                    }
                }

                .icon-button {
                    @apply flex flex-row items-center gap-x-4
                }
            }
        }
    }
}

.audits-container {
    @apply w-full py-7 px-6;

    @screen md {
        @apply px-9;
    }

    .title-container {
        @apply flex flex-row justify-between mb-4;

        h1 {
            @apply text-grey font-bold text-2xl;
        }
    }

    .not-available-container {
        @apply flex flex-row items-center gap-x-2;

        .not-available-circle {
            @apply bg-black w-4 h-4 rounded-full;
        }
    }

    .td-after {
        @apply flex flex-row;

        & > * {
            @apply mr-3;

            &:last-child {
                @apply mr-0;
            }
        }
    }

    .row {
        &:first-of-type {
            @apply mb-2;
        }

        &:last-of-type {
            @apply mb-0;
        }
    }
}
</style>
