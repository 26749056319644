<template>
    <div class="page-container">
        <Headbar>
            <template v-slot:left>
                <Button className="--primary --small" :onclick="toggleCreate" v-if="$store.getters.hasAnyPermission(['store departments'])">{{ $t('departments.add_department') }}</Button>
            </template>
            <template v-slot:right>
                <Search class="search-desktop" :placeholder="$t('departments.search_departments')" @search="search"/>
                <button class="btn-search-mobile btn-icon-only"
                        @click="headbarExpanded = headbarExpanded === 'search' ? null : 'search'">
                    <font-awesome-icon v-if="headbarExpanded === 'search'" :icon="['far', 'times']"/>
                    <font-awesome-icon v-else :icon="['far', 'search']"/>
                </button>
            </template>
            <template v-slot:expanded>
                <div class="headbar-expanded-container">
                    <Search v-if="headbarExpanded === 'search'" :placeholder="$t('departments.search_departments')" @search="search"/>
                </div>
            </template>
        </Headbar>

        <main>
            <vue-good-table
                ref="table"
                mode="remote"
                styleClass="vgt-table vgt-custom"
                :columns="columns"
                :rows="departments"
                :isLoading.sync="is_loading_departments"
                :search-options="{
                    enabled: false,
                }"
                :pagination-options="{
                    enabled: true,
                    mode: 'records',
                    dropdownAllowAll: false,
                    perPage: 10,
                    perPageDropdownEnabled: true,
                    rowsPerPageLabel: $t('x_per_page', {x: $t('departments.departments')}),
                }"
                :sort-options="{
                  enabled: true,
                  multipleColumns: true,
                }"
                :totalRows="totalRecords"
                @on-page-change="onPageChange"
                @on-per-page-change="onPerPageChange"
                @on-sort-change="onSortChange">
                <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field === 'after'" class="td-after">
                        <Button className="--secondary --outline --mini --big-text" v-if="$store.getters.hasPermission('update departments')"
                                :onclick="()=>toggleUpdate(props.row)">
                            <font-awesome-icon :icon="['fal', 'pencil']"/>
                        </Button>
                        <Button className="--secondary --outline --mini --big-text" v-if="$store.getters.hasPermission('destroy departments')"
                                :onclick="()=>toggleDelete(props.row)">
                            <font-awesome-icon :icon="['fal', 'trash']"/>
                        </Button>
                    </div>
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
            </vue-good-table>
        </main>
    </div>
</template>

<script>
import Headbar from "../../components/headbar/Headbar";
import Search from "../../components/Search";
import Button from "../../components/Button";
import Select from "../../components/form/Select";
import ConfirmModal from "../../components/modal/ConfirmModal";
import CreateModal from "../../components/departments/DepartmentsCreateModal";
import UpdateModal from "../../components/departments/DepartmentsUpdateModal";
import {EventBus} from "../../EventBus";

export default {
    name: "departments-index",
    components: {ConfirmModal, Select, Button, Search, Headbar},
    data: function () {
        const columns = [
            {
                label: this.$t('departments.code'),
                field: 'attributes.code',
                sortable: false,
            },
            {
                label: this.$t('departments.name'),
                field: 'attributes.name',
                sortable: false,
            }
        ];

        if (this.$store.getters.hasAnyPermission(['update departments', 'destroy departments']))
            columns.push({
                label: this.$t('departments.actions'),
                field: 'after',
                tdClass: 'td-after',
                sortable: false
            });

        return {
            columns: columns,
            departments: [],
            is_loading_departments: false,
            totalRecords: null,
            serverParams: {
                sorting: [],
                per_page: 10
            },
            searchTerm: null,
            is_deleting: false,
            headbarExpanded: false
        }
    },
    methods: {
        toggleCreate() {
            this.$modal.show(
                CreateModal, {},
                {
                    name: 'departments-create-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    width: 450,
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true)
                            this.getDepartments();
                    }
                }
            );
        },
        toggleUpdate(department) {
            this.$modal.show(
                UpdateModal,
                {
                    department_id: department.id,
                },
                {
                    name: 'departments-update-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    width: 450,
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true)
                            this.getDepartments();
                    }
                }
            );
        },
        toggleDelete(department) {
            this.$modal.show(
                ConfirmModal, {
                    title: this.$t('departments.delete_department'),
                    message: this.$t('departments.prompt_delete'),
                    confirmText: this.$t('delete'),
                    cancelText: this.$t('cancel'),
                    confirmClass: '--primary',
                    cancelClass: '--secondary --outline'
                },
                {
                    name: 'confirm-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                },
                {
                    'before-close': (e) => {
                        if (e.params === true) {
                            this.is_deleting = true;
                            this.$axios.delete(`departments/${department.id}`)
                                .then(({data}) => {
                                    this.is_deleting = false;
                                    this.getDepartments();
                                })
                                .catch(e => {
                                    this.is_deleting = false;

                                    this.$notify({
                                        title: this.$t('error'),
                                        text: this.$larerror(e.response.data, this.$t('departments.error_delete')),
                                        type: 'error',
                                    });
                                });
                        }
                    }
                }
            );
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.getDepartments();
        },
        onPerPageChange(params) {
            this.updateParams({per_page: params.currentPerPage});
            this.getDepartments();
        },
        onSortChange(params) {
            const sorts = [];

            if (!params || !params.length) {
                this.updateParams({sorting: []});
                return this.getDepartments();
            }

            params.forEach(p => {
                if (!p.type || p.type === 'none')
                    return;

                let sort_by = null;
                let sort_order = p.type;
                if (p.field === 'attributes.name')
                    sort_by = 'name';
                else if (p.field === 'attributes.email')
                    sort_by = 'email';
                else if (p.field === 'role')
                    sort_by = 'role';
                else
                    sort_by = p.field.split('.')[1];

                sorts.push({sort_order, sort_by})
            });

            this.updateParams({sorting: sorts});
            this.getDepartments();
        },
        removeParam(param) {
            this.$delete(this.serverParams, param);
        },
        search(searchTerm) {
            this.searchTerm = searchTerm;

            if (searchTerm && searchTerm.length)
                this.updateParams({term: searchTerm, page: 1});
            else this.removeParam('term');

            this.getDepartments();
        },
        getDepartments() {
            this.is_loading_departments = true;

            const encodedSorting = this.serverParams.sorting.map(f => btoa(JSON.stringify(f)));

            this.$axios.get('departments', {params: {...this.serverParams, sorting: encodedSorting}})
                .then(({data}) => {
                    this.departments = data.data;

                    this.$refs.table.changePage(data.meta.current_page);
                    this.totalRecords = data.meta.total;
                    this.is_loading_departments = false;
                })
                .catch(e => {
                        this.is_loading_departments = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('departments.error_retrieve')),
                            type: 'error',
                        });
                    }
                );
        }
    },
    mounted() {
        EventBus.$on('get-departments', this.getDepartments);
        this.getDepartments();
    },
    beforeDestroy() {
        EventBus.$off('get-departments');
    }
}
</script>

<style lang="scss" scoped>
.page-container {
    .headbar-expanded-container {
        @apply mt-4 flex flex-col gap-y-4 items-center;

        @screen md {
            @apply hidden;
        }
    }

    .select-dropdown {
        @apply min-w-40;
    }

    .btn-search-mobile, .btn-filter-mobile {
        @apply block text-primary w-6;

        @screen md {
            @apply hidden;
        }

        &:active, &:focus {
            @apply outline-none;
        }
    }

    .search-desktop, .filter-desktop {
        @apply hidden;

        @screen md {
            @apply block;
        }
    }

    .search-container {
        @apply mr-0;
    }

    .filter-mobile {
        @apply max-w-xs;
    }

    .td-after {
        @apply flex flex-row;

        & > * {
            @apply mr-3;

            &:last-child {
                @apply mr-0;
            }
        }
    }
}
</style>
