<template>
    <div class="categories-update-modal">
        <ModalContainer :title="$t('categories.edit_category')" identifier="categories-update-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_loading_category || is_saving">
                <FormInputText v-model="$v.category.name.$model" identifier="name" :label="$t('categories.name')"
                               :disabled="is_loading_category || is_saving" :has-error="$v.category.name.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.category.name.required">
                            {{$t('validation.x_is_required',{x: $t('categories.name')})}}
                        </p>
                    </template>
                </FormInputText>

                <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                    {{$t('save')}}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
    import _ from 'lodash';
    import ModalContainer from "../modal/ModalContainer";
    import Form from "../form/Form";
    import FormInputText from "../form/FormInputText";
    import FormGroupTwo from "../form/FormGroupTwo";
    import FormInputSelect from "../form/FormInputSelect";
    import {required, email} from 'vuelidate/lib/validators'
    import Button from "../Button";
    import FormInputPassword from "@/components/form/FormInputPassword";

    export default {
        name: "CategoriesUpdateModal",
        components: {FormInputPassword, Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
        props: {
            category_id: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                category: {
                    name: null,
                },
                is_saving: false,
                is_loading_category: false,
            }
        },
        validations: {
            category: {
                name: {required},
            }
        },
        methods: {
            close(status) {
                this.$modal.hide('categories-update-modal', status);
            },
            save() {
                this.$v.category.$touch();
                if (this.$v.category.$anyError || this.is_saving)
                    return;

                this.is_saving = true;

                let payload = {
                    name: this.$v.category.name.$model
                }

                this.$axios.patch(`categories/${this.category_id}`, payload).then(({data}) => {
                    this.$notify({
                        text: this.$t('categories.success_updated'),
                        type: 'success',
                    });

                    this.is_saving = false;
                    this.close(true);
                }).catch(e => {
                    this.is_saving = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data.errors, this.$t('categories.error_update')),
                        type: 'error',
                    });
                });
            },
            async getCategory() {
                this.is_loading_category = true;

                await this.$axios.get(`categories/${this.category_id}`)
                    .then(({data}) => {
                        this.$v.category.name.$model = data.data.attributes.name;
                        this.is_loading_category = false;
                    })
                    .catch(e => {
                        this.is_loading_category = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('categories.error_retrieve')),
                            type: 'error',
                        });
                    });
            },
        },
        mounted() {
            this.getCategory();
        }
    }
</script>
