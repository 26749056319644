<template>
    <div class="confirm-modal">
        <ModalContainer :title="title" identifier="confirm-modal" :closable="true">
            <div class="sure-container">
                <p>{{message}}</p>

                <slot name="body"></slot>

                <div class="buttons">
                    <Button :className="`${cancelClass} wider`" :onclick="()=>close(false)">
                        {{cancelText}}
                    </Button>
                    <Button :className="`${confirmClass} wider`" :onclick="()=>close(true)">
                        {{confirmText}}
                    </Button>
                </div>
            </div>

            <div class="bottom-text" v-if="subtext">
                <p>{{subtext}}</p>
            </div>
        </ModalContainer>
    </div>
</template>

<script>
    import Button from "../Button";
    import ModalContainer from "./ModalContainer";

    export default {
        name: 'ConfirmModal',
        components: {ModalContainer, Button},
        props: {
            title: {
                type: String,
                required: false,
                default: 'Confirm',
            },
            message: {
                type: String,
                required: false,
                default: 'Are you sure?',
            },
            subtext: {
                type: String,
                required: false,
                default: null,
            },
            confirmText: {
                type: String,
                required: false,
                default: 'Yes',
            },
            cancelText: {
                type: String,
                required: false,
                default: 'No'
            },
            confirmClass: {
                type: String,
                required: false,
                default: '--negative'
            },
            cancelClass: {
                type: String,
                required: false,
                default: '--primary',
            }
        },
        methods: {
            close(status) {
                this.$modal.hide('confirm-modal', status);
            },
        }
    }
</script>

<style lang="scss" scoped>
    .confirm-modal {
        .sure-container {
            @apply flex flex-col items-center mt-6;

            p {
                @apply font-bold text-grey text-sm mb-6;
            }

            .buttons {
                @apply flex flex-row justify-center;

                button {
                    @apply mx-2 mt-0 px-8 py-3;
                }
            }
        }

        .bottom-text {
            p {
                @apply text-xs text-grey-dark italic text-center mt-8 leading-loose;
            }
        }
    }
</style>
