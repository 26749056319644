<template>
    <loading-screen v-if="is_loading"/>
    <div class="page-container" v-else-if="!is_loading">
        <main>
            <h1 v-if="document">{{ document.attributes.name }}</h1>

            <div class="document-container">
                <div class="document-info-container">
                    <div class="document-info">
                        <div class="info-text-container">
                            <div class="info-text">
                                <p class="info-title">{{ $t('documents.date_uploaded') }}</p>
                                <p>{{ $moment(document.attributes.created_at).format('DD/MM/YYYY HH:mm') }}</p>
                            </div>
                            <div class="info-text">
                                <p class="info-title">{{ $t('documents.last_updated') }}</p>
                                <p>{{ $moment(document.attributes.last_updated_at).format('DD/MM/YYYY - HH:mm') }}</p>
                            </div>
                        </div>
                        <div class="options-container">
                            <div class="filename-container">
                                <p>{{ document.attributes.attachment_name }}</p>
                            </div>
                            <div class="buttons-container">
                                <Button className="--secondary --outline --small --big-text"
                                        :onclick="() => downloadDocument()">
                                    <span class="icon-button"><font-awesome-icon :icon="['far', 'download']"/>{{$t('documents.download')}}</span>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </main>
    </div>
</template>

<script>
import _ from 'lodash';
import Headbar from "../../components/headbar/Headbar";
import Form from "../../components/form/Form";
import SectionHeader from "../../components/SectionHeader";
import Button from "../../components/Button";
import FormInputText from "../../components/form/FormInputText";
import FormInputSelect from "../../components/form/FormInputSelect";
import FormInputDateTime from "../../components/form/FormInputDateTime";
import FormGroupThree from "../../components/form/FormGroupThree";
import FormFileUpload from "../../components/form/FormFileUpload";
import {required, requiredIf} from 'vuelidate/lib/validators'
import FormGroupTwo from "../../components/form/FormGroupTwo";
import DocumentsUpdateModal from "../../components/documents/DocumentsUpdateModal";
import LoadingScreen from "../../components/LoadingScreen";

export default {
    name: "documents-update-page",
    components: {
        FormGroupTwo,
        FormInputText,
        FormInputSelect,
        FormInputDateTime,
        Button,
        SectionHeader,
        Form,
        Headbar,
        FormGroupThree,
        FormFileUpload,
        LoadingScreen
    },
    data: function () {
        return {
            document: null,
            is_loading: true,
        }
    },
    methods: {
        createdFn(row) {
            return this.$moment(row.attributes.created_at).format('DD/MM/YYYY - HH:mm');
        },
        downloadDocument() {
            this.$axios.get(`/public/documents/${this.$route.params.id}/download`, {
                responseType: 'arraybuffer',
                headers: {
                    'Accept': 'application/pdf',
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                },
                params: {
                    t: new Date().getTime()
                },
            }).then(({data}) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', this.document.attributes.attachment_name);
                document.body.appendChild(link);
                link.click();
            });
        },
        getDocument() {
            this.is_loading = true;

            this.$axios.get(`/public/documents/${this.$route.params.id}`)
                .then(({data}) => {
                    this.document = data.data;
                    this.is_loading = false;
                })
                .catch(e => {
                    this.is_loading = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('documents.error_retrieve_document')),
                        type: 'error',
                    });
                });
        },
    },
    computed: {
        todayDate() {
            let date = new Date();
            return this.$moment(date).format('YYYY-MM-DD HH:mm');
        }
    },
    mounted() {
        this.$route.params.id = this.$CryptoJS.AES.decrypt(atob(this.$route.params.id.toString()), 'download').toString(this.$CryptoJS.enc.Utf8);
        this.getDocument();
    },
    head() {
        return {
            title: {
                inner: this.$t('documents.document')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
main {
    padding: 0 !important;
}

h1 {
    @apply text-xl text-center mx-auto font-bold text-grey my-8;

    @screen md {
        @apply text-3xl mt-12;
    }

    @screen xl {
        @apply mt-16;
    }
}

.document-container {
    @apply flex flex-col border-primary;

    @screen md {
        @apply flex-row;
    }

    .document-details-container {
        @apply py-7 px-4 w-full;

        @screen md {
            @apply pl-8 pr-4 w-7/12;
        }

        form {
            @apply p-8;
        }
    }

    .document-info-container {
        @apply mx-auto;

        .document-info {
            @apply flex flex-col gap-y-8 px-8 py-6 bg-white text-grey rounded-lg;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

            .info-text-container {
                @apply flex flex-col justify-between gap-y-2 mx-auto text-center;

                @screen md {
                    @apply flex-row gap-y-0 gap-x-6 text-left mx-0;
                }

                .info-text {
                    @apply flex flex-col;

                    @screen md {
                        @apply flex-row gap-x-2;
                    }

                    .info-title {
                        @apply font-bold;
                    }
                }
            }

            .options-container {
                @apply flex flex-col gap-y-8 gap-x-4 w-full;

                .filename-container {
                    @apply mx-auto flex flex-row font-bold rounded border-2 border-primary text-left;

                    p {
                        @apply text-base font-normal mx-4 my-2 overflow-hidden;
                        text-overflow: ellipsis;
                    }
                }

                .buttons-container {
                    @apply flex flex-col;

                    @screen 2xl {
                        @apply flex-row;
                    }

                    & > * {
                        @apply mx-auto;
                    }
                }

                .icon-button {
                    @apply flex flex-row items-center gap-x-4
                }
            }
        }
    }
}
</style>