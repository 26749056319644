<template>
    <div class="countries-create-modal">
        <ModalContainer :title="$t('country_groups.add_country_group')" identifier="country-groups-update-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving || is_loading_country_group">
                <FormInputText v-model="$v.country_group.code.$model" identifier="code" :label="$t('country_groups.code')"
                              :disabled="is_saving || is_loading_country_group"
                               :has-error="$v.country_group.code.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.country_group.code.required">
                            {{$t('validation.x_is_required',{x: $t('country_groups.code')})}}
                        </p>
                    </template>
                </FormInputText>

                <FormInputText v-model="$v.country_group.name.$model" identifier="name" :label="$t('country_groups.name')"
                               :disabled="is_saving || is_loading_country_group"
                               :has-error="$v.country_group.name.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.country_group.name.required">
                            {{$t('validation.x_is_required',{x: $t('country_groups.name')})}}
                        </p>
                    </template>
                </FormInputText>

                <FormInputSelect v-model="$v.country_group.countries.$model" identifier="countries"
                                 :label="$t('country_groups.countries')" :options="country_options"
                                 :disabled="is_saving || is_loading_country_group || is_loading_countries"
                                 :has-error="$v.country_group.countries.$error" track-by="id"
                                 display-label="name" :multiple="true" class="select">
                    <template v-slot:errors>
                        <p v-if="!$v.country_group.countries.required">
                            {{ $t('validation.x_are_required', {x: $t('country_groups.countries')}) }}
                        </p>
                    </template>
                </FormInputSelect>

                <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                    {{$t('save')}}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormGroupTwo from "../form/FormGroupTwo";
import FormInputSelect from "../form/FormInputSelect";
import {required} from 'vuelidate/lib/validators'
import Button from "../Button";
import FormInputPassword from "@/components/form/FormInputPassword";

export default {
    name: "CountriesCreateModal",
    components: {FormInputPassword, Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
    props: {
        country_group_id: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            country_group: {
                code: null,
                name: null,
                countries: [],
            },
            country_options: [],
            is_saving: false,
            is_loading_countries: true,
            is_loading_country_group: true,
        }
    },
    validations: {
        country_group: {
            code: {required},
            name: {required},
            countries: {required},
        }
    },
    methods: {
        close(status) {
            this.$modal.hide('country-groups-update-modal', status);
        },
        save() {
            this.$v.country_group.$touch();
            if (this.$v.country_group.$anyError || this.is_saving)
                return;

            this.is_saving = true;

            let payload = {
                code: this.country_group.code,
                name: this.country_group.name,
            };

            this.$axios.patch(`countryGroups/${this.country_group_id}`, payload).then(async ({data}) => {
                await this.syncCountries(data.data.id);

                this.$notify({
                    text: this.$t('country_groups.success_updated'),
                    type: 'success',
                });

                this.is_saving = false;

                this.close(true);
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('country_groups.error_update')),
                    type: 'error',
                });
            });
        },
        async syncCountries(country_group_id) {
            await this.$axios.post(`countryGroups/${this.country_group_id}/countries/sync`, {country_ids: this.country_group.countries.map(c => c.id)});
        },
        async getCountries() {
            this.is_loading_countries = true;

            await this.$axios.get('countries/list')
                .then(async ({data}) => {
                    this.country_options = data.data.map(c => ({id: c.id, name: c.attributes.name}));

                    this.is_loading_countries = false;
                })
                .catch(e => {
                    this.is_loading_countries = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('countries.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        async getCountryGroup() {
            this.is_loading_country_group = true;

            await this.$axios.get(`countryGroups/${this.country_group_id}`)
                .then(({data}) => {
                    this.$v.country_group.code.$model = data.data.attributes.code;
                    this.$v.country_group.name.$model = data.data.attributes.name;
                    this.$v.country_group.countries.$model = this.country_options.filter(country => data.data.relationships.countries.data.find(c => c.id === country.id));

                    this.is_loading_country_group = false;
                })
                .catch(e => {
                    this.is_loading_country_group = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('categories.error_retrieve')),
                        type: 'error',
                    });
                });
        },
    },
    async mounted() {
        await this.getCountries();
        this.getCountryGroup();
    }
}
</script>
