<template>
    <div class="modules-update-modal">
        <ModalContainer :title="$t('modules.edit_module')" identifier="modules-update-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving || is_loading_module">
                <FormInputText v-model="$v.module.name.$model" identifier="name" :label="$t('modules.name')"
                               :disabled="is_saving || is_loading_module"
                               :has-error="$v.module.name.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.module.name.required">
                            {{ $t('validation.x_is_required', {x: $t('modules.name')}) }}
                        </p>
                    </template>
                </FormInputText>

                <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                    {{ $t('save') }}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormInputSelect from "../form/FormInputSelect";
import {required} from 'vuelidate/lib/validators'
import Button from "../Button";

export default {
    name: "ModulesUpdateModal",
    components: {Button, FormInputSelect, FormInputText, Form, ModalContainer},
    props: {
        module_id: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            module: {
                name: null,
            },
            is_saving: false,
            is_loading_module: true,
        }
    },
    validations: {
        module: {
            name: {required},
        }
    },
    methods: {
        close(status) {
            this.$modal.hide('modules-update-modal', status);
        },
        save() {
            this.$v.module.$touch();
            if (this.$v.module.$anyError || this.is_saving)
                return;

            this.is_saving = true;

            this.$axios.patch(`modules/${this.module_id}`, {name: this.module.name,})
                .then(({data}) => {
                    this.is_saving = false;
                    this.close(true);
                }).catch(e => {
                    this.is_saving = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data.errors, this.$t('modules.error_update')),
                        type: 'error',
                    });
                });
        },
        getModule() {
            this.is_loading_module = true;

            this.$axios.get(`modules/${this.module_id}`)
                .then(({data}) => {
                    this.module.name = data.data.attributes.name;

                    if (data.data.relationships.users.data.length)
                        this.module.users = this.user_options.filter(po => data.data.relationships.users.data.find(p => p.id === po.id));

                    this.is_loading_module = false;
                })
                .catch(e => {
                    this.is_loading_module = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('modules.error_retrieve')),
                        type: 'error',
                    });
                });
        },
    },
    async mounted() {
        this.getModule();
    }
}
</script>
