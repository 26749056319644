<template>
    <div class="app-container">
        <slot/>
    </div>
</template>

<script>
    export default {
        name: "NoLayout",
    }
</script>

<style lang="scss" scoped>
    .app-container {
        @apply min-h-screen w-full;
    }
</style>