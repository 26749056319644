<template>
    <div class="documents-create-modal">
        <ModalContainer :title="$t('documents.add_document')" identifier="documents-create-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                <FormGroupThree>
                    <FormInputText v-model="$v.document.name.$model" ref="name" identifier="name" :label="$t('documents.name')"
                                   :disabled="is_saving" :has-error="$v.document.name.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.document.name.required">
                                {{ $t('validation.x_is_required', {x: $t('documents.name')}) }}
                            </p>
                        </template>
                    </FormInputText>

                    <FormInputSelect v-model="$v.document.type.$model" identifier="type" :label="$t('documents.type')" :options="type_options"
                                     :disabled="is_saving || is_loading_types" :has-error="$v.document.type.$error" track-by="id"
                                     :display-custom-label="(row) => `${row.attributes.name}`">
                        <template v-slot:errors>
                            <p v-if="!$v.document.type.required">
                                {{$t('validation.x_is_required',{x: $t('documents.type')})}}
                            </p>
                        </template>
                    </FormInputSelect>

                    <FormInputSelect v-model="$v.document.category.$model" identifier="category" :label="$t('documents.category')" :options="category_options"
                                     :disabled="is_saving || is_loading_categories" :has-error="$v.document.category.$error" track-by="id"
                                     :display-custom-label="(row) => `${row.attributes.name}`">
                        <template v-slot:errors>
                            <p v-if="!$v.document.category.required">
                                {{$t('validation.x_is_required',{x: $t('documents.category')})}}
                            </p>
                        </template>
                    </FormInputSelect>

                    <FormInputText v-model="$v.document.language.$model" identifier="language" :label="$t('documents.language')"
                                   :disabled="is_saving" :has-error="$v.document.language.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.document.language.required">
                                {{ $t('validation.x_is_required', {x: $t('documents.language')}) }}
                            </p>
                        </template>
                    </FormInputText>

                    <FormInputSelect v-model="$v.document.module.$model" identifier="module" :label="$t('documents.module')" :options="module_options"
                                     :disabled="is_saving || is_loading_modules" :has-error="$v.document.module.$error" track-by="id"
                                     :display-custom-label="(row) => `${row.attributes.name}`">
                        <template v-slot:errors>
                            <p v-if="!$v.document.module.required">
                                {{$t('validation.x_is_required',{x: $t('documents.module')})}}
                            </p>
                        </template>
                    </FormInputSelect>

                    <FormInputText v-model="$v.document.version.$model" identifier="version" :label="$t('documents.version')"
                                   :disabled="is_saving" :has-error="$v.document.version.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.document.version.required">
                                {{ $t('validation.x_is_required', {x: $t('documents.version')}) }}
                            </p>
                        </template>
                    </FormInputText>

                    <FormInputSelect v-model="$v.document.trainers.$model" identifier="trainer" :label="$t('documents.trainers')" :options="job_role_options"
                                     :disabled="is_saving || is_loading_job_roles" :has-error="$v.document.trainers.$error" track-by="id"
                                     :display-custom-label="(row) => `${row.attributes.name}`" :multiple="true">
                        <template v-slot:errors>
                            <p v-if="!$v.document.trainers.required">
                                {{$t('validation.x_is_required',{x: $t('documents.trainers')})}}
                            </p>
                        </template>
                    </FormInputSelect>

                    <FormInputText v-model="$v.document.review_num.$model" identifier="review_num" :label="$t('documents.review_num')"
                                   :disabled="is_saving" :has-error="$v.document.review_num.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.document.review_num.required">
                                {{ $t('validation.x_is_required', {x: $t('documents.review_num')}) }}
                            </p>
                        </template>
                    </FormInputText>

                    <FormInputDateTime v-model="$v.document.next_review_date.$model" identifier="next_review_date"
                                       :label="$t('documents.next_review_date')" :disabled="is_saving" :only-date="true"
                                       :hasError="$v.document.next_review_date.$error" :min-date="todayDate" formatted="DD/MM/YYYY">
                        <template v-slot:errors>
                            <p v-if="!$v.document.next_review_date.required">
                                {{ $t('validation.x_is_required', {x: $t('documents.next_review_date')}) }}
                            </p>
                        </template>
                    </FormInputDateTime>

                    <FormInputText v-model="$v.document.revision_details.$model" identifier="revision_details" :label="$t('documents.revision_details')"
                                   :disabled="is_saving" :has-error="$v.document.revision_details.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.document.revision_details.required">
                                {{ $t('validation.x_is_required', {x: $t('documents.revision_details')}) }}
                            </p>
                        </template>
                    </FormInputText>

                    <FormInputText v-model="$v.document.reference.$model" identifier="reference" :label="$t('documents.reference')"
                                   :disabled="is_saving" :has-error="$v.document.reference.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.document.reference.required">
                                {{ $t('validation.x_is_required', {x: $t('documents.reference')}) }}
                            </p>
                        </template>
                    </FormInputText>

                    <FormInputText v-model="$v.document.description.$model" identifier="description" :label="$t('documents.description')"
                                   :disabled="is_saving" :has-error="$v.document.description.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.document.description.required">
                                {{ $t('validation.x_is_required', {x: $t('documents.description')}) }}
                            </p>
                        </template>
                    </FormInputText>

                    <FormInputSelect v-model="$v.document.sites.$model" identifier="sites" :label="$t('documents.sites')" :options="site_options"
                                     :disabled="is_saving || is_loading_sites" :has-error="$v.document.sites.$error" track-by="id"
                                     :display-custom-label="(row) => `${row.attributes.name}`" :multiple="true">
                        <template v-slot:errors>
                            <p v-if="!$v.document.sites.required">
                                {{$t('validation.x_is_required',{x: $t('documents.sites')})}}
                            </p>
                        </template>
                    </FormInputSelect>

                    <FormInputSelect v-model="$v.document.site_groups.$model" identifier="sites" :label="$t('documents.site_groups')" :options="site_group_options"
                                     :disabled="is_saving || is_loading_site_groups" :has-error="$v.document.site_groups.$error" track-by="id"
                                     :display-custom-label="(row) => `${row.attributes.name}`" :multiple="true">
                        <template v-slot:errors>
                            <p v-if="!$v.document.site_groups.required">
                                {{$t('validation.x_is_required',{x: $t('documents.site_groups')})}}
                            </p>
                        </template>
                    </FormInputSelect>

                    <FormInputSelect v-model="$v.document.departmental_owner.$model" identifier="departmental_owner" :label="$t('documents.departmental_owner')" :options="department_options"
                                     :disabled="is_saving || is_loading_departments" :has-error="$v.document.departmental_owner.$error" track-by="id"
                                     :display-custom-label="(row) => `${row.attributes.name}`">
                        <template v-slot:errors>
                            <p v-if="!$v.document.departmental_owner.required">
                                {{$t('validation.x_is_required',{x: $t('documents.departmental_owner')})}}
                            </p>
                        </template>
                    </FormInputSelect>

                    <FormInputSelect v-model="$v.document.departments.$model" identifier="departments" :label="$t('documents.departments')" :options="department_options"
                                     :disabled="is_saving || is_loading_departments" :has-error="$v.document.departments.$error" track-by="id"
                                     :display-custom-label="(row) => `${row.attributes.name}`" :multiple="true">
                        <template v-slot:errors>
                            <p v-if="!$v.document.departments.required">
                                {{$t('validation.x_is_required',{x: $t('documents.departments')})}}
                            </p>
                        </template>
                    </FormInputSelect>

                    <FormInputSelect v-model="$v.document.manager.$model" identifier="manager" :label="$t('documents.manager')" :options="job_role_options"
                                     :disabled="is_saving || is_loading_job_roles" :has-error="$v.document.manager.$error" track-by="id"
                                     :display-custom-label="(row) => `${row.attributes.name}`">
                        <template v-slot:errors>
                            <p v-if="!$v.document.manager.required">
                                {{$t('validation.x_is_required',{x: $t('documents.manager')})}}
                            </p>
                        </template>
                    </FormInputSelect>

                    <div class="checkbox-container input-group">
                        <input type="checkbox" id="line-manager-check" v-model="$v.document.is_line_manager.$model">
                        <label for="line-manager-check">{{$t('documents.activate_line_manager')}}</label>
                    </div>
                </FormGroupThree>

                <FormGroupThree class="training-material-spacing">
                    <div class="checkbox-container input-group ">
                                <input type="checkbox" id="is_training_material" v-model="$v.document.is_training_material.$model">
                                <label for="is_training_material">{{$t('documents.is_training_material')}}</label>
                            </div>
                </FormGroupThree>

                <FormFileUpload v-model="$v.document.file_upload.$model" :label="$t('documents.upload')" :has-error="$v.document.file_upload.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.document.file_upload.required">
                            {{$t('validation.x_is_required',{x: $t('documents.file_upload')})}}
                        </p>
                    </template>
                </FormFileUpload>

                <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                    {{ $t('save') }}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormInputSelect from "../form/FormInputSelect";
import FormGroupThree from "../form/FormGroupThree";
import FormInputDateTime from "../form/FormInputDateTime";
import FormFileUpload from "../form/FormFileUpload";
import {required, requiredIf} from 'vuelidate/lib/validators'
import Button from "../Button";
import FormInputCheck from "@/components/form/FormInputCheck.vue";

export default {
    name: "DocumentsUpdateModal",
    components: {
        FormInputCheck,
        Button, FormInputSelect, FormInputText, FormInputDateTime, FormGroupThree, FormFileUpload, Form, ModalContainer},
    data() {
        return {
            document: {
                name: null,
                type: null,
                category: null,
                language: null,
                module: null,
                version: null,
                trainers: [],
                manager: null,
                review_num: null,
                next_review_date: null,
                revision_details: null,
                reference: null,
                description: null,
                sites: [],
                site_groups: [],
                departmental_owner: null,
                departments: [],
                is_line_manager: false,
                file_upload: null,
                is_training_material:false,
            },
            type_options: [],
            category_options: [],
            module_options: [],
            job_role_options: [],
            manager_options: [],
            site_options: [],
            site_group_options: [],
            department_options: [],
            is_loading_types: false,
            is_loading_categories: false,
            is_loading_modules: false,
            is_loading_job_roles: false,
            is_loading_sites: false,
            is_loading_site_groups: false,
            is_loading_departmental_owners: false,
            is_loading_departments: false,
            is_saving: false,
        }
    },
    validations: {
        document: {
            name: {required},
            type: {required},
            category: {required},
            language: {required},
            module: {required},
            version: {required},
            trainers: {},
            manager: {},
            review_num: {},
            next_review_date: {},
            revision_details: {},
            reference: {},
            description: {},
            sites: {
                required: requiredIf(function () {
                    return !this.document.sites.length && !this.document.site_groups.length;
                }),
            },
            site_groups: {
                required: requiredIf(function () {
                    return this.$v.document.sites.length;
                }),
            },
            departmental_owner: {required},
            departments: {required},
            is_line_manager: {},
            file_upload: {required},
            is_training_material:{},
        }
    },
    computed: {
        todayDate() {
            let date = new Date();
            return this.$moment(date).format('YYYY-MM-DD HH:mm');
        }
    },
    methods: {
        close(status) {
            this.$modal.hide('documents-create-modal', status);
        },
        getTypes() {
            this.is_loading_types = true;

            this.$axios.get('types/list')
                .then(({data}) => {
                    this.type_options = data.data;
                    this.is_loading_types = false;
                })
                .catch(e => {
                    this.is_loading_types = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('types.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        getCategories() {
            this.is_loading_categories = true;

            this.$axios.get('categories/list')
                .then(({data}) => {
                    this.category_options = data.data;
                    this.is_loading_categories = false;
                })
                .catch(e => {
                    this.is_loading_categories = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('categories.error_retrieve')),
                        category: 'error',
                    });
                });
        },
        getModules() {
            this.is_loading_modules = true;

            this.$axios.get('modules/list')
                .then(({data}) => {
                    this.module_options = data.data;
                    this.is_loading_modules = false;
                })
                .catch(e => {
                    this.is_loading_modules = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('modules.error_retrieve')),
                        module: 'error',
                    });
                });
        },
        getJobRoles() {
            this.is_loading_job_roles = true;

            this.$axios.get('jobRoles/list')
                .then(({data}) => {
                    this.job_role_options = data.data;
                    this.is_loading_job_roles = false;
                })
                .catch(e => {
                    this.is_loading_job_roles = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('job_roles.error_retrieve')),
                        trainer: 'error',
                    });
                });
        },
        getSites() {
            this.is_loading_sites = true;

            this.$axios.get('sites/list')
                .then(({data}) => {
                    this.site_options = data.data;
                    this.is_loading_sites = false;
                })
                .catch(e => {
                    this.is_loading_sites = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('sites.error_retrieve')),
                        site: 'error',
                    });
                });
        },
        getSiteGroups() {
            this.is_loading_site_groups = true;

            this.$axios.get('siteGroups/list')
                .then(({data}) => {
                    this.site_group_options = data.data;
                    this.is_loading_site_groups = false;
                })
                .catch(e => {
                    this.is_loading_site_groups = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('site_groups.error_retrieve')),
                        site: 'error',
                    });
                });
        },
        getDepartments() {
            this.is_loading_departments = true;

            this.$axios.get('departments/list')
                .then(({data}) => {
                    this.department_options = data.data;
                    this.is_loading_departments = false;
                })
                .catch(e => {
                    this.is_loading_departments = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('departments.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        async uploadDocument() {
            let formData = new FormData();
            formData.append('name', this.document.name);
            formData.append('document', this.document.file_upload);
            formData.append('attachment_name', this.document.file_upload.name);

            return await this.$axios.post(`documents`, formData);
        },
        deleteDocument(document_id) {
            this.$axios.delete(`documents/${document_id}`);
        },
        async save() {
            this.$v.document.$touch();
            if (this.$v.document.$anyError || this.is_saving) return;

            this.is_saving = true;

            const document_uploaded = await this.uploadDocument();

            let payload = {
                type_id: this.document.type.id,
                category_id: this.document.category.id,
                language: this.document.language,
                module_id: this.document.module.id,
                version: this.document.version,
                sites: this.document.sites.map(site => site.id),
                site_groups: this.document.site_groups.map(site_group => site_group.id),
                department_id: this.document.departmental_owner.id,
                departments: this.document.departments.map(department => department.id),
                is_line_manager: this.document.is_line_manager,
                is_training_material: this.document.is_training_material
            }

            if(this.document.trainers.length)
                payload.job_roles = this.document.trainers.map(trainer => trainer.id);

            if(this.document.manager)
                payload.manager_job_role_id = this.document.manager.id;
            
            if(this.document.review_num)
                payload.review_count = this.document.review_num;
            
            if(this.document.next_review_date)
                payload.next_review_at = this.document.next_review_date;
            
            if(this.document.revision_details)
                payload.revision_details = this.document.revision_details;

            if(this.document.reference)
                payload.reference = this.document.reference;

            if(this.document.description)
                payload.description = this.document.description;

            if(document_uploaded.status === 201) {
                this.is_saving = true;

                this.$axios.patch(`documents/${document_uploaded.data.data.id}`, payload)
                    .then(({}) => {
                        this.is_saving = false;
                        this.close(true);
                    })
                    .catch(e => {
                        this.deleteDocument(document_uploaded.data.data.id);
                        this.is_saving = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('documents.error_upload')),
                            type: 'error',
                        });
                    });
            }
        },
    },
    mounted() {
        this.getTypes();
        this.getCategories();
        this.getModules();
        this.getJobRoles();
        this.getSites();
        this.getSiteGroups();
        this.getDepartments();

        this.$refs.name.$el.children[1].focus();
    }
}
</script>


<style lang="scss" scoped>
.documents-create-modal {
    .select {
        @apply mb-5;
    }

    .training-material-spacing {
        @apply mb-4;
    }

    .checkbox-container {
        @apply flex flex-row items-center my-auto;

        & > input {
            @apply mr-2 my-auto w-6 h-6 rounded-lg;
            accent-color: theme('colors.primary');
        }

        & > label {
            @apply text-grey font-bold my-auto;
        }
    }
}

</style>