<template>
    <nav class="menubar-container">
        <button class="btn-mobile-menu" @click="$store.commit('TOGGLE_MENU_EXPANDED')">
            <font-awesome-icon :icon="['fal', 'bars']"/>
        </button>

        <img src="../assets/medilink-white-logo.svg" class="logo">

        <div class="user-wrapper" v-if="$store.getters.user">
            <div class="user">
                <div class="avatar">
                    <user-icon iconType="fas" iconName="user" backgroundColor="#595959"></user-icon>
<!--                    <avatar background-color="#C82026" color="#474747" :size="30" :username="$store.getters.user.attributes.name"></avatar>-->
                </div>
                <p>{{$store.getters.user.attributes.name}}</p>
                <font-awesome-icon :icon="['far', 'angle-down']"/>
            </div>
            <nav>
                <ul class="menu">
                    <li>
                        <router-link :to="{name: 'profile'}">{{$t('nav.profile')}}</router-link>
                    </li>

                    <li v-if="$store.getters.hasRole('administrator')">
                        <router-link :to="{name: 'users-progress-index', params: {role: 'administrator'}}">{{$t('users.view_users_progress_as_administrator')}}</router-link>
                    </li>

                    <li v-if="$store.getters.hasRole('document_controller')">
                        <router-link :to="{name: 'users-progress-index', params: {role: 'document_controller'}}">{{$t('users.view_users_progress_as_document_controller')}}</router-link>
                    </li>

                    <li v-if="$store.getters.hasRole('trainer')">
                        <router-link :to="{name: 'users-progress-index', params: {role: 'trainer'}}">{{$t('users.view_users_progress_as_trainer')}}</router-link>
                    </li>

                    <li v-if="$store.getters.hasRole('manager')">
                        <router-link :to="{name: 'users-progress-index', params: {role: 'manager'}}">{{$t('users.view_users_progress_as_manager')}}</router-link>
                    </li>

                    <li v-if="$store.getters.hasRole('employee')" @click="pushEmployee"><p>{{$t('users.view_own_documents')}}</p></li>

                    <li>
                        <a @click="logout">{{$t('nav.logout')}}</a>
                    </li>
                </ul>
            </nav>
        </div>
    </nav>
</template>

<script>
    import Avatar from 'vue-avatar'
    import UserIcon from './UserIcon'

    export default {
        name: "Menubar",
        components: {UserIcon, Avatar},
        methods: {
            pushEmployee() {
                let encrypted_id = btoa(this.$CryptoJS.AES.encrypt(this.$store.getters.user.id.toString(), 'user').toString());
                this.$router.push({name: 'user-documents', params: {id: encrypted_id, role: 'employee'}});
            },
            async logout() {
                await this.$store.dispatch('logout');

                if(this.$msal.isAuthenticated()) {
                    await this.$msal.signOut();
                } else {
                    this.$router.push({name: 'login'});
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
.menubar-container {
    @apply flex flex-row w-full bg-primary h-14 text-white relative justify-between;
    z-index: 999999;
    grid-area: menubar;

    .btn-mobile-menu {
        @apply ml-6;

        @screen md {
            @apply hidden;
        }

        svg {
            @apply text-white text-xl;
        }

        &:active,&:focus{
            @apply outline-none;
        }
    }

    img.logo {
        @apply h-6 my-auto w-auto pl-12;
    }

    .user-wrapper {
        @apply py-4 cursor-pointer mr-2;

        .user {
            @apply mx-4 flex flex-row h-9 pb-4 items-center;

            .avatar {
                @apply mr-3;

                .icon-wrapper {
                    @apply bg-grey;
                }
            }

            p {
                @apply max-w-xs truncate text-sm hidden mr-3;

                @screen md {
                    @apply block;
                }
            }

            svg {
                @apply text-2xl;
            }
        }

        nav {
            @apply relative hidden bg-grey-darker mt-1;
            z-index: 999;

            .menu {
                @apply p-0 list-none;

                li {
                    a, p {
                        @apply flex text-white cursor-pointer py-4 px-6 text-sm;
                    }

                    &:hover {
                        background: linear-gradient(90deg, theme('colors.primary') 2%, #666666 2%);
                    }
                }
            }
        }

        &:hover, &:active, &:focus {
            nav {
                @apply block;
            }
        }
    }
}
</style>