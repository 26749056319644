<template>
    <div class="page-container">
        <Headbar>
            <template v-slot:left>
                <Button className="--primary --small" :onclick="toggleCreate" v-if="$store.getters.hasAnyPermission(['store job roles'])">
                    {{ $t('job_roles.add_job_role') }}
                </Button>
            </template>
            <template v-slot:right>
                <Search class="search-desktop" :placeholder="$t('job_roles.search_job_roles')" @search="search"/>
                <button class="btn-search-mobile btn-icon-only"
                        @click="headbarExpanded = headbarExpanded === 'search' ? null : 'search'">
                    <font-awesome-icon v-if="headbarExpanded === 'search'" :icon="['far', 'times']"/>
                    <font-awesome-icon v-else :icon="['far', 'search']"/>
                </button>
            </template>
            <template v-slot:expanded>
                <div class="headbar-expanded-container">
                    <Search v-if="headbarExpanded === 'search'" :placeholder="$t('job_roles.search_job_roles')" @search="search"/>
                </div>
            </template>
        </Headbar>
        <main>
            <vue-good-table
                ref="table"
                mode="remote"
                styleClass="vgt-table vgt-custom"
                :columns="columns"
                :rows="job_roles"
                :isLoading.sync="is_loading_job_roles"
                :search-options="{
                    enabled: false,
                }"
                :pagination-options="{
                    enabled: true,
                    mode: 'records',
                    dropdownAllowAll: false,
                    perPage: 10,
                    perPageDropdownEnabled: true,
                    rowsPerPageLabel: $t('x_per_page', {x: $t('job_roles.job_roles')}),
                }"
                :sort-options="{
                  enabled: true,
                  multipleColumns: true,
                }"
                :totalRows="totalRecords"
                @on-page-change="onPageChange"
                @on-per-page-change="onPerPageChange"
                @on-sort-change="onSortChange">
                <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field === 'role'">
                        <p style="text-transform: capitalize;">
                            {{ props.row.relationships.role.data[0].attributes.name }}</p>
                    </div>
                    <div v-else-if="props.column.field === 'after'" class="td-after">
                        <Button className="--secondary --outline --mini --big-text" v-if="$store.getters.hasPermission('update job roles')"
                                :onclick="()=>toggleUpdate(props.row)">
                            <font-awesome-icon :icon="['fal', 'pencil']"/>
                        </Button>
                        <Button
                            className="--secondary --outline --mini --big-text" v-if="$store.getters.hasPermission('destroy job roles')"
                            :onclick="()=>toggleDelete(props.row)">
                            <font-awesome-icon :icon="['fal', 'trash']"/>
                        </Button>
                    </div>
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
            </vue-good-table>
        </main>
    </div>
</template>

<script>
import Headbar from "../../components/headbar/Headbar";
import Search from "../../components/Search";
import Button from "../../components/Button";
import Select from "../../components/form/Select";
import ConfirmModal from "../../components/modal/ConfirmModal";
import JobRolesCreateModal from "../../components/job_roles/JobRolesCreateModal";
import JobRolesUpdateModal from "../../components/job_roles/JobRolesUpdateModal";

export default {
    name: "job-roles-index-page",
    components: {ConfirmModal, Select, Button, Search, Headbar},
    data: function () {
        const columns = [
            {
                label: this.$t('job_roles.id'),
                field: 'id',
                sortable: false,
            },
            {
                label: this.$t('job_roles.name'),
                field: 'attributes.name',
                sortable: false,
            },
            {
                label: this.$t('job_roles.sites'),
                field: row => row.relationships.sites.data ? row.relationships.sites.data.map(s => s.attributes.name).join(', ') : '',
                sortable: false,
            },
            {
                label: this.$t('job_roles.site_groups'),
                field: row => row.relationships.siteGroups.data ? row.relationships.siteGroups.data.map(sg => sg.attributes.name).join(', ') : '',
                sortable: false,
            },
            {
                label: this.$t('job_roles.departments'),
                field: row => row.relationships.departments.data ? row.relationships.departments.data.map(d => d.attributes.name).join(', ') : '',
                sortable: false,
            },
        ];

        if (this.$store.getters.hasAnyPermission(['update job roles', 'destroy job roles']))
            columns.push({
                label: this.$t('job_roles.actions'),
                field: 'after',
                tdClass: 'td-after',
                sortable: false
            });

        return {
            columns: columns,
            job_roles: [],
            is_loading_job_roles: false,
            totalRecords: null,
            serverParams: {sorting: []},
            searchTerm: null,
            is_deleting: false,
            headbarExpanded: false
        }
    },
    methods: {
        toggleCreate() {
            this.$modal.show(
                JobRolesCreateModal, {},
                {
                    name: 'job-roles-create-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    width: 600,
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true)
                            this.getJobRoles();
                    }
                }
            );
        },
        toggleUpdate(job_role) {
            this.$modal.show(
                JobRolesUpdateModal,
                {
                    job_role_id: job_role.id,
                },
                {
                    name: 'job-roles-update-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    width: 600,
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true)
                            this.getJobRoles();
                    }
                }
            );
        },
        toggleDelete(job_role) {
            this.$modal.show(
                ConfirmModal, {
                    title: this.$t('job_roles.delete_job_role'),
                    message: this.$t('job_roles.prompt_delete'),
                    confirmText: this.$t('delete'),
                    cancelText: this.$t('cancel'),
                    confirmClass: '--primary',
                    cancelClass: '--secondary --outline'
                },
                {
                    name: 'confirm-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    width: 450,
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true) {
                            this.is_deleting = true;
                            this.$axios.delete(`jobRoles/${job_role.id}`)
                                .then(({data}) => {
                                    this.is_deleting = false;
                                    this.getJobRoles();
                                })
                                .catch(e => {
                                    this.is_deleting = false;

                                    this.$notify({
                                        title: this.$t('error'),
                                        text: this.$larerror(e.response.data, this.$t('job_roles.error_delete')),
                                        type: 'error',
                                    });
                                });
                        }
                    }
                }
            );
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.getJobRoles();
        },
        onPerPageChange(params) {
            this.updateParams({per_page: params.currentPerPage});
            this.getJobRoles();
        },
        onSortChange(params) {
            const sorts = [];

            if (!params || !params.length) {
                this.updateParams({sorting: []});
                return this.getJobRoles();
            }

            params.forEach(p => {
                if (!p.type || p.type === 'none')
                    return;

                let sort_by = null;
                let sort_order = p.type;
                if (p.field === 'attributes.name')
                    sort_by = 'name';
                else if (p.field === 'attributes.email')
                    sort_by = 'email';
                else if (p.field === 'role')
                    sort_by = 'role';
                else
                    sort_by = p.field.split('.')[1];

                sorts.push({sort_order, sort_by})
            });

            this.updateParams({sorting: sorts});
            this.getJobRoles();
        },
        removeParam(param) {
            this.$delete(this.serverParams, param);
        },
        search(searchTerm) {
            this.searchTerm = searchTerm;

            if (searchTerm && searchTerm.length)
                this.updateParams({term: searchTerm, page: 1});
            else this.removeParam('term');

            this.getJobRoles();
        },
        getJobRoles() {
            this.is_loading_job_roles = true;

            const encodedSorting = this.serverParams.sorting.map(f => btoa(JSON.stringify(f)));

            this.$axios.get('jobRoles', {params: {...this.serverParams, sorting: encodedSorting}})
                .then(({data}) => {
                    this.job_roles = data.data;
                    
                    this.$refs.table.changePage(data.meta.current_page);
                    this.totalRecords = data.meta.total;
                    this.is_loading_job_roles = false;
                })
                .catch(e => {
                    this.is_loading_job_roles = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('job_roles.error_retrieve')),
                        type: 'error',
                    });
                });
        }
    },
    mounted() {
        this.getJobRoles();
    },
    head() {
        return {
            title: {
                inner: this.$t('nav.job_roles')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
.page-container {
    .headbar-expanded-container {
        @apply mt-4 flex flex-col gap-y-4 items-center;

        @screen md {
            @apply hidden;
        }
    }

    .select-dropdown {
        @apply min-w-40;
    }

    .btn-search-mobile, .btn-filter-mobile {
        @apply block text-primary w-6;

        @screen md {
            @apply hidden;
        }

        &:active, &:focus {
            @apply outline-none;
        }
    }

    .search-desktop, .filter-desktop {
        @apply hidden;

        @screen md {
            @apply block;
        }
    }

    .search-container {
        @apply mr-0;
    }

    .filter-mobile {
        @apply max-w-xs;
    }

    .td-after {
        @apply flex flex-row;

        & > * {
            @apply mr-3;

            &:last-child {
                @apply mr-0;
            }
        }
    }
}
</style>
