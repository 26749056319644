<template>
    <div class="form-errors">
        <slot/>
    </div>
</template>

<script>
    export default {
        name: "FormErrors"
    }
</script>

<style lang="scss" scoped>
    .form-errors {
        @apply mt-1;

        p {
            @apply text-negative italic text-xs;
        }
    }
</style>