<template>
    <div class="countries-create-modal">
        <ModalContainer :title="$t('country_groups.add_country_group')" identifier="country-groups-create-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                <FormInputText v-model="$v.country_group.code.$model" ref="code" identifier="code" :label="$t('country_groups.code')"
                               :disabled="is_saving" :has-error="$v.country_group.code.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.country_group.code.required">
                            {{$t('validation.x_is_required',{x: $t('country_groups.code')})}}
                        </p>
                    </template>
                </FormInputText>

                <FormInputText v-model="$v.country_group.name.$model" identifier="name" :label="$t('country_groups.name')"
                               :disabled="is_saving" :has-error="$v.country_group.name.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.country_group.name.required">
                            {{$t('validation.x_is_required',{x: $t('country_groups.name')})}}
                        </p>
                    </template>
                </FormInputText>

                <FormInputSelect v-model="$v.country_group.countries.$model" identifier="countries"
                                 :label="$t('country_groups.countries')" :options="country_options"
                                 :disabled="is_saving || is_loading_countries"
                                 :has-error="$v.country_group.countries.$error" track-by="id"
                                 display-label="name" :multiple="true" class="select">
                    <template v-slot:errors>
                        <p v-if="!$v.country_group.countries.required">
                            {{ $t('validation.x_are_required', {x: $t('country_groups.countries')}) }}
                        </p>
                    </template>
                </FormInputSelect>

                <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                    {{$t('save')}}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormGroupTwo from "../form/FormGroupTwo";
import FormInputSelect from "../form/FormInputSelect";
import {required, email} from 'vuelidate/lib/validators'
import Button from "../Button";
import FormInputPassword from "@/components/form/FormInputPassword";

export default {
    name: "CountriesCreateModal",
    components: {FormInputPassword, Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
    data() {
        return {
            country_group: {
                code: null,
                name: null,
                countries: [],
            },
            country_options: [],
            is_saving: false,
            is_loading_countries: false,
        }
    },
    validations: {
        country_group: {
            code: {required},
            name: {required},
            countries: {required},
        }
    },
    methods: {
        close(status) {
            this.$modal.hide('country-groups-create-modal', status);
        },
        save() {
            this.$v.country_group.$touch();
            if (this.$v.country_group.$anyError || this.is_saving)
                return;

            this.is_saving = true;

            let payload = Object.assign({}, this.$v.country_group.$model);
            delete payload.countries;

            this.$axios.post(`countryGroups`, payload).then(async ({data}) => {
                await this.syncCountries(data.data.id);

                this.$notify({
                    text: this.$t('country_groups.success_created'),
                    type: 'success',
                });

                this.is_saving = false;
                this.close(true);
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('country_groups.error_create')),
                    type: 'error',
                });
            });
        },
        async syncCountries(country_group_id) {
            await this.$axios.post(`countryGroups/${country_group_id}/countries/sync`, {country_ids: this.country_group.countries.map(c => c.id)});
        },
        getCountries() {
            this.is_loading_countries = true;

            this.$axios.get('countries/list')
                .then(({data}) => {
                    this.country_options = data.data.map(p => ({id: p.id, name: p.attributes.name}));
                    this.is_loading_countries = false;
                })
                .catch(e => {
                    this.is_loading_countries = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('countries.error_retrieve')),
                        type: 'error',
                    });
                });
        }
    },
    mounted() {
        this.getCountries();

        this.$refs.code.$el.children[1].focus();
    }
}
</script>
