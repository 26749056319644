<template>
    <div class="departments-create-modal">
        <ModalContainer :title="$t('departments.add_department')" identifier="departments-create-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                <FormInputText v-model="$v.department.code.$model" ref="code" identifier="code" :label="$t('departments.code')"
                               :disabled="is_saving" :has-error="$v.department.code.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.department.code.required">
                            {{$t('validation.x_is_required',{x: $t('departments.code')})}}
                        </p>
                    </template>
                </FormInputText>

                <FormInputText v-model="$v.department.name.$model" identifier="name" :label="$t('departments.name')"
                               :disabled="is_saving" :has-error="$v.department.name.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.department.name.required">
                            {{$t('validation.x_is_required',{x: $t('departments.name')})}}
                        </p>
                    </template>
                </FormInputText>

                <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                    {{$t('save')}}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormGroupTwo from "../form/FormGroupTwo";
import FormInputSelect from "../form/FormInputSelect";
import {required, email} from 'vuelidate/lib/validators'
import Button from "../Button";
import FormInputPassword from "@/components/form/FormInputPassword";

export default {
    name: "DepartmentsCreateModal",
    components: {FormInputPassword, Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
    data() {
        return {
            department: {
                code: null,
                name: null,
            },
            is_saving: false,
        }
    },
    validations: {
        department: {
            code: {required},
            name: {required},
        }
    },
    methods: {
        close(status) {
            this.$modal.hide('departments-create-modal', status);
        },
        save() {
            this.$v.department.$touch();
            if (this.$v.department.$anyError || this.is_saving)
                return;

            this.is_saving = true;

            const payload = Object.assign({}, this.$v.department.$model);

            this.$axios.post(`departments`, payload).then(({data}) => {
                this.$notify({
                    text: this.$t('departments.success_created'),
                    type: 'success',
                });

                this.is_saving = false;
                this.close(true);
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('departments.error_create')),
                    type: 'error',
                });
            });
        }
    },
    mounted() {
        this.$refs.code.$el.children[1].focus();
    }
}
</script>
