<template>
    <div class="mobile-menu-container">
        <router-link :to="{name: 'documents-index'}" class="nav-item" @click.native="$store.commit('TOGGLE_MENU_EXPANDED', false)" v-if="$store.getters.hasPermission('read documents')">
            <div class="icon-container">
                <font-awesome-icon :icon="['far', 'file-alt']"/>
            </div>
            <p>{{$t('nav.documents')}}</p>
        </router-link>

        <router-link :to="{name: 'departments-index'}" class="nav-item" @click.native="$store.commit('TOGGLE_MENU_EXPANDED', false)"
                     v-if="$store.getters.hasAnyPermission(['read departments', 'read department groups'])">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'users']"/>
            </div>
            <p>{{$t('nav.departments')}}</p>
        </router-link>

        <router-link :to="{name: 'sites-index'}" class="nav-item" @click.native="$store.commit('TOGGLE_MENU_EXPANDED', false)"
                     v-if="$store.getters.hasAnyPermission(['read sites', 'read site groups'])">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'building']"/>
            </div>
            <p>{{$t('nav.sites')}}</p>
        </router-link>

        <router-link :to="{name: 'countries-index'}" class="nav-item" @click.native="$store.commit('TOGGLE_MENU_EXPANDED', false)"
                     v-if="$store.getters.hasAnyPermission(['read countries', 'read country groups'])">
            <div class="icon-container">
                <font-awesome-icon :icon="['fas', 'globe-europe']"/>
            </div>
            <p>{{$t('nav.countries')}}</p>
        </router-link>

        <router-link :to="{name: 'categories-index'}" class="nav-item" @click.native="$store.commit('TOGGLE_MENU_EXPANDED', false)"
                     v-if="$store.getters.hasAnyPermission(['read categories', 'read types'])">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'icons']"/>
            </div>
            <p>{{$t('nav.documents_categories_types')}}</p>
        </router-link>

        <router-link :to="{name: 'job-roles-index'}" class="nav-item" @click.native="$store.commit('TOGGLE_MENU_EXPANDED', false)"
                     v-if="$store.getters.hasAnyPermission(['read job roles'])">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'briefcase']"/>
            </div>
            <p>{{$t('nav.job_roles')}}</p>
        </router-link>

        <router-link :to="{name: 'modules-index'}" class="nav-item" @click.native="$store.commit('TOGGLE_MENU_EXPANDED', false)"
                     v-if="$store.getters.hasAnyPermission(['read modules'])">
            <div class="icon-container">
                <font-awesome-icon :icon="['far', 'file-certificate']"/>
            </div>
            <p>{{$t('nav.modules')}}</p>
        </router-link>

        <router-link :to="{name: 'roles-index'}" class="nav-item" @click.native="$store.commit('TOGGLE_MENU_EXPANDED', false)"
                     v-if="$store.getters.hasPermission('read permissions')">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'key']"/>
            </div>
            <p>{{$t('nav.roles_permissions')}}</p>
        </router-link>

        <router-link :to="{name: 'users-index'}" class="nav-item" @click.native="$store.commit('TOGGLE_MENU_EXPANDED', false)"
                     v-if="$store.getters.hasPermission('read users')">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'user-friends']"/>
            </div>
            <p>{{$t('nav.users')}}</p>
        </router-link>
    </div>
</template>

<script>
export default {
    name: "MobileMenu"
}
</script>

<style lang="scss" scoped>
.mobile-menu-container {
    @apply flex-1 grid grid-cols-2 content-start gap-y-12 gap-x-8 h-auto p-8 bg-white;

    @screen sm {
        @apply grid-cols-3;
    }

    @screen md {
        @apply hidden;
    }

    .nav-item {
        @apply flex flex-col items-center;

        .icon-container {
            @apply p-2 rounded-lg border-2 border-grey-light flex flex-col items-center justify-center;
            height: 40px;
            width: 40px;

            svg {
                @apply text-primary w-auto;
                height: 18px;
            }
        }

        p {
            @apply text-grey text-sm font-bold mt-4 text-center;
            max-width: 8rem;
        }

        &:hover, &:focus {
            .icon-container {
                @apply border-primary;
            }
        }

        &.router-link-active {
            .icon-container {
                @apply bg-primary border-primary;

                svg {
                    @apply text-white;
                }
            }
        }
    }
}
</style>