<template>
    <div class="sign-document-modal">
        <ModalContainer :title="$t('documents.sign_document')" identifier="sign-document-modal" :closable="true">
            <Form class="form" @submit="sign" :disabled="!is_checked || is_saving">

                <div class="checkbox-container">
                    <input type="checkbox" id="check" v-model="is_checked"/>
                    <label for="check">{{$t('documents.tick_box_sign')}}</label>
                </div>

                <Button type="submit" className="--primary --small" :disabled="!is_checked" :class="{spinner: is_saving}">
                    {{$t('sign')}}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputCheck from "../form/FormInputCheck";
import {required, requiredIf} from 'vuelidate/lib/validators'
import Button from "../Button";

export default {
    name: "SignDocumentModal",
    components: {Button, Form, FormInputCheck, ModalContainer},
    props: {
        document_id: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            is_checked: false,
            is_saving: false,
        }
    },
    methods: {
        close(status) {
            this.$modal.hide('sign-document-modal', status);
        },
        async sign() {
            if(!this.is_checked) return;

            this.is_saving = true;

            let payload = {};

            if(this.$store.getters.hasRole('administrator') && this.$route.params.id.toString() === this.$store.getters.user.id.toString() && (this.$route.params.role === 'administrator' || this.$route.params.role === 'employee')) payload.role = 'employee';
            else if(this.$route.params.role) payload.role = this.$route.params.role;

            if(this.$route.params.role && this.$route.params.role === 'trainer') {
                payload = {...payload, user_id: this.$route.params.id};
            }

            this.$axios.post(`documents/${this.document_id}/sign`, payload)
                .then(({data}) => {
                    this.is_saving = false;
                    this.close(true);

                    this.$notify({
                        title: this.$t('success'),
                        text: this.$t('documents.document_signed'),
                        type: 'success',
                    });
                })
                .catch(e => {
                    this.is_saving = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('documents.error_sign_document')),
                        type: 'error',
                    });
                });
        },
    },
}
</script>

<style lang="scss" scoped>
    .checkbox-container {
        @apply flex flex-row items-center my-6 mx-auto;

        & > input {
            @apply mr-4 my-auto w-6 h-6 rounded-lg;
            accent-color: theme('colors.primary');
        }

        & > label {
            @apply text-grey font-bold my-auto;
        }
    }
</style>