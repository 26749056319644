<template>
  <div class="page-container">
    <Headbar>
      <template v-slot:left>
        <Button className="--primary --small" :onclick="toggleCreate"
                v-if="$store.getters.hasAnyPermission(['store documents'])">
          {{ $t('documents.upload') }}
        </Button>
      </template>
      <template v-slot:right>
        <Search class="search-desktop" :placeholder="$t('documents.search_documents')" @search="search" />
        <button class="btn-search-mobile btn-icon-only"
                @click="headbarExpanded = headbarExpanded === 'search' ? null : 'search'">
          <font-awesome-icon v-if="headbarExpanded === 'search'" :icon="['far', 'times']" />
          <font-awesome-icon v-else :icon="['far', 'search']" />
        </button>
      </template>
      <template v-slot:expanded>
        <div class="headbar-expanded-container">
          <Search v-if="headbarExpanded === 'search'" :placeholder="$t('documents.search_documents')"
                  @search="search" />
        </div>
      </template>
    </Headbar>
    <main>
      <div class="options-container">
        <div class="export-container-tablet">
          <p class="export-as-text">{{ $t('export_as') }}</p>
          <FormInputSelect v-model="export_option_selected" :placeholder="$t('documents.export_options')"
                           identifier="type" :options="export_options" className="filter" :allow-empty="false" track-by="id"
                           display-label="name">
          </FormInputSelect>
          <Button className="--grey --outline --match-select" :onclick="generateDownload"
                  :disabled="is_printing"
                  :class="{ spinner: is_printing }">
            <font-awesome-icon :icon="['far', 'download']" />
          </Button>
        </div>
        <div class="filters-container" style="width: 1300px">
          <div class="filter-by-mobile" @click="filterByExpanded = !filterByExpanded">
            <p class="filter-by-text">{{ $t('filter_by') }}</p>
            <button class="btn-filter-by-mobile">
              <font-awesome-icon v-if="filterByExpanded === true" :icon="['far', 'chevron-up']" />
              <font-awesome-icon v-else :icon="['far', 'chevron-down']" />
            </button>
          </div>
          <p class="filter-by-text">{{ $t('filter_by') }}</p>
          <div class="filters-wrapper" v-if="filterByExpanded === true || windowWidth >= 768">
            <FormInputSelect v-model="filter_type" className="filter" @input="onFilterUpdated" identifier="type"
                             :options="type_options" :placeholder="$t('documents.type')" :disabled="is_loading_types"
                             track-by="id" :display-custom-label="(row) => `${row.attributes.name}`">
            </FormInputSelect>
            <FormInputSelect v-model="filter_category" className="filter" @input="onFilterUpdated"
                             identifier="category" :options="category_options" :placeholder="$t('documents.category')"
                             :disabled="is_loading_categories" track-by="id"
                             :display-custom-label="(row) => `${row.attributes.name}`">
            </FormInputSelect>
            <FormInputSelect v-model="filter_module" className="filter" @input="onFilterUpdated"
                             identifier="module" :options="module_options" :placeholder="$t('documents.module')"
                             :disabled="is_loading_modules" track-by="id"
                             :display-custom-label="(row) => `${row.attributes.name}`">
            </FormInputSelect>
            <FormInputSelect v-model="filter_department" class="departmental-owner" className="filter"
                             identifier="department_owner" :options="department_options" @input="onFilterUpdated"
                             :placeholder="$t('documents.departmental_owner')" :disabled="is_loading_departments"
                             track-by="id" :display-custom-label="(row) => `${row.attributes.name}`">
            </FormInputSelect>
            <FormInputSelect v-model="filter_trainers" className="filter" identifier="trainer"
                             :options="trainer_options" :placeholder="$t('documents.trainer')"
                             @input="onTrainersFilterUpdated" :disabled="is_loading_trainers" track-by="id" :multiple="true"
                             :display-custom-label="(row) => `${row.attributes.name}`">
            </FormInputSelect>
            <FormInputSelect v-model="filter_archived" className="filter" identifier="archived"
                             :options="archived_options" :allow-empty="false" :placeholder="$t('documents.archived')"
                             @input="onFilterUpdated" track-by="id" :display-custom-label="(row) => `${row.name}`">
            </FormInputSelect>
            <Button className="--primary --small" :onclick="clearFilters">
              {{ $t('clear_filters') }}
            </Button>
          </div>
        </div>

        <div class="export-container-normal">
          <p class="export-as-text">{{ $t('export_as') }}</p>
          <FormInputSelect v-model="export_option_selected" :placeholder="$t('documents.export_options')"
                           identifier="type" :options="export_options" className="filter" :allow-empty="false" track-by="id"
                           display-label="name">
          </FormInputSelect>
          <Button className="--grey --outline --match-select" :onclick="generateDownload"
                  :disabled="is_printing"
                  :class="{ spinner: is_printing }">
            <font-awesome-icon :icon="['far', 'download']" />
          </Button>
        </div>
      </div>

      <vue-good-table ref="table" mode="remote" styleClass="vgt-table vgt-custom vgt-documents" :columns="columns"
                      :rows="documents" :isLoading.sync="is_loading_documents" :search-options="{
                    enabled: false,
                }" :pagination-options="{
    enabled: true,
    mode: 'records',
    dropdownAllowAll: false,
    perPage: 15,
    perPageDropdownEnabled: true,
    rowsPerPageLabel: $t('x_per_page', { x: $t('documents.documents') }),
}" :sort-options="{
    enabled: true,
    multipleColumns: true,
}" :totalRows="totalRecords" @on-page-change="onPageChange" @on-per-page-change="onPerPageChange"
                      @on-sort-change="onSortChange">
        <template slot="table-row" slot-scope="props">
          <div v-if="props.column.field === 'role'">
            <p style="text-transform: capitalize;">
              {{ props.row.relationships.role.data[0].attributes.name }}</p>
          </div>


          <div v-else-if="props.column.field === 'after'" class="td-after">
            <Button className="--secondary --outline --mini --big-text"
                    v-if="$store.getters.hasPermission('update documents')" :onclick="() => toggleUpdate(props.row)"
                    :content="$t('update')" v-tippy="{ placement: 'top', arrow: true }">
              <font-awesome-icon :icon="['far', 'retweet']" />
            </Button>

            <Button className="--secondary --outline --mini --big-text" :onclick="() => copyLink(props.row.id)"
                    :content="$t('documents.copy_link')" v-tippy="{ placement: 'top', arrow: true }">
              <font-awesome-icon :icon="['far', 'link']" />
            </Button>

            <div v-if="!documentPreviewSupported(props.row.attributes.attachment_name)">
              <Button className="--secondary --outline --mini --big-text"
                      :onclick="() => downloadDocument(props.row.id, props.row.attributes.attachment_name)"
                      :content="$t('download')" v-tippy="{ placement: 'top', arrow: true }"
                      :disabled="is_downloading">
                <font-awesome-icon :icon="['far', 'download']" />
              </Button>
            </div>

            <Button v-if="documentPreviewSupported(props.row.attributes.attachment_name)"
                    className="--secondary --outline --mini --big-text" :onclick="() => previewDocument(props.row)"
                    :disabled="!documentPreviewSupported(props.row.attributes.attachment_name)"
                    :content="$t('documents.preview')" v-tippy="{ placement: 'top', arrow: true }">
              <font-awesome-icon :icon="['far', 'external-link']" />
            </Button>

            <Button className="--secondary --outline --mini --big-text"
                    :onclick="() => visitDocument(props.row.id)" :content="$t('edit')"
                    v-tippy="{ placement: 'top', arrow: true }">
              <font-awesome-icon :icon="['far', 'pencil']" />
            </Button>

            <Button className="--secondary --outline --mini --big-text"
                    v-if="$store.getters.hasPermission('destroy documents')"
                    :onclick="() => toggleArchive(props.row)"
                    :content="filter_archived.value === 'not_archived' ? $t('archive') : $t('unarchive')"
                    v-tippy="{ placement: 'top', arrow: true }">
              <font-awesome-icon
                :icon="props.row.attributes.archived_at ? ['far', 'inbox-out'] : ['far', 'archive']" />
            </Button>
          </div>
          <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>
        </template>
      </vue-good-table>

      <download-csv v-if="csv_data.length" v-show="false" ref="csv-generator" :data="csv_data"></download-csv>

      <vue-html2pdf :enable-download="true" filename="Documents PDF Report" :pdf-quality="2" :manual-pagination="true"
                    pdf-format="a4" pdf-orientation="landscape" pdf-content-width="1120px"
                    @beforeDownload="beforePDFDownload($event)" @hasDownloaded="hasPDFDownloaded($event)" ref="html2Pdf">
        <section slot="pdf-content" class="pdf-content">
          <h1>{{ $t('documents.all_documents') }}</h1>

          <vue-good-table v-for="(page, index) in printing_pages" :key="index" class="documents-table"
                          styleClass="vgt-table vgt-printing-table vgt-custom" :columns="printing_columns" :rows="page"
                          :totalRows="totalRecords" :pagination-options="{ enabled: true }">
            <template slot="pagination-bottom" slot-scope="props">
              <div class="html2pdf__page-break" />
            </template>
          </vue-good-table>
        </section>
      </vue-html2pdf>
    </main>
  </div>
</template>

<script>
import Headbar from "../../components/headbar/Headbar";
import Search from "../../components/Search";
import Button from "../../components/Button";
import Select from "../../components/form/Select";
import FormInputSelect from "../../components/form/FormInputSelect";
import ConfirmModal from "../../components/modal/ConfirmModal";
import DocumentsCreateModal from "../../components/documents/DocumentsCreateModal";
import DocumentsUpdateModal from "../../components/documents/DocumentsUpdateModal";

export default {
  name: "documents-index-page",
  components: { ConfirmModal, Select, Button, Search, Headbar, FormInputSelect },
  data: function () {
    const columns = [
      {
        label: this.$t('documents.id'),
        field: 'id',
        sortable: false,
      },
      {
        label: this.$t('documents.name'),
        field: 'attributes.name',
        sortable: false,
      },
      {
        label: this.$t('documents.type'),
        field: 'relationships.type.data.attributes.name',
        sortable: false,
      },
      {
        label: this.$t('documents.category'),
        field: 'relationships.category.data.attributes.name',
        sortable: false,
      },
      {
        label: this.$t('documents.language'),
        field: 'attributes.language',
        sortable: false,
      },
      {
        label: this.$t('documents.module'),
        field: 'relationships.module.data.attributes.name',
        sortable: false,
      },
      {
        label: this.$t('documents.version'),
        field: 'attributes.version',
        sortable: false,
      },
      {
        label: this.$t('documents.revision_details'),
        field: row => row.attributes.revision_details ? row.attributes.revision_details : '',
        sortable: false,
      },
      {
        label: this.$t('documents.next_review'),
        field: row => row.attributes.next_review_at !== null ? this.$moment(row.attributes.next_review_at).format('DD/MM/YYYY') : '',
        sortable: false,
      },
      {
        label: this.$t('documents.trainer'),
        field: row => row.relationships.job_roles.data ? row.relationships.job_roles.data.map(jr => jr.attributes.name.charAt(0).toUpperCase() + jr.attributes.name.slice(1)).join(', ') : '',
        sortable: false,
      },
      {
        label: this.$t('documents.description'),
        field: row => row.attributes.description ? row.attributes.description : '',
        sortable: false,
      },
    ];

    const printing_columns = [
      {
        label: this.$t('documents.name'),
        field: 'attributes.name',
        sortable: false,
      },
      {
        label: this.$t('documents.type'),
        field: 'relationships.type.data.attributes.name',
        sortable: false,
      },
      {
        label: this.$t('documents.category'),
        field: 'relationships.category.data.attributes.name',
        sortable: false,
      },
      {
        label: this.$t('documents.language'),
        field: 'attributes.language',
        sortable: false,
      },
      {
        label: this.$t('documents.module'),
        field: 'relationships.module.data.attributes.name',
        sortable: false,
      },
      {
        label: this.$t('documents.version'),
        field: 'attributes.version',
        sortable: false,
      },
      {
        label: this.$t('documents.rev_details'),
        field: row => row.attributes.revision_details ? row.attributes.revision_details : '',
        sortable: false,
      },
      {
        label: this.$t('documents.next_review'),
        field: row => row.attributes.next_review_at ? this.$moment(row.attributes.next_review_at).format('DD/MM/YYYY') : '',
        sortable: false,
      },
      {
        label: this.$t('documents.trainer'),
        field: row => row.relationships.job_roles.data && row.relationships.job_roles.data.attributes && row.relationships.job_roles.data.attributes.name ? row.relationships.job_roles.data.attributes.name : '',
        sortable: false,
      },
    ];

    if (this.$store.getters.hasAnyPermission(['update documents', 'destroy documents']))
      columns.push({
        label: this.$t('documents.actions'),
        field: 'after',
        tdClass: 'td-after',
        sortable: false
      });

    return {
      columns: columns,
      printing_columns: printing_columns,
      printing_pages: [],
      documents: [],
      csv_data: [],
      type_options: [],
      category_options: [],
      module_options: [],
      department_options: [],
      trainer_options: [],
      export_options: [{ id: 1, name: 'CSV' }],
      export_option_selected: { id: 1, name: 'CSV' },
      filter_type: null,
      filter_category: null,
      filter_module: null,
      filter_department: null,
      filter_trainers: [],
      filter_archived: { id: 2, name: 'Not archived', value: 'not_archived' },
      archived_options: [{ id: 1, name: 'Archived', value: 'archived' }, { id: 2, name: 'Not archived', value: 'not_archived' }],
      is_loading_documents: false,
      is_loading_documents_csv_data: false,
      is_loading_types: false,
      is_loading_categories: false,
      is_loading_modules: false,
      is_loading_languages: false,
      is_loading_departments: false,
      is_loading_trainers: false,
      is_downloading: false,
      totalRecords: null,
      serverParams: { sorting: [], filters: [{ filter_by: 'archived_at', filter_value: null }] },
      searchTerm: null,
      is_printing: false,
      headbarExpanded: false,
      filterByExpanded: false,
      windowWidth: 0,
      base_url: process.env.VUE_APP_API_URL.slice(0, -7),
    }
  },
  methods: {
    generateDownload() {
      if (!this.export_option_selected) return;

      if (this.export_option_selected.name === 'CSV')
        this.getCSVFromApi();
    },
    async getCSVFromApi() {
      this.is_printing = true
      this.$axios.get(`documents/download`, {
        responseType: 'arraybuffer',
        headers: {
          'Accept': 'application/csv',
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0',
        },
          params: {
              t: new Date().getTime()
          },
      })
        .then(({data}) => {
          this.is_downloading = false;
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'report.csv');
          document.body.appendChild(link);
          link.click();
          this.is_printing = false
        })
        .catch((err) => {
          this.$notify({
            title: this.$t('error'),
            text: this.$larerror(e.response.data, this.$t('users.error_download_document')),
            type: 'error',
          });
          this.is_printing = false
        })
    },
    async generateCSVDownload() {
      this.is_printing = true;
      await this.$refs['csv-generator'].generate();
      this.is_printing = false;
    },
    async generatePDFDownload() {
      this.is_printing = true;
      await this.$refs.html2Pdf.generatePdf()
    },
    async beforePDFDownload() {
      this.is_printing = true;
    },
    async hasPDFDownloaded() {
      this.is_printing = false;
    },
    createdAtFn(row) {
      return this.$moment(row.attributes.created_at).format('DD/MM/YYYY - HH:mm');
    },
    lastUpdatedAtFn(row) {
      return this.$moment(row.attributes.last_updated_at).format('DD/MM/YYYY - HH:mm');
    },
    async copyLink(document_id) {
      let encrypted_id = btoa(this.$CryptoJS.AES.encrypt(document_id.toString(), 'download').toString());

      let url = `${location.origin}/documents/${encrypted_id}/download`;

      await navigator.clipboard.writeText(url);

      this.$notify({
        title: this.$t('success'),
        text: this.$t('documents.link_copied'),
        type: 'success',
      });
    },
    documentPreviewSupported(document) {
      let file_type = document.split('.').pop();

      return file_type === 'jpg' || file_type === 'jpeg' || file_type === 'png' || file_type === 'doc' || file_type === 'docx'
        || file_type === 'ppt' || file_type === 'pptx' || file_type === 'xlsx' || file_type === 'pdf';
    },
    async previewDocument(document) {
      let file_type = document.attributes.attachment_name.split('.').pop();

      let encrypted_id = btoa(this.$CryptoJS.AES.encrypt(document.id.toString(), 'preview').toString());

      let url = `${location.origin}/documents/${encrypted_id}/preview`;

      window.open(url, '_blank');
    },
    downloadDocument(document_id, attachment_name) {
      this.is_downloading = true;

      this.$axios.get(`/documents/${document_id}/download`, {
        responseType: 'arraybuffer',
        headers: {
          'Accept': 'application/pdf',
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0',
        },
          params: {
              t: new Date().getTime()
          },
      }).then(({ data }) => {
        this.is_downloading = false;

        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', attachment_name);
        document.body.appendChild(link);
        link.click();
      });
    },
    visitDocument(document_id) {
      let encrypted_id = btoa(this.$CryptoJS.AES.encrypt(document_id.toString(), 'document').toString());
      const routeData = this.$router.resolve({ name: 'documents-update', params: { id: encrypted_id } });
      window.open(routeData.href, '_blank');
    },
    toggleCreate() {
      this.$modal.show(
        DocumentsCreateModal, {},
        {
          name: 'documents-create-modal',
          adaptive: true,
          resizable: true,
          height: 'auto',
          width: 900,
          scrollable: true,
          classes: 'modal',
        }, {
          'before-close': (e) => {
            if (e.params === true)
              this.getDocuments();
          }
        }
      );
    },
    toggleUpdate(document) {
      this.$modal.show(
        DocumentsUpdateModal,
        {
          document_id: document.id,
        },
        {
          name: 'documents-update-modal',
          adaptive: true,
          resizable: true,
          height: 'auto',
          scrollable: true,
          classes: 'modal',
        }, {
          'before-close': (e) => {
            if (e.params === true)
              this.getDocuments();
          }
        }
      );
    },
    toggleArchive(document) {
      this.$modal.show(
        ConfirmModal, {
          title: document.attributes.archived_at ? this.$t('documents.unarchive_document') : this.$t('documents.archive_document'),
          message: document.attributes.archived_at ? this.$t('documents.prompt_unarchive') : this.$t('documents.prompt_archive'),
          confirmText: document.attributes.archived_at ? this.$t('unarchive') : this.$t('archive'),
          cancelText: this.$t('cancel'),
          confirmClass: '--primary',
          cancelClass: '--secondary --outline'
        },
        {
          name: 'confirm-modal',
          adaptive: true,
          resizable: true,
          height: 'auto',
          scrollable: true,
          classes: 'modal',
        }, {
          'before-close': (e) => {
            if (e.params === true) {
              this.is_loading_documents = true;

              let date = new Date();

              this.$axios.patch(`documents/${document.id}`, { archived_at: document.attributes.archived_at ? null : this.$moment(date).format('YYYY-MM-DD HH:mm:ss') })
                .then(({ }) => {
                  this.getDocuments();
                })
                .catch(e => {
                  this.is_loading_documents = false;

                  this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data, this.$t('documents.error_archive')),
                    type: 'error',
                  });
                });
            }
          }
        }
      );
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getDocuments();
    },
    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
      this.getDocuments();
    },
    onSortChange(params) {
      const sorts = [];

      if (!params || !params.length) {
        this.updateParams({ sorting: [] });
        return this.getDocuments();
      }

      params.forEach(p => {
        if (!p.type || p.type === 'none')
          return;

        let sort_by = null;
        let sort_order = p.type;
        if (p.field === 'attributes.name')
          sort_by = 'name';
        else if (p.field === 'attributes.email')
          sort_by = 'email';
        else if (p.field === 'role')
          sort_by = 'role';
        else
          sort_by = p.field.split('.')[1];

        sorts.push({ sort_order, sort_by })
      });

      this.updateParams({ sorting: sorts });
      this.getDocuments();
    },
    removeParam(param) {
      this.$delete(this.serverParams, param);
    },
    search(searchTerm) {
      this.searchTerm = searchTerm;

      if (searchTerm && searchTerm.length)
        this.updateParams({ term: searchTerm, page: 1 });
      else this.removeParam('term');

      this.getDocuments();
    },
    onFilterUpdated() {
      const filters = [];

      if (this.filter_type)
        filters.push({ filter_by: 'type_id', filter_value: this.filter_type.id });

      if (this.filter_category)
        filters.push({ filter_by: 'category_id', filter_value: this.filter_category.id });

      if (this.filter_module)
        filters.push({ filter_by: 'module_id', filter_value: this.filter_module.id });

      if (this.filter_department)
        filters.push({ filter_by: 'department_id', filter_value: this.filter_department.id });

      if (this.filter_archived && this.filter_archived.value === 'archived')
        filters.push({ filter_by: 'archived_at', filter_operator: '!=', filter_value: null });
      else if (this.filter_archived && this.filter_archived.value === 'not_archived')
        filters.push({ filter_by: 'archived_at', filter_value: null });

      if (filters.length)
        this.updateParams({
          filters: filters
        });
      else this.removeParam('filters');

      this.getDocuments();
    },
    onTrainersFilterUpdated() {
      let job_roles = [];

      job_roles = this.filter_trainers.map(ft => ft.id);

      if (job_roles.length)
        this.updateParams({
          job_roles: job_roles
        });
      else this.removeParam('job_roles');

      this.getDocuments();
    },
    clearFilters() {
      this.filter_type = null;
      this.filter_category = null;
      this.filter_module = null;
      this.filter_department = null;
      this.filter_trainers = [];

      this.updateParams({ filters: [{ filter_by: 'archived_at', filter_value: null }] });
      this.removeParam('job_roles');

      this.getDocuments();
    },
    getDocuments() {
      this.getDocumentsCSVData();

      this.is_loading_documents = true;

      const encodedSorting = this.serverParams.sorting.map(f => btoa(JSON.stringify(f)));

      this.$axios.get('documents', { params: { ...this.serverParams, sorting: encodedSorting } })
        .then(({ data }) => {
          this.documents = data.data;

          this.printing_pages = [];
          let temp_documents_printing_array = _.clone(this.documents);

          while (temp_documents_printing_array.length > 0)
            this.printing_pages.push(temp_documents_printing_array.splice(0, 8));

          this.$refs.table.changePage(data.meta.current_page);

          history.pushState(
            {},
            null,
            `${this.$route.path}?page=${data.meta.current_page}`
          );

          this.totalRecords = data.meta.total;
          this.is_loading_documents = false;
        })
        .catch(e => {
          this.is_loading_documents = false;

          this.$notify({
            title: this.$t('error'),
            text: this.$larerror(e.response.data, this.$t('documents.error_retrieve')),
            type: 'error',
          });
        });
    },
    getDocumentsCSVData() {
      this.is_loading_documents_csv_data = true;

      this.$axios.get('documents/list', { params: this.serverParams })
        .then(({ data }) => {
          this.csv_data = [];

          data.data.forEach(document => {
            this.csv_data.push({
              'Name': document.attributes.name,
              'Type': document.relationships.type.data && document.relationships.type.data.attributes ? document.relationships.type.data.attributes.name : '',
              'Category': document.relationships.category.data && document.relationships.category.data.attributes ? document.relationships.category.data.attributes.name : '',
              'Language': document.attributes.language,
              'Module': document.relationships.module.data && document.relationships.module.data.attributes ? document.relationships.module.data.attributes.name : '',
              'Date Created': this.$moment(document.attributes.created_at).format('YYYY-MM-DD HH:mm'),
              'Version': document.attributes.version,
              'Last Updated': this.$moment(document.attributes.updated_at).format('YYYY-MM-DD HH:mm'),
              'Review No.': document.attributes.review_count ? document.attributes.review_count : '',
              'Revision Details': document.attributes.revision_details ? document.attributes.revision_details : '',
              'Next Review': document.attributes.next_review_at ? this.$moment(document.attributes.next_review_at).format('YYYY-MM-DD HH:mm') : '',
              'Sites': document.relationships.sites.data.length ? document.relationships.sites.data.map(s => s.attributes.name).join(', ') : (''),
              'Site Groups': document.relationships.site_groups.data.length ? document.relationships.site_groups.data.map(sg => sg.attributes.name).join(', ') : (''),
              'Departmental Owner': document.relationships.department.data && document.relationships.department.data.attributes ? document.relationships.department.data.attributes.name : '',
              'Departments': document.relationships.departments.data.length ? document.relationships.departments.data.map(d => d.attributes.name).join(', ') : (''),
              'Trainer': document.relationships.job_roles.data && document.relationships.job_roles.data.attributes ? document.relationships.job_roles.data.attributes.name : '',
              'Reference': document.attributes.reference ? document.attributes.reference : '',
              'Description': document.attributes.description ? document.attributes.description : '',
            });
          });

          this.is_loading_documents_csv_data = false;
        })
        .catch(e => {
          this.is_loading_documents_csv_data = false;

          this.$notify({
            title: this.$t('error'),
            text: this.$larerror(e.response.data, this.$t('documents.error_retrieve')),
            type: 'error',
          });
        });
    },
    getTypes() {
      this.is_loading_types = true;

      this.$axios.get('types/list')
        .then(({ data }) => {
          this.type_options = data.data;
          this.is_loading_types = false;
        })
        .catch(e => {
          this.is_loading_types = false;

          this.$notify({
            title: this.$t('error'),
            text: this.$larerror(e.response.data, this.$t('types.error_retrieve')),
            type: 'error',
          });
        });
    },
    getCategories() {
      this.is_loading_categories = true;

      this.$axios.get('categories/list')
        .then(({ data }) => {
          this.category_options = data.data;
          this.is_loading_categories = false;
        })
        .catch(e => {
          this.is_loading_categories = false;

          this.$notify({
            title: this.$t('error'),
            text: this.$larerror(e.response.data, this.$t('categories.error_retrieve')),
            type: 'error',
          });
        });
    },
    getModules() {
      this.is_loading_modules = true;

      this.$axios.get('modules/list')
        .then(({ data }) => {
          this.module_options = data.data;
          this.is_loading_modules = false;
        })
        .catch(e => {
          this.is_loading_modules = false;

          this.$notify({
            title: this.$t('error'),
            text: this.$larerror(e.response.data, this.$t('modules.error_retrieve')),
            type: 'error',
          });
        });
    },
    getDepartments() {
      this.is_loading_departments = true;

      this.$axios.get('departments/list')
        .then(({ data }) => {
          this.department_options = data.data;
          this.is_loading_departments = false;
        })
        .catch(e => {
          this.is_loading_departments = false;

          this.$notify({
            title: this.$t('error'),
            text: this.$larerror(e.response.data, this.$t('departments.error_retrieve')),
            type: 'error',
          });
        });
    },
    getTrainers() {
      this.is_loading_trainers = true;

      this.$axios.get('jobRoles/list')
        .then(({ data }) => {
          this.trainer_options = data.data;
          this.is_loading_trainers = false;
        })
        .catch(e => {
          this.is_loading_trainers = false;

          this.$notify({
            title: this.$t('error'),
            text: this.$larerror(e.response.data, this.$t('trainers.error_retrieve')),
            type: 'error',
          });
        });
    },
  },
  mounted() {
    this.windowWidth = window.innerWidth;

    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    });

    this.getTypes();
    this.getCategories();
    this.getModules();
    this.getDepartments();
    this.getTrainers();

    if (this.$route.query.page) {
      this.updateParams({ page: this.$route.query.page });
    }

    this.getDocuments();
  },
  head() {
    return {
      title: {
        inner: this.$t('nav.documents')
      },
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  .headbar-expanded-container {
    @apply mt-4 flex flex-col gap-y-4 items-center;

    @screen md {
      @apply hidden;
    }
  }

  .select-dropdown {
    @apply min-w-40;
  }

  .btn-search-mobile,
  .btn-filter-mobile {
    @apply block text-primary w-6;

    @screen md {
      @apply hidden;
    }

    &:active,
    &:focus {
      @apply outline-none;
    }
  }

  .search-desktop,
  .filter-desktop {
    @apply hidden;

    @screen md {
      @apply block;
    }
  }

  .search-container {
    @apply mr-0;
  }

  .filter-mobile {
    @apply max-w-xs;
  }

  .td-after {
    @apply flex flex-row;

    &>* {
      @apply mr-3;

      &:last-child {
        @apply mr-0;
      }
    }
  }

  .options-container {
    @apply flex flex-col mb-6 w-full gap-y-4 flex-wrap;

    @screen 2xl {
      @apply flex-row gap-x-4 items-center justify-between;
    }

    .export-container-tablet {
      @apply flex flex-row gap-x-4 items-center hidden;
      z-index: 999;

      @screen md {
        @apply flex;
      }

      @screen 2xl {
        @apply hidden;
      }

      &>.export-as-text {
        @apply ml-auto text-sm font-bold text-grey;
        width: max-content;
      }

      &>.input-group {
        @apply mb-0 w-1/2;
        max-width: 160px;
      }
    }

    .documents-title-normal {
      @apply font-bold text-2xl capitalize pr-8 block text-grey;

      @screen md {
        @apply hidden;
      }

      @screen 2xl {
        @apply block;
      }
    }

    .filters-container {
      @apply flex flex-col items-center w-full;

      @screen md {
        @apply flex-row flex-nowrap gap-x-4;
      }

      @screen 2xl {
        @apply w-8/12;
      }

      .filters-wrapper {
        @apply flex flex-row flex-wrap gap-y-4 mt-4;

        @screen md {
          @apply mt-0 w-full;
        }

        .departmental-owner {
          @screen md {
            min-width: 200px !important;
          }
        }

        .input-group {
          @apply w-1/2 mb-0;

          @screen md {
            @apply w-auto gap-y-0 gap-x-4 flex-grow;
            max-width: 20%;
            min-width: 10rem;
          }

          &:nth-of-type(even) {
            @apply pl-2;

            @screen md {
              @apply pr-2;
            }

          }

          &:nth-of-type(odd) {
            @apply pr-2;

            @screen md {
              @apply pl-2;
            }

          }
        }
      }

      .filter-by-mobile {
        @apply flex flex-row w-full justify-between cursor-pointer font-bold text-grey text-sm;

        @screen md {
          @apply hidden;
        }
      }

      &>.filter-by-text {
        @apply w-full hidden;

        @screen md {
          @apply w-auto block text-grey text-sm font-bold;
          min-width: max-content;
        }
      }

    }

    .export-container-normal {
      @apply flex flex-row w-full gap-x-4 items-center;

      @screen md {
        @apply hidden;
      }

      @screen 2xl {
        @apply flex w-2/12;
      }

      .export-as-text {
        @apply font-bold text-grey text-sm;
        min-width: max-content;

        @screen md {
          @apply ml-auto;
        }
      }

      .input-group {
        @apply mb-0 w-2/4;
        min-width: 4.5rem;

        @screen md {
          @apply w-auto;
        }
      }
    }
  }
}

.pdf-content {
  @apply w-full px-4;

  &>h1 {
    @apply text-grey font-bold text-2xl my-8;
  }
}</style>