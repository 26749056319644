<template>
    <div class="sites-create-modal">
        <ModalContainer :title="$t('sites.add_site')" identifier="sites-create-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving || is_loading_countries">
                <FormInputText v-model="$v.site.name.$model" ref="name" identifier="name" :label="$t('sites.name')"
                               :disabled="is_saving" :has-error="$v.site.name.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.site.name.required">
                            {{$t('validation.x_is_required',{x: $t('sites.name')})}}
                        </p>
                    </template>
                </FormInputText>

                <FormInputSelect v-model="$v.site.country.$model" identifier="country" :label="$t('sites.country')" :options="country_options"
                                 :disabled="is_loading_countries || is_saving" :has-error="$v.site.country.$error" track-by="id"
                                 :display-custom-label="(row) => `${row.attributes.name}`">
                    <template v-slot:errors>
                        <p v-if="!$v.site.country.required">
                            {{$t('validation.x_is_required',{x: $t('sites.country')})}}
                        </p>
                    </template>
                </FormInputSelect>

                <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                    {{$t('save')}}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
    import ModalContainer from "../modal/ModalContainer";
    import Form from "../form/Form";
    import FormInputText from "../form/FormInputText";
    import FormGroupTwo from "../form/FormGroupTwo";
    import FormInputSelect from "../form/FormInputSelect";
    import {required, email} from 'vuelidate/lib/validators'
    import Button from "../Button";
    import FormInputPassword from "@/components/form/FormInputPassword";

    export default {
        name: "SitesCreateModal",
        components: {FormInputPassword, Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
        data() {
            return {
                site: {
                    name: null,
                    country: null,
                },
                country_options: [],
                is_loading_countries: true,
                is_saving: false,
            }
        },
        validations: {
            site: {
                name: {required},
                country: {required},
            }
        },
        methods: {
            close(status) {
                this.$modal.hide('sites-create-modal', status);
            },
            save() {
                this.$v.site.$touch();
                if (this.$v.site.$anyError || this.is_saving)
                    return;

                this.is_saving = true;

                let payload = {
                    name: this.site.name,
                    country_id: this.site.country.id
                };

                this.$axios.post(`sites`, payload).then(({data}) => {
                    this.$notify({
                        text: this.$t('sites.success_created'),
                        type: 'success',
                    });

                    this.is_saving = false;
                    this.close(true);
                }).catch(e => {
                    this.is_saving = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data.errors, this.$t('sites.error_create')),
                        type: 'error',
                    });
                });
            },
            getCountries() {
                this.is_loading_countries = true;

                this.$axios.get('countries/list')
                    .then(({data}) => {
                        this.country_options = data.data;
                        this.is_loading_countries = false;
                    })
                    .catch(e => {
                            this.is_loading_countries = false;

                            this.$notify({
                                title: this.$t('error'),
                                text: this.$larerror(e.response.data, this.$t('countries.error_retrieve')),
                                type: 'error',
                            });
                        }
                    );
            }
        },
        mounted() {
            this.getCountries();

            this.$refs.name.$el.children[1].focus();
        }
    }
</script>
