import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import {i18n} from './locale/i18n';
import './css/app.scss';
import PrettyCheck from 'pretty-checkbox-vue/check';
import PrettyRadio from 'pretty-checkbox-vue/radio';
import VueHead from 'vue-head';
import Notifications from 'vue-notification';
import Vuelidate from 'vuelidate';
import Multiselect from 'vue-multiselect';
import VueGoodTablePlugin from 'vue-good-table';
import VModal from 'vue-js-modal';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import VueTippy, {TippyComponent} from "vue-tippy";
import PrettyCheckbox from 'pretty-checkbox-vue';
import VCalendar from 'v-calendar';
import VueHtml2pdf from 'vue-html2pdf';
import MSAL from "vue-msal";
import JsonCSV from 'vue-json-csv';
import VueCryptojs from 'vue-cryptojs';

Vue.prototype.$moment = require('moment-timezone');
Vue.prototype.$axios = require('axios');

Vue.prototype.$axios.defaults.headers['Content-Type'] = 'application/json';
Vue.prototype.$axios.defaults.baseURL = process.env.VUE_APP_API_URL;
Vue.prototype.$axios.interceptors.request.use((config) => {
    if (store.getters.token)
        config.headers.common['Authorization'] = `Bearer ${store.getters.token.bearer}`;

    return config;
});

Vue.prototype.$axios.interceptors.response.use((res) => res, (error) => {
    if (error && error.response && error.response.status && error.response.status === 401) {
        store.dispatch('logout');
        router.push({name: 'login'});
    }

    return Promise.reject(error);
});

Vue.prototype.$larerror = (error, fallback = 'error') => {
    if (!error)
        return fallback;

    if (error.errors && error.errors.constructor === Object)
        return error.errors[Object.keys(error.errors)[0]][0];
    else if (error.errors && error.errors.constructor === Array)
        return error.errors[0];

    if (error.message && error.message.constructor === String && error.message.length)
        return error.message;

    if (!error.errors && error.constructor === Object)
        return error[Object.keys(error)[0]][0];

    return error || fallback;
};

Vue.config.productionTip = false;

Vue.component('p-check', PrettyCheck);
Vue.component('p-radio', PrettyRadio);
Vue.use(VueHead);
Vue.use(Notifications);
Vue.use(Vuelidate);
Vue.component('multiselect', Multiselect);
Vue.component('vue-html2pdf', VueHtml2pdf);
Vue.component('downloadCsv', JsonCSV);
Vue.use(PrettyCheckbox);
Vue.use(VueCryptojs);

Vue.prototype.$b2c_client_id = process.env.VUE_APP_AZURE_CLIENT_ID;

Vue.use(MSAL, {
    auth: {
        clientId: process.env.VUE_APP_AZURE_CLIENT_ID,
        tenantId: process.env.VUE_APP_AZURE_TENANT_ID,
        redirectUri: process.env.VUE_APP_AZURE_REDIRECT_URI,
        postLogoutRedirectUri: process.env.VUE_APP_AZURE_LOGOUT_REDIRECT_URI,
        validateAuthority: false
    },
    cache: {
        cacheLocation: "localStorage"
    }
});

import 'vue-good-table/dist/vue-good-table.css';

Vue.use(VueGoodTablePlugin);
Vue.use(VModal, {dynamic: true, injectModalsContainer: true});

Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

Vue.use(VueTippy);
Vue.component("tippy", TippyComponent);

Vue.use(VCalendar)

import "vue-multiselect/dist/vue-multiselect.min.css";

import {library} from '@fortawesome/fontawesome-svg-core';
import {} from '@fortawesome/free-solid-svg-icons';
import {
    faEye as farEye,
    faEyeSlash as farEyeSlash,
    faUser as farUser,
} from '@fortawesome/free-regular-svg-icons';
import {
    faAngleDown as farAngleDown,
    faSearch as farSearch,
    faTimes as farTimes,
    faUserCheck as farUserCheck,
    faUserSlash as farUserSlash,
    faCheck as farCheck,
    faArrowCircleRight,
    faArrowCircleLeft,
    faHomeLgAlt,
    faTimesCircle as farTimesCircle,
    faInfoCircle,
    faBookAlt as farBookAlt,
    faClipboardCheck as farClipboardCheck,
    faCar as farCar,
    faChartBar as farChartBar,
    faBuilding as farBuilding,
    faDoNotEnter as farDoNotEnter,
    faFileCertificate,
    faFileAlt,
    faUpload as farUpload,
    faDownload as farDownload,
    faRetweet as farRetweet,
    faSignature as farSignature,
    faChevronUp as farChevronUp,
    faChevronDown as farChevronDown,
    faLink,
    faArrowSquareRight,
    faExternalLink,
    faArchive,
    faPencil as farPencil,
    faInboxOut, faArrowSquareLeft
} from '@fortawesome/pro-regular-svg-icons';
import {faGlobeEurope, faUser} from '@fortawesome/pro-solid-svg-icons';
import {
    faUsers as falUsers,
    faUsersMedical as falUsersMedical,
    faBuilding as falBuilding,
    faClipboardList as falClipboardList,
    faUserFriends as falUserFriends,
    faFileChartLine as falFileChartLine,
    faPencil as falPencil,
    faTrash as falTrash,
    faEye as falEye,
    faFilePdf as falFilePdf,
    faFileExcel as falFileExcel,
    faLongArrowLeft,
    faCheckCircle,
    faBlinds,
    faListAlt,
    faKey,
    faBars,
    faTimesCircle,
    faBoothCurtain, faSlidersVSquare, faMinusCircle,
    faIconsAlt as falIconsAlt,
    faBookOpen as falBookOpen,
    faHouse as falHouse,
    faFileChartPie as falFileChartPie,
    faUpload as falUpload,
    faCalendar as falCalendar, faIcons,
    faBriefcase as falBriefcase,
    faChalkboardTeacher as falChalkboardTeacher,
    faUser as falUser,
    faArrowCircleLeft as falArrowCircleLeft,
} from '@fortawesome/pro-light-svg-icons';
import {} from '@fortawesome/free-brands-svg-icons';
import {FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText} from '@fortawesome/vue-fontawesome';
import {faShoppingCart} from "@fortawesome/pro-light-svg-icons/faShoppingCart";

library.add(farAngleDown, falUserFriends, falBuilding, falFileChartLine, falClipboardList, farEye, farEyeSlash,
    farSearch, falPencil, falTrash, farTimes, falFilePdf, falFileExcel, falUsersMedical, falEye, farUserCheck,
    farUserSlash, farCheck, faLongArrowLeft, faCheckCircle, faBlinds, faListAlt, faKey, faUser, faBars, faArrowCircleRight,
    faArrowCircleLeft, faHomeLgAlt, faTimesCircle, farTimesCircle, faCheckCircle, faInfoCircle, faShoppingCart, faBoothCurtain,
    faSlidersVSquare, faMinusCircle, farBookAlt, farClipboardCheck, farCar, farChartBar, falIconsAlt, falBookOpen, falHouse, farBuilding, falFileChartPie,
    falUpload, farDoNotEnter, falCalendar, faIcons, faGlobeEurope, falUsers, falBriefcase, falChalkboardTeacher,
    faFileCertificate, faFileAlt, farUpload, farDownload, farRetweet, falUser, farUser, falArrowCircleLeft, farSignature,
    farChevronUp, farChevronDown, faLink, faArrowSquareRight, faExternalLink, faArchive, farPencil, faInboxOut, faArrowSquareLeft);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);
Vue.component('font-awesome-layers-text', FontAwesomeLayersText);

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
