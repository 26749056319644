<template>
    <div class="page-container">
        <Headbar>
            <template v-slot:left>
                <Button className="--primary --small" :onclick="toggleCreate" v-if="$store.getters.hasAnyPermission(['store users'])">
                    {{ $t('users.add_user') }}
                </Button>
            </template>
            <template v-slot:right>
                <Search class="search-desktop" :placeholder="$t('users.search_users')" @search="search"/>
                <button class="btn-search-mobile btn-icon-only"
                        @click="headbarExpanded = headbarExpanded === 'search' ? null : 'search'">
                    <font-awesome-icon v-if="headbarExpanded === 'search'" :icon="['far', 'times']"/>
                    <font-awesome-icon v-else :icon="['far', 'search']"/>
                </button>
            </template>
            <template v-slot:expanded>
                <div class="headbar-expanded-container">
                    <Search v-if="headbarExpanded === 'search'" :placeholder="$t('users.search_users')" @search="search"/>
                </div>
            </template>
        </Headbar>
        <main>
            <div class="options-container">
                <div class="filters-container">
                    <div class="filter-by-mobile" @click="filterByExpanded = !filterByExpanded">
                        <p class="filter-by-text">{{ $t('filter_by') }}</p>
                        <button class="btn-filter-by-mobile">
                            <font-awesome-icon v-if="filterByExpanded === true" :icon="['far', 'chevron-up']"/>
                            <font-awesome-icon v-else :icon="['far', 'chevron-down']"/>
                        </button>
                    </div>
                    <p class="filter-by-text">{{ $t('filter_by') }}</p>
                    <div class="filters-wrapper" v-if="filterByExpanded === true || windowWidth >= 768">
                        <FormInputSelect v-model="filter_job_role" className="filter"
                                         identifier="archived" :options="job_role_options"
                                         :placeholder="$t('users.job_roles')" @input="onFilterUpdated"
                                         track-by="id" :display-custom-label="(row) => `${row.name}`">
                        </FormInputSelect>
                        <FormInputSelect v-model="filter_archived" className="filter"
                                         identifier="archived" :options="archived_options" :allow-empty="false"
                                         :placeholder="$t('documents.archived')" @input="onFilterUpdated"
                                         track-by="id" :display-custom-label="(row) => `${row.name}`">
                        </FormInputSelect>
                    </div>
                </div>
            </div>

            <vue-good-table
                ref="table"
                mode="remote"
                styleClass="vgt-table vgt-custom"
                :columns="columns"
                :rows="users"
                :isLoading.sync="is_loading_users"
                :search-options="{
                    enabled: false,
                }"
                :pagination-options="{
                    enabled: true,
                    mode: 'records',
                    dropdownAllowAll: false,
                    perPage: 10,
                    perPageDropdownEnabled: true,
                    rowsPerPageLabel: $t('x_per_page', {x: $t('users.users')}),
                }"
                :sort-options="{
                  enabled: true,
                  multipleColumns: true,
                }"
                :totalRows="totalRecords"
                @on-page-change="onPageChange"
                @on-per-page-change="onPerPageChange"
                @on-sort-change="onSortChange">
                <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field === 'role'">
                        <p style="text-transform: capitalize;">
                            {{ props.row.relationships.role.data[0].attributes.name }}</p>
                    </div>
                    <div v-else-if="props.column.field === 'after'" class="td-after">
                        <Button className="--secondary --outline --mini --big-text"
                                v-if="$store.getters.hasPermission('update users')"
                                :onclick="()=>toggleUpdate(props.row)">
                            <font-awesome-icon :icon="['fal', 'pencil']"/>
                        </Button>
                        <Button className="--secondary --outline --mini --big-text"
                                v-if="$store.getters.hasPermission('destroy users')"
                                :onclick="()=>toggleArchive(props.row)"
                                :content="filter_archived.value === 'not_archived' ? $t('archive') : $t('unarchive')" v-tippy="{ placement : 'top',  arrow: true }">
                            <font-awesome-icon :icon="props.row.attributes.archived_at ? ['far', 'inbox-out'] : ['far', 'archive']"/>
                        </Button>
                    </div>
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
            </vue-good-table>
        </main>
    </div>

</template>

<script>
import Headbar from "../../components/headbar/Headbar";
import Search from "../../components/Search";
import Button from "../../components/Button";
import Select from "../../components/form/Select";
import ConfirmModal from "../../components/modal/ConfirmModal";
import UsersCreateModal from "../../components/users/UsersCreateModal";
import UsersUpdateModal from "../../components/users/UsersUpdateModal";
import UserIconLarge from "../../components/UserIconLarge";
import FormInputSelect from "@/components/form/FormInputSelect.vue";

export default {
    name: "users-index-page",
    components: {FormInputSelect, UserIconLarge, ConfirmModal, Select, Button, Search, Headbar},
    data: function () {
        const columns = [
            {
                label: this.$t('users.name'),
                field: 'attributes.name',
                sortable: false,
            },
            {
                label: this.$t('users.email'),
                field: 'attributes.email',
                sortable: false,
            },
            {
                label: this.$t('users.job_role'),
                field: row => row.relationships.job_roles.data ? row.relationships.job_roles.data.map(jr => jr.attributes.name.charAt(0).toUpperCase() + jr.attributes.name.slice(1)).join(', ') : '',
                sortable: false,
            },
            {
                label: this.$t('users.permission'),
                field: row => row.relationships.role.data ? row.relationships.role.data.map(r => r.attributes.name.split('_').map(string => string.charAt(0).toUpperCase() + string.slice(1)).join(' ')).join(', ') : '',
                sortable: false,
            },
        ];

        if (this.$store.getters.hasAnyPermission(['update users', 'destroy users']))
            columns.push({
                label: this.$t('users.actions'),
                field: 'after',
                tdClass: 'td-after',
                sortable: false
            });

        return {
            columns: columns,
            users: [],
            is_loading_users: false,
            totalRecords: null,
            serverParams: {sorting: [], filters: [{filter_by: 'archived_at', filter_value: null}], per_page: 10},
            searchTerm: null,
            is_deleting: false,
            headbarExpanded: false,
            filterByExpanded: false,
            filter_job_role: null,
            job_role_options: [],
            filter_archived: {id: 2, name: 'Not archived', value: 'not_archived'},
            archived_options: [{id: 1, name: 'Archived', value: 'archived'}, {id: 2, name: 'Not archived', value: 'not_archived'}],
            is_loading_job_roles: false,
            windowWidth: 0,
        }
    },
    methods: {
        onFilterUpdated() {
            const filters = [];

            if (this.filter_job_role)
                this.updateParams({
                    job_role_id: this.filter_job_role.id
                });
            else this.removeParam('job_role_id');

            if(this.filter_archived && this.filter_archived.value === 'archived')
                filters.push({filter_by: 'archived_at', filter_operator: '!=', filter_value: null});
            else if(this.filter_archived && this.filter_archived.value === 'not_archived')
                filters.push({filter_by: 'archived_at', filter_value: null});

            if (filters.length)
                this.updateParams({
                    filters: filters
                });
            else this.removeParam('filters');

            this.getUsers();
        },
        toggleArchive(user) {
            this.$modal.show(
                ConfirmModal, {
                    title: user.attributes.archived_at ? this.$t('users.unarchive_user') : this.$t('users.archive_user'),
                    message: user.attributes.archived_at ? this.$t('users.prompt_unarchive') : this.$t('users.prompt_archive'),
                    confirmText: user.attributes.archived_at ? this.$t('unarchive') : this.$t('archive'),
                    cancelText: this.$t('cancel'),
                    confirmClass: '--primary',
                    cancelClass: '--secondary --outline'
                },
                {
                    name: 'confirm-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true) {
                            this.is_loading_users = true;

                            let date = new Date();

                            this.$axios.patch(`users/${user.id}`, {archived_at: user.attributes.archived_at ? null : this.$moment(date).format('YYYY-MM-DD HH:mm:ss')})
                                .then(({}) => {
                                    this.getUsers();
                                })
                                .catch(e => {
                                    this.is_loading_users = false;

                                    this.$notify({
                                        title: this.$t('error'),
                                        text: this.$larerror(e.response.data, this.$t('users.error_archive')),
                                        type: 'error',
                                    });
                                });
                        }
                    }
                }
            );
        },
        getJobRoles() {
            this.is_loading_job_roles = true;

            this.$axios.get('jobRoles/list')
                .then(({data}) => {
                    this.job_role_options = data.data.map(job_role => ({id: job_role.id, name: job_role.attributes.name}));
                    this.is_loading_job_roles = false;
                })
                .catch(e => {
                    this.is_loading_job_roles = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('job_roles.error_retrieve')),
                        trainer: 'error',
                    });
                });
        },
        toggleDelete(user) {
            this.$modal.show(
                ConfirmModal, {
                    title: this.$t('users.delete_user'),
                    message: this.$t('users.prompt_delete'),
                    confirmText: this.$t('delete'),
                    cancelText: this.$t('cancel'),
                    confirmClass: '--primary',
                    cancelClass: '--secondary --outline'
                },
                {
                    name: 'confirm-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true) {
                            this.is_deleting = true;
                            this.$axios.delete(`users/${user.id}`)
                                .then(({data}) => {
                                    this.is_deleting = false;
                                    this.getUsers();
                                })
                                .catch(e => {
                                    this.is_deleting = false;

                                    this.$notify({
                                        title: this.$t('error'),
                                        text: this.$larerror(e.response.data, this.$t('users.error_delete')),
                                        type: 'error',
                                    });
                                });
                        }
                    }
                }
            );
        },
        toggleCreate() {
            this.$modal.show(
                UsersCreateModal, {},
                {
                    name: 'users-create-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true)
                            this.getUsers();
                    }
                }
            );
        },
        toggleUpdate(user) {
            this.$modal.show(
                UsersUpdateModal,
                {
                    user_id: user.id,
                },
                {
                    name: 'users-update-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true)
                            this.getUsers();
                    }
                }
            );
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.getUsers();
        },
        onPerPageChange(params) {
            this.updateParams({per_page: params.currentPerPage});
            this.getUsers();
        },
        onSortChange(params) {
            const sorts = [];

            if (!params || !params.length) {
                this.updateParams({sorting: []});
                return this.getUsers();
            }

            params.forEach(p => {
                if (!p.type || p.type === 'none')
                    return;

                let sort_by = null;
                let sort_order = p.type;
                if (p.field === 'attributes.name')
                    sort_by = 'name';
                else if (p.field === 'attributes.email')
                    sort_by = 'email';
                else if (p.field === 'role')
                    sort_by = 'role';
                else
                    sort_by = p.field.split('.')[1];

                sorts.push({sort_order, sort_by})
            });

            this.updateParams({sorting: sorts});
            this.getUsers();
        },
        removeParam(param) {
            this.$delete(this.serverParams, param);
        },
        search(searchTerm) {
            this.searchTerm = searchTerm;

            if (searchTerm && searchTerm.length)
                this.updateParams({term: searchTerm, page: 1});
            else this.removeParam('term');

            this.getUsers();
        },
        getUsers() {
            this.is_loading_users = true;

            const encodedSorting = this.serverParams.sorting.map(f => btoa(JSON.stringify(f)));

            this.$axios.get('users', {params: {...this.serverParams, sorting: encodedSorting}})
                .then(({data}) => {
                    this.users = data.data;

                    this.$refs.table.changePage(data.meta.current_page);
                    this.totalRecords = data.meta.total;
                    this.is_loading_users = false;
                })
                .catch(e => {
                    this.is_loading_users = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('users.error_retrieve')),
                        type: 'error',
                    });
                });
        }
    },
    mounted() {
        this.windowWidth = window.innerWidth;

        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth;
        });

        this.getJobRoles();
        this.getUsers();
    },
    head() {
        return {
            title: {
                inner: this.$t('nav.users')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
.page-container {
    .headbar-expanded-container {
        @apply mt-4 flex flex-col gap-y-4 items-center;

        @screen md {
            @apply hidden;
        }
    }

    .select-dropdown {
        @apply min-w-40;
    }

    .options-container {
        @apply flex flex-col mb-6 w-full gap-y-4 flex-wrap;

        @screen 2xl {
            @apply flex-row gap-x-4 items-center justify-between;
        }

        .filters-container {
            @apply flex flex-col items-center w-full;

            @screen md {
                @apply flex-row flex-nowrap gap-x-4;
            }

            @screen 2xl {
                @apply w-8/12;
            }

            .filters-wrapper {
                @apply flex flex-row flex-wrap gap-y-4 mt-4 w-full;

                @screen md {
                    @apply mt-0;
                }

                .input-group {
                    @apply w-1/2 mb-0;

                    @screen md {
                        @apply w-auto gap-y-0 gap-x-4 flex-grow;
                        max-width: 20%;
                        min-width: 10rem;
                    }

                    &:nth-of-type(even) {
                        @apply pl-2;

                        @screen md {
                            @apply pr-2;
                        }

                    }

                    &:nth-of-type(odd) {
                        @apply pr-2;

                        @screen md {
                            @apply pl-2;
                        }

                    }
                }
            }

            .filter-by-mobile {
                @apply flex flex-row w-full justify-between cursor-pointer font-bold text-grey text-sm;

                @screen md {
                    @apply hidden;
                }
            }

            & > .filter-by-text {
                @apply w-full hidden;

                @screen md {
                    @apply w-auto block text-grey text-sm font-bold;
                    min-width: max-content;
                }
            }

        }

        .export-container-normal {
            @apply flex flex-row w-full gap-x-4 items-center;

            @screen md {
                @apply hidden;
            }

            @screen 2xl {
                @apply flex w-2/12;
            }

            .export-as-text {
                @apply font-bold text-grey text-sm;
                min-width: max-content;

                @screen md {
                    @apply ml-auto;
                }
            }

            .input-group {
                @apply mb-0 w-2/4;
                min-width: 4.5rem;

                @screen md {
                    @apply w-auto;
                }
            }
        }
    }

    .btn-search-mobile, .btn-filter-mobile {
        @apply block text-primary w-6;

        @screen md {
            @apply hidden;
        }

        &:active, &:focus {
            @apply outline-none;
        }
    }

    .search-desktop, .filter-desktop {
        @apply hidden;

        @screen md {
            @apply block;
        }
    }

    .search-container {
        @apply mr-0;
    }

    .filter-mobile {
        @apply max-w-xs;
    }

    .td-after {
        @apply flex flex-row;

        & > * {
            @apply mr-3;

            &:last-child {
                @apply mr-0;
            }
        }
    }
}
</style>
