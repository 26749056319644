import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/index';
import DocumentsIndex from "../views/documents/Index";
import DocumentsUpdate from "../views/documents/Update";
import DocumentsDownload from "../views/documents/Download";
import DocumentsPreview from "../views/documents/Preview";
import DepartmentsIndex from '../views/departments/Index'
import SitesIndex from "../views/sites/Index";
import CountriesIndex from "../views/countries/Index";
import CategoriesIndex from "../views/categories/Index";
import JobRolesIndex from "../views/job_roles/Index";
import ModulesIndex from "../views/modules/Index";
import RolesIndex from "../views/roles/Index";
import UsersIndex from "../views/users/Index";
import UsersProgressIndex from "../views/users/ProgressIndex";
import UserDocuments from "../views/users/UserDocuments";
import Login from "../views/Login";
import NotFound from "../views/NotFound";
import Profile from "../views/Profile";
import {i18n} from '../locale/i18n';
import Reset from "@/views/Reset";

Vue.use(VueRouter);

const routes = [
    {
        path: '/documents',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/',
                name: 'documents-index',
                component: DocumentsIndex,
                meta: {
                    auth: true,
                    all_permissions: ['store documents', 'read documents', 'update documents', 'destroy documents'],
                },
            },
            {
                path: ':id',
                name: 'documents-update',
                component: DocumentsUpdate,
                meta: {
                    auth: true,
                    all_permissions: ['read documents', 'update documents'],
                },
            },
            {
                path: ':id/download',
                name: 'documents-download',
                component: DocumentsDownload,
            },
            {
                path: ':id/preview',
                name: 'documents-preview',
                component: DocumentsPreview,
            },
        ]
    },
    {
        path: '/departments',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/',
                name: 'departments-index',
                component: DepartmentsIndex,
                meta: {
                    auth: true,
                    all_permissions: ['read departments'],
                },
            },
        ]
    },
    {
        path: '/sites',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/',
                name: 'sites-index',
                component: SitesIndex,
                meta: {
                    auth: true,
                    all_permissions: ['read sites'],
                },
            },
        ]
    },
    {
        path: '/countries',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/',
                name: 'countries-index',
                component: CountriesIndex,
                meta: {
                    auth: true,
                    all_permissions: ['read countries'],
                },
            },
        ]
    },
    {
        path: '/categories',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/',
                name: 'categories-index',
                component: CategoriesIndex,
                meta: {
                    auth: true,
                    all_permissions: ['read categories'],
                },
            },
        ]
    },
    {
        path: '/job-roles',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/',
                name: 'job-roles-index',
                component: JobRolesIndex,
                meta: {
                    auth: true,
                    all_permissions: ['read job roles'],
                },
            },
        ]
    },
    {
        path: '/modules',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/',
                name: 'modules-index',
                component: ModulesIndex,
                meta: {
                    auth: true,
                    all_permissions: ['read modules'],
                },
            },
        ]
    },
    {
        path: '/roles',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/',
                name: 'roles-index',
                component: RolesIndex,
                meta: {
                    auth: true,
                    all_permissions: ['read permissions'],
                },
            },
        ]
    },
    {
        path: '/users',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/',
                name: 'users-index',
                component: UsersIndex,
                meta: {
                    auth: true,
                    all_permissions: ['read users'],
                },
            },
            {
                path: '/employee-progress/:role?',
                name: 'users-progress-index',
                component: UsersProgressIndex,
                meta: {
                    auth: true,
                    all_permissions: ['read users'],
                },
            },
            {
                path: ':id/:role?',
                name: 'user-documents',
                component: UserDocuments,
                meta: {
                    auth: true,
                    all_permissions: ['read users', 'read documents'],
                },
            },
        ]
    },
    {
        path: '/profile',
        name: 'profile',
        component: Profile,
        meta: {
            auth: true,
        }
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            auth: 'guest',
        }
    },
    {
        path: '/password/reset',
        name: 'reset',
        component: Reset,
        meta: {
            auth: 'guest',
        }
    },
    {
        path: '*',
        name: 'notfound',
        component: NotFound,
        meta: {
            auth: false
        }
    },
];

const router = new VueRouter({
    mode: 'history',
    routes
});

router.beforeEach(async (to, from, next) => {
    window.scrollTo(0, 0);

    if (!to.meta)
        next();

    if(to.path === '/') {
        if(!store.getters.user) {
            return next({name: 'login'});
        }

        if(store.getters.hasRole('administrator') || store.getters.hasRole('document_controller'))
            return next({name: 'documents-index'});
        else if(store.getters.hasRole('trainer'))
            return next({name: 'users-progress-index', params: {role: 'trainer'}});
        else if(store.getters.hasRole('manager'))
            return next({name: 'users-progress-index', params: {role: 'manager'}});
        else if(store.getters.hasRole('employee')) {
            let encrypted_id = btoa(Vue.prototype.$CryptoJS.AES.encrypt(store.getters.user.id.toString(), 'user').toString());
            return next({name: 'user-documents', params: {id: encrypted_id}});
        }
    }

    // check auth
    if (to.meta.auth) {
        const routeAuth = to.meta.auth;

        if (routeAuth === true) {
            // user must be logged in
            if (!store.getters.token) {
                Vue.notify({
                    type: 'error',
                    text: i18n.t('auth.unauthenticated')
                });

                return next({name: 'login'});
            }
        } else if (routeAuth.toLowerCase() === 'guest') {
            // user must be logged out
            if (store.getters.token)
                return next({path: '/'});
        }
    }

    // check permission
    if (to.meta.any_permissions) {
        // check that the user has some of the required permissions

        if (!store.getters.hasAnyPermission(to.meta.any_permissions)) {
            Vue.notify({
                type: 'error',
                text: i18n.t('auth.unauthorized')
            });

            return next({path: '/'});
        }
    }

    // check permission
    if (to.meta.all_permissions) {
        // check that the user has all the required permissions

        let permissions = to.meta.all_permissions;
        if (!store.getters.hasAllPermissions(permissions)) {
            Vue.notify({
                type: 'error',
                text: i18n.t('auth.unauthorized')
            });

            return next({path: '/'});
        }
    }

    return next();
});

export default router;
