<template>
    <div class="input-group" :class="{error: hasError}">
        <label v-if="label" :for="identifier">{{label}}</label>
        <textarea v-if="useTextarea" :id="identifier" :value="value" @input="(e)=>$emit('input', e.target.value)"
                  :placeholder="placeholder" :disabled="disabled" :autocomplete="autocomplete" :class="['input',{'large-textarea': largeTextarea}]"
                  :readonly="readonly"></textarea>
        <input v-else :id="identifier" :value="value" @input="(e)=>$emit('input', e.target.value)" :type="type"
               :placeholder="placeholder" :disabled="disabled" :autocomplete="autocomplete" class="input"
               :readonly="readonly" :class="{'primary-border': borderColor === 'primary'}">
        <FormErrors v-if="hasError">
            <slot name="errors"></slot>
        </FormErrors>
    </div>
</template>

<script>
    import FormErrors from "./FormErrors";

    export default {
        name: "FormInputText",
        components: {FormErrors},
        props: {
            value: {
                type: [String, Number],
            },
            identifier: {
                type: String,
                default: null,
            },
            type: {
                type: String,
                default: 'text'
            },
            label: {
                type: String,
                default: null,
            },
            placeholder: {
                type: String,
                default: null
            },
            autocomplete: {
                type: String,
                default: null
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            hasError: {
                type: Boolean,
                default: false
            },
            useTextarea: {
                type: Boolean,
                default: false,
            },
            readonly: {
                type: Boolean,
                default: false,
            },
            largeTextarea: {
                type: Boolean,
                default: false,
            },
            borderColor: {
                type: String,
                default: 'grey-light'
            }
        }
    }
</script>

<style lang="scss" scoped>
    .input-group {
        @apply w-full flex flex-col items-start mb-5;

        label {
            @apply text-sm text-grey font-bold mb-1;
        }

        .large-textarea{
            @apply h-9.5rem resize-none;
        }

        .input {
            @apply bg-white text-grey text-sm font-normal border-2 border-grey-light rounded w-full py-3 px-5 appearance-none;

            &:focus {
                @apply border-primary outline-none;
            }

            &:disabled {
                @apply bg-grey-light;
                opacity: 0.5 !important;
            }
        }

        .primary-border {
            @apply border-primary;
        }

        &.error {
            .input {
                @apply border-negative;
            }
        }
    }
</style>