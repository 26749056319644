<template>
    <button :class="`button ${className}`" :disabled="disabled" :type="type" @click="onclick">
        <slot></slot>
    </button>
</template>

<script>
    export default {
        name: "Button",
        props: {
            className: {
                type: String,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            type: {
                type: String,
                required: false,
                default: 'button'
            },
            onclick: {
                type: Function,
                required: false,
                default: () => ({})
            }
        }
    }
</script>
