<template>
    <div class="sites-update-modal">
        <ModalContainer :title="$t('sites.edit_site')" identifier="sites-update-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_loading_site || is_loading_countries || is_saving">
                <FormInputText v-model="$v.site.name.$model" identifier="name" :label="$t('sites.name')"
                               :disabled="is_saving || is_loading_site" :has-error="$v.site.name.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.site.name.required">
                            {{$t('validation.x_is_required',{x: $t('sites.name')})}}
                        </p>
                    </template>
                </FormInputText>

                <FormInputSelect v-model="$v.site.country.$model" identifier="country" :label="$t('sites.country')" :options="country_options"
                                 :disabled="is_loading_site || is_loading_countries || is_saving" :has-error="$v.site.country.$error" track-by="id"
                                 :display-custom-label="(row) => `${row.attributes.name}`">
                    <template v-slot:errors>
                        <p v-if="!$v.site.country.required">
                            {{$t('validation.x_is_required',{x: $t('sites.country')})}}
                        </p>
                    </template>
                </FormInputSelect>

                <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                    {{$t('save')}}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
    import _ from 'lodash';
    import ModalContainer from "../modal/ModalContainer";
    import Form from "../form/Form";
    import FormInputText from "../form/FormInputText";
    import FormGroupTwo from "../form/FormGroupTwo";
    import FormInputSelect from "../form/FormInputSelect";
    import {required, email} from 'vuelidate/lib/validators'
    import Button from "../Button";
    import FormInputPassword from "@/components/form/FormInputPassword";

    export default {
        name: "SitesUpdateModal",
        components: {FormInputPassword, Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
        props: {
            site_id: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                site: {
                    name: null,
                    country: null,
                },
                country_options: [],
                is_saving: false,
                is_loading_site: true,
                is_loading_countries: true,
            }
        },
        validations: {
            site: {
                name: {required},
                country: {required},
            }
        },
        methods: {
            close(status) {
                this.$modal.hide('sites-update-modal', status);
            },
            save() {
                this.$v.site.$touch();
                if (this.$v.site.$anyError || this.is_saving)
                    return;

                this.is_saving = true;

                let payload = {
                    name: this.site.name,
                    country_id: this.site.country.id
                }

                this.$axios.patch(`sites/${this.site_id}`, payload).then(({data}) => {
                    this.$notify({
                        text: this.$t('sites.success_updated'),
                        type: 'success',
                    });

                    this.is_saving = false;
                    this.close(true);
                }).catch(e => {
                    this.is_saving = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data.errors, this.$t('sites.error_update')),
                        type: 'error',
                    });
                });
            },
            async getSite() {
                this.is_loading_site = true;

                await this.$axios.get(`sites/${this.site_id}`)
                    .then(({data}) => {
                        this.$v.site.name.$model = data.data.attributes.name;
                        this.$v.site.country.$model = _.find(this.country_options, {id: data.data.relationships.country.data.id});

                        this.is_loading_site = false;
                    })
                    .catch(e => {
                        this.is_loading_site = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('sites.error_retrieve')),
                            type: 'error',
                        });
                    });
            },
            async getCountries() {
                this.is_loading_countries = true;

                await this.$axios.get('countries/list')
                    .then(({data}) => {
                        this.country_options = data.data;
                        this.is_loading_countries = false;
                    })
                    .catch(e => {
                            this.is_loading_countries = false;

                            this.$notify({
                                title: this.$t('error'),
                                text: this.$larerror(e.response.data, this.$t('countries.error_retrieve')),
                                type: 'error',
                            });
                        }
                    );
            }
        },
        async mounted() {
            await this.getCountries();
            this.getSite();
        }
    }
</script>
