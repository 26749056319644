<template>
    <div class="roles-update-modal">
        <ModalContainer :title="$t('roles.edit_role')" identifier="roles-update-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                <FormInputText v-model="$v.role.name.$model" identifier="name" :label="$t('roles.name')"
                               :disabled="is_saving || is_loading_role || is_loading_permissions"
                               :has-error="$v.role.name.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.role.name.required">
                            {{ $t('validation.x_is_required', {x: $t('roles.name')}) }}
                        </p>
                    </template>
                </FormInputText>

                <FormInputSelect v-model="$v.role.permissions.$model" identifier="permissions"
                                 :label="$t('roles.permissions')" :options="permission_options"
                                 :disabled="is_saving || is_loading_role || is_loading_permissions"
                                 :has-error="$v.role.permissions.$error" track-by="id"
                                 display-label="name" :multiple="true">
                    <template v-slot:errors>
                        <p v-if="!$v.role.permissions.required">
                            {{ $t('validation.x_are_required', {x: $t('roles.permissions')}) }}
                        </p>
                    </template>
                </FormInputSelect>
                <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                    {{ $t('save') }}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormInputSelect from "../form/FormInputSelect";
import {required} from 'vuelidate/lib/validators'
import Button from "../Button";

export default {
    name: "RolesUpdateModal",
    components: {Button, FormInputSelect, FormInputText, Form, ModalContainer},
    props: {
        role_id: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            role: {
                name: null,
                permissions: [],
            },
            permission_options: [],
            is_saving: false,
            is_loading_role: true,
            is_loading_permissions: true,
        }
    },
    validations: {
        role: {
            name: {required},
            permissions: {required},
        }
    },
    methods: {
        close(status) {
            this.$modal.hide('roles-update-modal', status);
        },
        save() {
            this.$v.role.$touch();
            if (this.$v.role.$anyError || this.is_saving)
                return;

            this.is_saving = true;

            this.$axios.patch(`roles/${this.role_id}`, {
                name: this.role.name,
            }).then(({data}) => {
                // sync permissions
                this.$axios.put(`roles/${data.data.id}/permissions/sync`, {
                    permissions: this.role.permissions.map(p => p.id),
                }).then(({data}) => {
                    this.$notify({
                        text: this.$t('roles.success_updated'),
                        type: 'success',
                    });

                    this.is_saving = false;
                    this.close(true);
                }).catch(e => {
                    this.is_saving = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data.errors, this.$t('permissions.error_attach')),
                        type: 'error',
                    });
                });
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('roles.error_update')),
                    type: 'error',
                });
            });
        },
        getRole() {
            this.is_loading_role = true;

            this.$axios.get(`roles/${this.role_id}`)
                .then(({data}) => {
                    this.role.name = data.data.attributes.name;

                    if (data.data.relationships.permissions.data.length)
                        this.role.permissions = this.permission_options.filter(po => data.data.relationships.permissions.data.find(p => p.id === po.id));

                    this.is_loading_role = false;
                })
                .catch(e => {
                    this.is_loading_role = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('roles.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        async getPermissions() {
            this.is_loading_permissions = true;

            await this.$axios.get('permissions/list')
                .then(({data}) => {
                    this.permission_options = data.data.map(p => ({id: p.id, name: p.attributes.name}));
                    this.is_loading_permissions = false;
                })
                .catch(e => {
                    this.is_loading_permissions = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('permissions.error_retrieve')),
                        type: 'error',
                    });
                });
        }
    },
    async mounted() {
        await this.getPermissions();
        this.getRole();
    }
}
</script>
