<template>
    <div class="app-container">
        <Menubar/>
        <Sidebar v-if="$store.getters.hasRole('administrator') || $store.getters.hasRole('document_controller')"/>
        <div class="main-wrapper">
            <MobileMenu v-if="$store.state.menuExpanded"/>
            <main :class="{'mobile-menu-expanded': $store.state.menuExpanded}">
                <slot/>
            </main>
            <Footer/>
        </div>
    </div>
</template>

<script>
    import Menubar from "../components/Menubar";
    import Sidebar from "../components/Sidebar";
    import Footer from "../components/Footer";
    import MobileMenu from "@/components/MobileMenu";

    export default {
        name: "AppLayout",
        components: {MobileMenu, Footer, Sidebar, Menubar}
    }
</script>

<style lang="scss" scoped>
    .app-container {
        @apply grid h-screen;

        grid-template-columns: auto 1fr;
        grid-template-rows: auto 1fr;
        grid-template-areas: "menubar menubar" "sidebar content";

        .main-wrapper {
            @apply overflow-auto flex flex-col;

            grid-area: content;

            main {
                @apply flex-1;

                &.mobile-menu-expanded{
                    @apply hidden;

                    @screen md {
                        @apply block;
                    }
                }
            }
        }
    }
</style>