<template>
    <div class="modal-container">
        <header>
            <h1 class="title">{{title}}</h1>

            <Button className="--secondary --outline --mini --big-text" :onclick="close">
                <font-awesome-icon :icon="['far','times']"/>
            </Button>
        </header>
        <main>
            <slot/>
        </main>
    </div>
</template>

<script>
    import Button from "../Button";

    export default {
        name: "ModalContainer",
        components: {Button},
        props: {
            title: {
                type: String,
                required: false,
                default: null,
            },
            identifier: {
                type: String,
                required: true,
            },
            closable: {
                type: Boolean,
                default: false,
            }
        },
        methods: {
            close(status) {
                this.$modal.hide(this.identifier, status);
            },
        }
    }
</script>

<style lang="scss" scoped>
    .modal-container {
        @apply flex flex-col;

        & > header {
            @apply px-5 py-4 flex flex-row justify-between items-center border-b border-grey-light;

            .title {
                @apply text-lg text-grey font-bold;
            }

            & > button {
                @apply py-1;

                & > svg {
                    @apply text-xl;
                }
            }
        }

        & > main {
            @apply p-5;
        }
    }
</style>